import React from 'react';
import styles from './unauthorized.module.css';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Unauthorized Access</h1>
      <p className={styles.message}>You do not have permission to view this page.</p>
      <button className={styles.button} onClick={handleGoBack}>Go Back to Home</button>
    </div>
  );
}; 

export default Unauthorized;
 