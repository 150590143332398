import React from "react";
import s from "../css/view.module.css";
function Top(props) {
    const {datas, setIsModalOpen} =props;
  return (
    <div className={s.header}>
      <button onClick = {() => setIsModalOpen(false)} >&larr; Classes</button>
     
    </div>
  );
}

export default Top;
