// A.js
export function setLetterP(fp) {
  const letterP = new fp.GestureDescription("P");

  // Gesture K: Thumb up, other fingers curled
  letterP.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  letterP.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterP.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);

  // letterP.addDirection(
  //   fp.Finger.Thumb,
  //   fp.FingerDirection.VerticalDown,
  //   0.2
  // );
  letterP.addDirection(
    fp.Finger.Middle,
    fp.FingerDirection.VerticalDown,
    0.5
  );
  letterP.addDirection(
    fp.Finger.Index,
    fp.FingerDirection.VerticalDown,
    0.4
  );
  letterP.addDirection(
    fp.Finger.Index,
    fp.FingerDirection.VerticalDown,
    0.5
  );
  letterP.addDirection(
    fp.Finger.Middle,
    fp.FingerDirection.VerticalDown,
    0.5
  );
  return letterP;
}
