import React, { useEffect, useRef, useState } from "react";
import { startTranslation } from "../Utilities/Translate.js";
import Speech from "../Utilities/Speech.js";
import space from "../../assets/images/space.png";
import "../css/speech2sign.css";
import { handleToggler } from "./../../../../utilities/methods";
import slide from "../../assets/sounds/slide.mp3";
import mouse from "../../assets/sounds/mouse.mp3";

function Speech2Sign({ room, user, socket }) {
  const [speed, setSpeed] = useState("moderate");
  const [isTranslating, setIsTranslating] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const slideAudioRef = useRef(new Audio(slide));
  const mouseAudioRef = useRef(new Audio(mouse));
  const [error, setError] = useState("");
  const [replies, setReplies] = useState(() => {
    const savedReplies = localStorage.getItem("participantReplies");
    return savedReplies ? JSON.parse(savedReplies) : [];
  });
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("hostMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [values, setValues] = useState({
    message: "",
    user: user,
    room: room,
  });

  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = (user) => {
    setSelectedUser(user === selectedUser ? null : user);
  };

  useEffect(() => {
    const handleMessageSent = (datas) => {
      const { message } = datas;
      setMessages((prevMsg) => {
        const updatedMessages = [...prevMsg, message];
        localStorage.setItem("hostMessages", JSON.stringify(updatedMessages));
        return updatedMessages;
      });
    };

    const handleReplySent = (datas) => {
      setReplies(datas.participantReplies);
      localStorage.setItem(
        "participantReplies",
        JSON.stringify(datas.participantReplies)
      );
    };

    const handleReplyReset = () => {
      setReplies([]);
      localStorage.removeItem("participantReplies");
    };

    const handleHostMessagesReset = () => {
      setMessages([]);
      localStorage.removeItem("hostMessages");
    };

    socket.on("hostMessagesReset", handleHostMessagesReset);
    socket.on("messageSent", handleMessageSent);
    socket.on("replySent", handleReplySent);
    socket.on("replyReset", handleReplyReset);

    return () => {
      socket.off("messageSent", handleMessageSent);
      socket.off("replySent", handleReplySent);
      socket.off("replyReset", handleReplyReset);
      socket.off("hostMessagesReset", handleHostMessagesReset);
    };
  }, [socket]);

  const handleSpeechRecognition = (transcript) => {
    setValues((prev) => ({
      ...prev,
      message: transcript,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // mouseAudioRef.current.play();
    const { message, user, room } = values;

    if (!message || !user || !room) {
      return;
    }
    socket.emit("sendMessage", values);

    setIsTranslating(true);
    startTranslation(values.message, speed, () => setIsTranslating(false));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const resetHistory = () => {
    setMessages([]);
    localStorage.removeItem("hostMessages");
    socket.emit("resetHostMessages", room);
  };

  const resetReplies = () => {
    setReplies([]);
    localStorage.removeItem("participantReplies");
    socket.emit("resetReplies", room);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSpeedChange = (e) => {
    setSpeed(e.target.value);
  };

  const closeChatBox = () => {
    setIsChatBoxOpen(false);
  };

  const handleRetrieve = (e, msg) => {
    e.preventDefault();
    setValues((prevValues) => ({
      ...prevValues,
      message: msg, // Set message value to the retrieved one
    }));
    setIsTranslating(true);
    startTranslation(msg, speed, () => setIsTranslating(false));
  };

  const toggleChatbox = () => {
    slideAudioRef.current.play();
    handleToggler(isChatBoxOpen, setIsChatBoxOpen);
  };

  return (
    <>
      <div className="translate-grandparent" onKeyPress={handleKeyPress}>
        <header className="translate-logo-container"></header>
        <section className="speech2sign-translate-parent">
          <div className="translate-hand-container">
            <img
              src={space}
              className="translate-default-hand"
              alt="Hand Gesture"
            />
          </div>

          <div className="speech2sign-translate-text-parent">
            <div className="speedContainer">
              <label>Speed:</label>

              <select
                className="speed-options"
                value={speed}
                onChange={handleSpeedChange}
              >
                <option value="slow">Slow</option>
                <option value="moderate">Moderate</option>
                <option value="fast">Fast</option>
              </select>
              <button className="btnChatboxToggler" onClick={toggleChatbox}>
                Chatbox
              </button>
            </div>
            <div className="translate-text-container">
              <textarea
                placeholder="Type text here"
                type="text"
                onChange={handleChange}
                name="message"
                value={values.message}
              />
            </div>
            <div className="buttons-container">
              <Speech
                handleSpeechRecognition={handleSpeechRecognition}
                setError={setError}
              />
              <button onClick={(e) => handleSubmit(e)}>Translate</button>
            </div>
          </div>
          {isChatBoxOpen && (
            <section className="chatbox-replies-container">
              <div className="previous-replies slideRight">
                <span className="history-header">
                  <span>
                    Chatbox
                    {Object.entries(replies).length > 0 && (
                      <button onClick={resetReplies}>Reset Replies</button>
                    )}
                  </span>
                  <button className="btnClose" onClick={closeChatBox}>
                    Close
                  </button>
                </span>
                <div className="chat-container">
                  {Object.entries(replies).map(([user, messages]) => (
                    <div key={user} className="user-reply-container">
                      <span
                        className="user-name"
                        onClick={() => handleUserClick(user)}
                        style={{ cursor: "pointer" }}
                      >
                        ⭐ {user}
                      </span>

                      {selectedUser === user && messages.length > 0 && (
                        <div className="user-messages">
                          {messages.map((msg, i) => (
                            <div
                              key={i}
                              className={`message-bubble ${
                                user === "123" ? "current-user" : "other-user"
                              }`}
                            >
                              {msg}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        </section>

        <section className="histories-messages-container">
          <div className="previous-messages">
            <h3 className="history-header">
              History
              {messages.length > 0 && (
                <button className="historyResetButton" onClick={resetHistory}>
                  Reset
                </button>
              )}
            </h3>
            {messages.length === 0 ? (
              "No Messages sent to room participants yet"
            ) : (
              <div className="previous-span-container">
                {messages.map((msg, i) => (
                  <span
                    key={i}
                    onClick={(e) => handleRetrieve(e, msg)}
                    className="history-box"
                  >
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      #{i + 1}:{" "}
                    </span>{" "}
                    {msg}
                  </span>
                ))}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Speech2Sign;
