import React, { useEffect, useState } from "react";
import s from "./css/handles.module.css";
// components
import Default from "../../../../../defaults/Default/Default";
import Prompt from "../../../../components/Prompt/Prompt";
import Popup from "../../../../components/Popup/Popup";
import { getDatas, postDatas } from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";

function Handles() {
  const [strand, setStrand] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
    const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  // *****************************************
  // send request to handle strand
  // *****************************************
  const goConfirm = async (confirmation, strand_id) => {
    setError(null);
    setActive(null);
    setMessage(null);
    if (confirmation) {
      try {
        const sendUrl = `/teacher-api/strand/request`;
        const datas = {
          teacher_id: user.teacher_id,
          strand_id: strand_id,
          role: "Collaborator",
          description: "",
        };
        const sent = await postDatas(sendUrl, datas, setError);
        if (sent && sent.response) {
          setActive(1);
          setMessage(sent.response);
        }
      } catch (error) {
        setActive(3);
        setMessage("Handling Request Already Exist");
        getStrands(user.teacher_id);
      }
    }
    closePrompt();
  };

  // *****************************************
  // get all not handled strands
  // *****************************************
  const getStrands = async (teacher_id) => {
    setError(null);
    const url = `/teacher-api/strand/not-handled-strands/${teacher_id}`;

    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setStrand(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // *****************************************
  // togglers and use effect
  // *****************************************
  const openModal = (Component, str) => {
    setModalContent(
      <Component str={str} onConfirm={goConfirm} id={str.strand_id} />
    );
    setIsModalOpen(true);
  };

  const closePrompt = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getStrands(user.teacher_id);
  }, [user]);

  return (
    <>
      <Popup message={message} active={active} />

      {isModalOpen ? (
        <>{modalContent}</>
      ) : (
        <>
          <section className={`${s.parent} ${s.fadeIn}`}>
            <div className={s.header}>SEND STRAND HANDLING REQUEST</div>
            {error ? (
              <Default active={3} />
            ) : loading ? (
              <Default active={1} />
            ) : (
              <>
                {strand.length === 0 ? (
                  <Default active={2} />
                ) : (
                  <div className={s.strandContainer}>
                    {strand.map((str, i) => (
                      <div
                        className={s.col}
                        key={i}
                        onClick={() => openModal(Prompt, str)}
                      >
                        <div className={s.card}>
                          <img
                            src={str.image}
                            className={s.cardImg}
                            alt="..."
                          />
                          <div className={s.cardBody}>
                            <h5 className={s.cardTitle}>{str.strand}</h5>
                            <p className={s.cardText}>{str.year}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </section>
        </>
      )}
    </>
  );
}

export default Handles;
