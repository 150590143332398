import React from "react";
import { Link } from "react-router-dom";
// icons
import { IoMdAdd } from "react-icons/io";
// style
import s from "./style.module.css";
// components
import Add from "../../../classes/modals/Add";
 
function Top({ openModal }) {
  return (
    <div className={s.parent}>
        <Link to="/teacher/strand" className={s.backButton}>
          Strands
        </Link>
      <div className={s.right}>
        <button className={s.button} onClick={(e) => openModal(Add, {}, e)}>
          <IoMdAdd />
        </button>
      </div>
    </div>
  );
}

export default Top;
