import React, { useState } from "react";
// styles
import styles from "./css/create.module.css";
import s from '../../components/style_configs/buttons.module.css';
// components
import Popup from "../../components/Popup/Popup";
import { putDatas } from "../../../../services/api";

function ChangePassword({ user, closeModal }) {
  console.log(user);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    password: "",
    confirm: "",
    teacher_id: user.teacher_id
  });

  const handleSubmit = async (e) => {
    const { password, confirm } = values;
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    if (!password || !confirm) {
      setActive(2);
      setMessage("Please Fill up empty fields");
      return;
    }

    if (password !== confirm) {
      setActive(2);
      setMessage("Password Didn't match");
      return;
    }

    const url = `/teacher-api/authentication/forgot`;
    const response = await putDatas(url, values, setError);

    if (response && response.response) {
      setActive(1);
      setMessage(response.response);
    } else {
      setActive(3);
      setMessage("Updating Student Password Failed!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value }); 
  };

  return (
    <>
      <Popup message={message} active={active} />

      <div className={styles.container}>
        <div className={styles.changePasswordContainer}>
          <div className={styles.title}>
            Change Password
          </div>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <input
                className={styles.input}
                type="text"
                name="password"
                placeholder="Enter New Password"
                value={values.password}
                onChange={handleChange}
                required
              />
              <input
                className={styles.input}
                type="text"
                name="confirm"
                placeholder="Confirm Password"
                value={values.confirm}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className={styles.button}>
              Change Password
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
