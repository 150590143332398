export const categories = [
    { 
      id: "clqpwckl40006vb3bo7x08570",
      name: "Icons",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    }, 
    {
      id: "clqpwceop0002vb3bn3073ugx", 
      name: "Color",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwbsp50000vb3b1vghb920",
      name: "Accessibility",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwbyzb0001vb3bto2y9dy2",
      name: "Books",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl4000bvb3b2bhmajtx",
      name: "Mockup Tools",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl30003vb3b0wts8kvd",
      name: "Design News",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl30004vb3bi0vrqj22",
      name: "Design Systems",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl30005vb3bd3pajldj",
      name: "Design Tools",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
  
    {
      id: "clqpwckl40007vb3b24apop4l",
      name: "Illustrations",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl40008vb3ba41vsbz9",
      name: "Inspiration",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl40009vb3b31fslsa9",
      name: "Jobs",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwckl4000avb3b7me9u31c",
      name: "Learn Design",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
  
    {
      id: "clqpwckl4000cvb3b6ng3as0u",
      name: "Podcasts",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwetea000hvb3bhuy19oug",
      name: "Prototyping Tools",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwetea000fvb3bxu5katf7",
      name: "Stock Photos",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwetea000gvb3bxlwiq1ez",
      name: "Resources",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqqy1ssm0000vbn8g0dx05ls",
      name: "Typography",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
    {
      id: "clqpwete9000evb3bucmzdlxb",
      name: "Wireframing",
      icon: null,
      createdAt: "2023-12-29T22:14:29.877Z",
    },
  ];
  