import React from "react";
// styles
import s from "./prompt.module.css";
import style from '../css/global.module.css';
// icons
import { icons } from "../../../../utilities/icons";
// components
import Center from "../Center_layout/Center"; 

function Prompt({ onConfirm,id}) {
  const handleYes = () => {
    onConfirm(true,id); // Confirm the action
  };

  const handleNo = () => {
    onConfirm(false); // Decline the action 
  };
 
  return (
    <Center> 
      <div className={s.card}>
        <div className={s.header}>
          <h2 className={s.logo}>{icons.gradFill}</h2>
        </div>
        <div className={s.main}>
          Do you want to proceed?
        </div>
        <div className={s.footer}>
          <button className={style.button} onClick={handleYes}>Yes</button>
          <button className={style.button} onClick={handleNo}>No</button>
        </div>
      </div>
    </Center>
  );
}

export default Prompt;
