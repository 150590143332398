// A.js
export function setLetterR(fp) {
  const letterR = new fp.GestureDescription("R");
  // U

  letterR.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterR.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterR.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  letterR.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterR.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  letterR.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.6);

  letterR.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 0.6);

  return letterR;
}
