import React, { useContext, useEffect, useState } from "react";
import { FaChalkboard, FaBars } from "react-icons/fa";
import styles from "./css/sidebar.module.css";
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { handleToggler } from "../../../../utilities/methods";
import { getDatas } from "../../../../services/api";
import Students from "./modals/students/Students";
import Teachers from "./modals/teachers/Teachers";
import { icons } from "../../../../utilities/icons";
import Strands from "./modals/strands/Strands";
import Home from "./modals/Home/Home";

const Sidebar = ({ setModalContent }) => {
  const { globalStyles } = useContext(GlobalStyleContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState({
    request: "",
  });
  const [error, setError] = useState(null);

  const getRequests = async () => {
    setError(null);
    const url = `/admin-api/get/requests`;
    const res = await getDatas(url, setError);
    try {
      if (res && res.response) {
        setCount({ request: res.response.length });
      } else {
        setCount({ request: 0 });
      }
    } catch (error) {
      console.log(error)
    }

  };

  const openModal = (Component, content = "", reCount = () => {}) => {
    setModalContent(
      <Component
        content={content}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        reCount={reCount}
      />
    );
    setIsModalOpen(true);
  };

  useEffect(() => {
    getRequests();
  }, []);
  return (
    <aside className={styles.container}>
      <div
        className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ""}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div
          className={styles.toggleContainer}
          style={{ backgroundColor: globalStyles.rhighlight }}
        >
          <div
            className={styles.toggle}
            onClick={() => handleToggler(isCollapsed, setIsCollapsed)}
          >
            <FaBars style={{ color: globalStyles.icon2 }} />
          </div>
        </div>
        <nav className={styles.nav}>
          <button
            onClick={() => openModal(Home)}
            className={`${styles.navItem} `}
            style={{ color: globalStyles.secondary }}
          >
            <span className={styles.icon}> {icons.assFill}</span>
            <span className={styles.text}>{!isCollapsed && "Dashboard"}</span>
          </button>
          <button
            onClick={() => openModal(Students)}
            className={`${styles.navItem} `}
            style={{ color: globalStyles.secondary }}
          >
            <span className={styles.icon}> {icons.gradFill}</span>
            <span className={styles.text}>{!isCollapsed && "Students"}</span>
          </button>
          <button
            onClick={() => openModal(Teachers)}
            className={`${styles.navItem} `}
            style={{ color: globalStyles.secondary }}
          >
            <span className={styles.icon}> {icons.teacherFill}</span>

            <span className={styles.text}>{!isCollapsed && "Teachers"}</span>
          </button>

          <button
            onClick={() => openModal(Strands)}
            className={`${styles.navItem} `}
            style={{ color: globalStyles.secondary }}
          >
            <FaChalkboard className={styles.icon} />
            <span className={styles.text}>{!isCollapsed && "Strands"}</span>
          </button>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
