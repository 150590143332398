import React, { useEffect, useState } from "react";
// style
import s from "../../components/style_configs/top_side_main.module.css";
import style from "./css/announcements.module.css";
// components
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Center from "../../components/Center_layout/Center";
import Main from "./components/Main";
import Default from "../../../defaults/Default/Default";
//methods
import { getDatas } from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [copyAnnouncements, setCopyAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const fetchAnnouncements = async () => {
    setError(null);
    const url = `/student-api/announcements/all/${user.strand_id}/${user.student_id}`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        const sortedAnnouncements = res.response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAnnouncements(sortedAnnouncements);
        setCopyAnnouncements(sortedAnnouncements);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  };
  

  useEffect(() => {
    if (user && user.strand_id && user.student_id) {
      fetchAnnouncements();
    }
  }, [user]);

  return (
    <div className={style.AnnouncementsContainer}>
      <header className={s.header}>
        {user && <Header title="MAIL" user={user} />}
      </header>
      <aside className={s.sidebar}>
        <Sidebar />
      </aside>
      <section className={s.main}>
        <Center>
          {error ? (
            <Default  active={2} />
          ) : loading ? (
            <Default
              active={1}
            />
          ) : (
            <Main
              user={user}
              copyAnnouncements={copyAnnouncements}
              announcements={announcements}
              setAnnouncements={setAnnouncements}
              fetchAnnouncements={fetchAnnouncements}
            />
          )}
        </Center>
      </section>
    </div>
  );
};

export default Announcements;
