import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// icons
import {
  FaTachometerAlt,
  FaChalkboard,
  FaMailBulk,
  FaClipboardList,
  FaBars,
} from "react-icons/fa";

import styles from "./Sidebar.module.css";
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { handleToggler } from "../../../../utilities/methods";

const Sidebar = () => {
  const { globalStyles } = useContext(GlobalStyleContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation(); // Get current route
  const links = [
    { to: "/student/dashboard", icon: FaTachometerAlt, label: "Dashboard" },
    { to: "/student/learning", icon: FaChalkboard, label: "Learning" },
    { to: "/student/scoreboard", icon: FaClipboardList, label: "Scoreboard" },
    { to: "/student/announcements", icon: FaMailBulk, label: "Mail" },
  ];

  return (
    <aside className={styles.container}>
      <div
        className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ""}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div
          className={styles.toggleContainer}
          style={{ backgroundColor: globalStyles.rhighlight }}
        >
          <div
            className={styles.toggle}
            onClick={() => handleToggler(isCollapsed, setIsCollapsed)}
          >
            <FaBars style={{ color: globalStyles.icon2 }} />
          </div>
        </div>
        <nav className={styles.nav}>
          {links.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={`${styles.navItem} ${
                location.pathname === link.to ? styles.activeLink : ""
              }`}
              style={{
                color: location.pathname === link.to ? "#fff" : "gray",
                backgroundColor: location.pathname === link.to ? globalStyles.bgHighlight : "transparent",
              }}
            >
              <link.icon className={styles.icon} />
              <span className={styles.text}>
                {!isCollapsed && link.label}
              </span>
            </Link>
          ))}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
