import React, { useState, useRef } from "react";
// styles
import s from "./css/form.module.css";
// methods
import { previewFile } from "../../../../../../utilities/methods";
// components
import Popup from "../../../../components/Popup/Popup";
import {
  getDatas,
  postDatas,
  uploadToS3,
} from "../../../../../../services/api";
import { icons } from "../../../../../../utilities/icons";
import { ref } from "firebase/storage";

function UploadContent({ closeModal, user, class_id }) {
  const previewFileRef = useRef(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [values, setValues] = useState({
    title: "",
    description: "",
    filetype: "",
    file: "",
    color: "rgb(137, 4, 4)",
    uploaded_by: `${user.fname} ${user.lname}`,
    updated_by: `${user.fname} ${user.lname}`,
    class_id: class_id,
    teacher_id: user.teacher_id,
  });

  const uploadMaterial = async (e) => {
    e.preventDefault();
    setActive(null);
    setError(null);
    setMessage("");

    const { title, file, color } = values;
    if (!title || !color) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }

    setIsProcessing(true);

    const updatedValues = {
      ...values,
      title: title.charAt(0).toUpperCase() + title.slice(1).toLowerCase(),
    };

    try {
      if (file) {
        try {
          const fileUrl = await getDatas(
            `/getS3Access/materials/files/${file.name}`,
            setError
          );
          if (fileUrl && fileUrl.url) {
            setIsFileUploaded(false);
            await uploadToS3(fileUrl.url, file);
            updatedValues.file = fileUrl.url.split("?")[0];
            setIsFileUploaded(true);
          }
        } catch (error) {
          console.log(error);
        }
      }

      const res = await postDatas(
        `/teacher-api/materials/upload`,
        updatedValues,
        setError
      );

      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
      } else {
        setActive(3);
        setMessage(res.error);
      }
    } catch (error) {
      setError("An error occurred while uploading. Please try again.");
    }

    setIsProcessing(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const filePreview = previewFileRef.current;
    if (file) {
      setValues({
        ...values,
        file: file,
        filetype: file.type,
      });

      const fileUrl = URL.createObjectURL(file);

      if (file.type === "application/pdf") {
        filePreview.src = fileUrl;
      } else if (file.type.includes("text")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          filePreview.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        filePreview.src = "";
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.uploadContentContainer} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        Upload Materials
        <button className={s.backButton} onClick={() => closeModal()}>
          Back
        </button>
      </div>

      <form className={s.form} onSubmit={uploadMaterial}>
        <input
          type="text"
          name="title"
          placeholder="Enter Title"
          className={s.input}
          value={values.title}
          onChange={handleChange}
        ></input>
        <textarea
          type="text"
          name="description"
          placeholder="Some Description..."
          className={s.textarea}
          value={values.description}
          onChange={handleChange}
        ></textarea>

        <span className={s.colorContainer}>
          <select
            name="color"
            value={values.color}
            onChange={handleChange}
            required
          >
            <option value="rgb(137, 4, 4)">red</option>
            <option value="#242629">dark</option>
            <option value="rgb(168, 168, 5)">yellow</option>
            <option value="rgb(15, 15, 96)">blue</option>
            <option value="rgb(9, 90, 9)">green</option>
            <option value="rgb(145, 94, 1)">orange</option>
            <option value="rgb(90, 12, 90)">violet</option>
          </select>
          <div
            className={s.colorPreview}
            style={{ backgroundColor: values.color }}
          ></div>
        </span>

        <div className={s.selectContainer}>
          <span>
            <label htmlFor="file" className={s.customImage}>
              {icons.clipFill} Select File
            </label>
            <input
              id="file"
              type="file"
              accept=".pdf,.doc,.docx,.txt,.xlsx,.zip,.rar"
              name="file"
              className={s.file}
              onChange={handleFileChange}
            />

            {isProcessing && !isFileUploaded && (
              <p className={s.notif}>File Uploading...</p>
            )}
          </span>
        </div>
        <div className={s.inputFileContainer}></div>

        <div className={s.buttons}>
          <button type="submit" className={s.submitButton}>
            Create
          </button>
        </div>
      </form>

      <div className={s.previews}>
        <iframe ref={previewFileRef}></iframe>
      </div>
    </div>
  );
}

export default UploadContent;
