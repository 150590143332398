import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { postDatas } from "../../../../services/api";
import logo from "../../../../assets/logo.jpg";
import profile from "../../../../assets/profile.png";
import { icons } from "../../../../utilities/icons";
import { handleToggler } from "../../../../utilities/methods";
import SendMail from "./SendMail";
import Mail from "../Mail_layout/Mail";

const Header = (props) => {
  const { title, user } = props;
  const [error, setError] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMailOpen, setIsMailOpen] = useState(false);

  const logout = async () => {
    localStorage.removeItem("isLoggedIn");
    const res = await postDatas(
      "/student-api/authentication/logout",
      {},
      setError
    );
    if (res.response) {
      window.location.href = "/";
    }
  };
  const { globalStyles } = useContext(GlobalStyleContext);
  return (
    <> 
      <header
        className={`${styles.header} ${styles.fadeIn}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div className={styles.left} style={{ color: globalStyles.logo }}>
          <img src={logo} alt="template"></img>
          <span>{title}</span>
        </div>
        <div className={styles.right}>
          <span onClick={() => handleToggler(isMailOpen, setIsMailOpen)}>
            {icons.emailLine}
          </span>
          <span></span>
          <span>
            {user && user.fname && user.lname
              ? user.fname + " " + user.lname
              : ""}
          </span>
          <span>
            <img
              src={user.image ? user.image : profile}
              alt="template"
              onClick={() =>
                handleToggler(isDropdownVisible, setIsDropdownVisible)
              }
            ></img>
          </span>
        </div>
        {isDropdownVisible && (
          <div className={styles.menuDropdown}>
            <Link to="/student/tools">
              {icons.toolFill} <label>Tools</label>
            </Link>
            <Link to="/student/settings">
              {icons.setLine} <label>Settings</label>
            </Link>
            <span onClick={logout}>
              {icons.homeLine} <label>Logout</label>
            </span>
          </div>
        )}
      </header>

      {isMailOpen && (
        <Mail>
          <SendMail setIsMailOpen={setIsMailOpen}user={user} />
        </Mail>
      )}
    </>
  );
};

export default Header;
