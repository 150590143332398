import React, { useState } from "react";
// styles
import s from "../style.module.css";
// methods
import { postData } from "../../../../../utilities/methods";
// icons
import Popup from "../../Popup/Popup";

function Email({ user, datas, closeModal }) {
  const [error, setError] = useState([]);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [values, setValues] = useState({
    from: user?.email,
    email: datas.email,
    subject: "",
    text: "",
  });

  const doSubmit = async (e) => {
    setActive(null);
    setMessage("");
    e.preventDefault();
    const url = `/teacher-api/mailer`;
    const res = await postData(url, values, setError);
    if (res) {
      setActive(1);
      setMessage("Email Sent");
    } else {
      setActive(3);
      setMessage("Failed to Send Email ");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        Email Notification
        <button className={s.modalButton} onClick={closeModal}>
          Back
        </button>
      </div>
      <div className={s.emailParent}>
        <form onSubmit={doSubmit}>
          <span className={s.biInputGroup}>
            <label>To:</label>
            <input
              className={s.input}
              type="email"
              onChange={handleChange}
              name="email"
              placeholder="Enter Recipient..."
              value={values.email}
            ></input>
          </span>

          <span>
            <input
              className={s.input}
              type="text"
              onChange={handleChange}
              name="subject"
              placeholder="Enter Email Subject"
              value={values.subject}
            ></input>
          </span>
          <span>
            <label>Content:</label>
            <textarea
              className={s.textarea}
              type="text"
              onChange={handleChange}
              name="text"
              placeholder="Enter email subject..."
              value={values.text}
            ></textarea>
          </span>
          <button type="submit" className={s.submitButton}>
            Send Mail
          </button>
        </form>
      </div>
    </div>
  );
}

export default Email;
