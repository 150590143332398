export function setLetterW(fp) {
  const letterW = new fp.GestureDescription("W");

  letterW.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterW.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterW.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  letterW.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
  letterW.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);


  letterW.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpRight, 1.0);
  // letterW.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.8);

  letterW.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);

  letterW.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpLeft, 1.0);
  // letterW.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 0.8);


  return letterW;
}
