import React, { useState } from "react";
import { Link } from "react-router-dom";
// style
import s from "./css/forgot.module.css";
// methods
import { postData, randomCode } from "../../../../utilities/methods";
import Popup from "../../components/Popup/Popup";
import ChangePassword from "./ChangePassword";
import { postDatas } from "../../../../services/api";

function Forgot() {
  const [codeSent, setCodeSent] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [error, setError] = useState(null);
  const [datas, setDatas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [values, setValues] = useState({
    email: "",
    code: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, code } = values;
    setMessage(null);
    setActive(null);

    // return early if email is empty
    if (email.length === 0) {
      setActive(2);
      setMessage("Email is Empty!");
      console.log("Email is empty");
      return;
    }

    const url = `/teacher-api/authentication/check-email`;
    const em = { email };

    const res = await postDatas(url, em, setError);
    if (res && res.response) {
      setDatas(res.response);
    }

    if (code !== codeSent) {
      setActive(2);
      setMessage("Code Did not match, Please Try Again");
    } else {
      setActive(1);
      setMessage("Code Matched");
      setIsModalOpen(true);
    }
  };

  const getCode = async () => {
    const { email } = values;
    setError(null);
    setActive(null);
    setMessage(null);

    if (!email) {
      setActive(2);
      setMessage("Email is Empty!");
      console.log("Email is empty");
      return;
    }

    const random = randomCode();
    setCodeSent(random);

    const url = `/teacher-api/authentication/mailer`;
    const data = { email, random };
    const codeSent = await postData(url, data, setError);
    if (codeSent) {
      setActive(1);
      setMessage("Code Sent to Email: " + email);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={s.forgotContainer}>
      <div className={s.glassCard}>
        <Link to={"/teacher/login"} className={s.link}>Back</Link>

        <Popup message={message} active={active} />
        <div>
          {isModalOpen ? (
            <ChangePassword
              user={datas}
              closeModal={() => setIsModalOpen(false)}
            />
          ) : (
            <>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
              />
              <div>
                <input
                  type="text"
                  name="code"
                  placeholder="Enter your code"
                  value={values.code}
                  onChange={handleChange}
                />
                <button className = {s.getCodeButton} type="button" onClick={getCode}>
                  Get Code
                </button>
              </div>
              <button className = {s.authenticateButton} type="button" onClick={handleSubmit}>
                Authenticate
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forgot;
