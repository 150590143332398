import React from "react";
import s from "../css/view.module.css";
import { cleanDate } from "../../../../../utilities/methods"; // Assume this utility formats the date

function View(props) {
  const { datas,closeMail } = props;
  return (
    <div className={`${s.viewContainer} ${s.fadeIn}`}>
      <div className={s.buttonContainer}>
        <button onClick={closeMail} className = {s.closeButton}>Close</button>
      </div>
      <div className={s.firstSection}>
        <h2>{datas.title}</h2>
      </div>

      <div className={s.section}>
        <h3>Content</h3> 
        <p className={s.content}>{datas.content}</p>
      </div>

      <div className={s.section}>
        <h3>Expiration Date</h3>
        <span>{cleanDate(datas.expires_at)}</span>
      </div>

      <div className={s.section}>
        <h3>Created By</h3>
        <span>{datas.created_by}</span>
      </div>

      <div className={s.section}>
        <h3>Strand</h3>
        <span>{datas.strand} - Year {datas.year}</span>
      </div>

      {datas.subject && (
        <div className={s.section}>
          <h3>Class</h3>
          <span>{datas.subject} - {datas.section}</span>
        </div>
      )}

      {datas.file && (
        <div className={s.section}>
          <h3>Attached File</h3>
          <a href={datas.file} className={s.attachment} target="_blank" rel="noopener noreferrer">
            View Attachment
          </a>
        </div>
      )}

      <div className={s.section}>
        <h3>Last Updated</h3>
        <span>{cleanDate(datas.updated_at)}</span>
      </div>
    </div>
  );
}

export default View;
