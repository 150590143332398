import React, { useState, useContext, useRef } from "react";
// methods
import {
  handleUpload,
  postData,
  previewFile,
} from "../../../../../../../utilities/methods";
// contexts
import { UserDataContext } from "../../../../../../../contexts/UserDataProvider";
// styles
import forms from "../../../../../components/css/forms.module.css";
// components
import Popup from "../../../../../components/Popup/Popup";

function Announce({ datas, closeModal }) {
  const { userData } = useContext(UserDataContext);
  const previewImageRef = useRef(null);
  const previewVideoRef = useRef(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [values, setValues] = useState({
    title: "",
    content: "",
    color: "",
    images: "",
    video: "",
    expires_at: "",
    teacher_id: "",
    user_id: datas.user_id,
    created_by: "",
    updated_by: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");

    const { title, content, expires_at } = values;
    if (!title || !content || !expires_at) {
      setMessage("Please fill out all required fields.");
      return;
    }

    try {
      if (image) {
        values.images = await handleUpload(image, "announcement/images");
      }

      if (video) {
        values.video = await handleUpload(video, "announcement/videos");
      }

      if (userData) {
        const url = `/teacher-api/announcements/create`;
        const newAnnouncement = {
          ...values,
          teacher_id: userData.teacher_id,
          created_by: `${userData.fname} ${userData.lname}`,
          updated_by: `${userData.fname} ${userData.lname}`,
        };
        const response = await postData(url, newAnnouncement, setError);

        if (response) {
          setActive(1);
          setMessage("Creating Announcement Successful!");
        } else {
          setActive(3);
          setMessage("Creating Announcement Failed!");
        }
      }
    } catch (error) {
      setActive(3);
      setMessage("Creating Announcement Failed");
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    previewFile(file, previewImageRef);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);
    previewFile(file, previewVideoRef);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Popup message={message} active={active}  />

      <div className={forms.buttons}>
        <button onClick={closeModal} className={forms.button}>
          Back
        </button>
      </div>
      <div className={forms.header}>CREATE ANNOUNCEMENT</div>
      <div className={forms.parent}>
        <form onSubmit={handleSubmit} className={forms.form}>
          <input
            type="text"
            className={forms.input}
            name="title"
            value={values.title}
            onChange={handleChange}
            placeholder="Enter Announcement Title"
            required
          />
          <textarea
            className={forms.textarea}
            name="content"
            value={values.content}
            onChange={handleChange}
            placeholder="Enter Announcement Details..."
            required
          />
          <div className={forms.biInputGroup}>
            <select
              name="color"
              className={forms.select}
              value={values.color}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Color
              </option>
              <option value="red">Red</option>
              <option value="yellow">Yellow</option>
              <option value="green">Green</option>
            </select>
            <span>
              Expiring Date:
              <input
                type="date"
                name="expires_at"
                className={forms.input}
                value={values.expires_at}
                onChange={handleChange}
                required
              />
            </span>
          </div>
          <div className={forms.biInputGroup}>
            <label className={forms.cLabel}>
              Select Template:
              <input
                type="file"
                className={forms.file}
                accept="image/*"
                onChange={handleImageChange}
              />
            </label>
            <label className={forms.cLabel}>
              Select Video:
              <input
                type="file"
                className={forms.file}
                accept="video/*"
                onChange={handleVideoChange}
              />
            </label>
          </div>
          <div className={forms.buttons}>
            <button type="submit" className={forms.button}>
              Create
            </button>
          </div>
        </form>

        <div className={forms.header}>Preview Panel</div>
        <div className={forms.previews}>
          <div className={forms.cLabel}>
            Selected Image:
            <img
              className={forms.imgPreview}
              ref={previewImageRef}
              alt="Preview"
            />
          </div>
          <div className={forms.cLabel}>
            Selected Video:
            <video
              className={forms.vidPreview}
              ref={previewVideoRef}
              controls
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Announce;
