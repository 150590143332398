import React, { useEffect, useState } from "react";
import s from "../css/main.module.css";
import Popup from "../../../components/Popup/Popup";
import Modal from "../../../components/Modal_layout/Modal";
import Mail from "../../../components/Mail_layout/Mail";
import Top from "./Top";
import { deleteDatas, getDatas } from "../../../../../services/api";
import { icons } from "../../../../../utilities/icons";
import Edit from "../modals/Edit";
import Default from "../../../../defaults/Default/Default";
import View from "../modals/View";
import Prompt from "../../../components/Prompt/Prompt";

function Main({ user, strands, classes, setStrands, setClasses }) {
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [mailContent, setMailContent] = useState(null);
  const [active, setActive] = useState("");
  const [classworks, setClassworks] = useState([]);
  const [copyClasswork, setCopyClasswork] = useState([]);
  const [message, setMessage] = useState("");
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClassworks = async () => {
    setLoading(true);
    try {
      const res = await getDatas(
        `/teacher-api/classworks/${user.teacher_id}`,
        setError
      );
      if (res && res.response) {
        setClassworks(res.response);
        setCopyClasswork(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (user && user.teacher_id) {
      fetchClassworks();
    }
  }, [user]);

  const deleteClasswork = async (confirmation, classwork_id, e) => {
    e.stopPropagation();
    if (confirmation) {
      const res = await deleteDatas(
        `/teacher-api/classworks/delete`,
        { classwork_id },
        setError
      );
      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        fetchClassworks();
      } else {
        setActive(3);
        setMessage(res?.error);
      }
    }
    setIsMailOpen(false);
  };

  const openMail = (Component, datas, e) => {
    e.stopPropagation();
    setIsModalOpen(false);
    setMailContent(
      <Component
        user={user}
        datas={datas}
        onConfirm={deleteClasswork}
        id={datas.classwork_id}
        closeMail={closeMail}
        e={e}
        strands={strands}
        setStrands={setStrands}
      />
    );
    setIsMailOpen(true);
  };

  const closeMail = () => {
    // fetchClassworks();
    setIsMailOpen(false);
  };

  const openMenu = (classwork_id, e) => {
    e.stopPropagation();
    setActiveMenu(classwork_id);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <Top
        user={user}
        openMail={openMail}
        strands={strands}
        classes={classes}
        setStrands={setStrands}
        setClasses={setClasses}
        setClassworks={setClassworks}
        copyClasswork={copyClasswork}
      />

      {loading ? (
        <Default active={1} />
      ) : classworks.length === 0 ? (
        <Default active={2} />
      ) : (
        <Modal>
          <div className={s.cardWrapper}>
            {classworks.map((classwork, i) => (
              <span
                className={s.card}
                key={i}
                style={{ backgroundColor: classwork?.color }}
                onClick={(e) => openMail(View, classwork, e)}
              >
                <span className={s.startBanner}>
                </span>
                <span className={s.title}>{classwork.title}</span>
                <span
                  className={s.kebab}
                  onClick={(e) => openMenu(classwork.classwork_id, e)}
                >
                  {icons.kebabFill}
                </span>
                {activeMenu === classwork.classwork_id && (
                  <span className={s.cardMenu}>
                    <button
                      onClick={(e) =>
                        openMail(Prompt, classwork.classwork_id, e)
                      }
                    >
                      {icons.deleteFill}
                    </button>
                    <button onClick={(e) => openMail(Edit, classwork, e)}>
                      {icons.updateFill}
                    </button>
                  </span>
                )}
              </span>
            ))}
          </div>
        </Modal>
      )}

      {isMailOpen && (
        <Mail title="Modal Title" isClose={closeMail} isOpen={isMailOpen}>
          {mailContent}
        </Mail>
      )}
    </div>
  );
}

export default Main;
