import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "../../../components/css/global.module.css";
import s from "./enrollment.module.css";
import {
  fetchData,
  postData,
  updateData,
} from "../../../../../utilities/methods";
import { getDatas } from "../../../../../services/api";
import logo from "../../../../../assets/logo.jpg";
import Default from "../../../../defaults/Default/Default";

function Enrollment() {
  const navigate = useNavigate();
  const [strands, setStrands] = useState([]);
  const [message, setMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const fetchDatas = async () => {
    const url = `/student-api/strands`;
    try {
      const datas = await fetchData(url, setError);
      setStrands(datas || []);
    } catch (error) {
      console.error("Error fetching strands:", error);
    }
  };

  const goEnroll = async (str) => {
    const postURL = `/student-api/enrollments`;
    const putURL = `/student-api/enrollments/update`;

    const values = {
      status: "Pending",
      teacher_id: str.teacher_id,
      strand_id: str.strand_id,
      student_id: user.student_id,
    };
    const datas =
      user.enrollment_id === null
        ? await postData(postURL, values, setError)
        : await updateData(putURL, values, setError);

    if (datas) {
      setMessage(datas.message);
      navigate("/student/pending");
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % strands.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + strands.length) % strands.length
    );
  };

  return (
    <>
      <div className={s.header}>
        <span>
          <img src={logo} alt="logo" className={s.logo}></img>
          <h2 className={s.title}>Trackademy</h2>
        </span>
        <button
          type="button"
          onClick={() => navigate("/student/login")}
          className={s.backButton}
        >
          &larr; Back
        </button>
      </div>

      <div className={s.outer}>
        <div className={s.inner}>
          <div
            className={s.slider}
            style={{
              "--quantity": strands.length, 
              transform: `translateX(-${currentIndex * 300}px)`,
            }}
          >
            {strands.length > 0 ? (
              strands.map((strand, i) => (
                <div key={i} className={s.item}>
                  <img
                    src={strand.image || logo} 
                    alt={`Card ${strand.id}`}
                    className={s.img}
                  />
                  {strand.year} - {strand.strand}
                  <button
                    type="button"
                    onClick={() => goEnroll(strand)}
                    className={style.button}
                  >
                    Enroll Now
                  </button>
                </div>
              ))
            ) : error ? ( 
              <Default active = {2}></Default>
            ) : (
              <Default active={2} />
            )}
          </div>
        </div>
        <div className={s.buttons}>
          <button className={style.button} onClick={prevSlide}>
            &larr; Previous
          </button>
          <button className={style.button} onClick={nextSlide}>
            Next &rarr;
          </button>
        </div>
      </div>
      <div className={s.footer}></div>
    </>
  );
}

export default Enrollment;
