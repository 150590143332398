export const startVideo = async (videoRef, size) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { width: size, height: size },
      audio: false,
    });
    
    if (videoRef.current) {
      videoRef.current.srcObject = stream;

      // Wait until the video metadata is loaded to check dimensions
      videoRef.current.onloadedmetadata = () => {
        if (videoRef.current.videoWidth > 0 && videoRef.current.videoHeight > 0) {
          videoRef.current.play();
          console.log(`Video started with dimensions: ${videoRef.current.videoWidth}x${videoRef.current.videoHeight}`);
        } 
      };
    }
  } catch (error) {
    console.error("Error accessing video stream:", error);
  }
};
