import React from "react";
// center layout
import Center from "../Center_layout/Center";
// styles
import s from "./prompt.module.css";
import style from '../style_configs/buttons.module.css'
// icons
import { icons } from "../../../../utilities/icons";

function Prompt({ id, onConfirm, imageUrl, videoUrl,content,e}) {
  const handleYes = (e) => {
    e.stopPropagation();  // Prevents the event from bubbling up
    onConfirm(true, id, e, content?.email || "", imageUrl || "", videoUrl || "");
  };
  
  const handleNo = (e) => {
    e.stopPropagation();  // Prevents the event from bubbling up
    onConfirm(false, id, e);
  };
  

  return (
    <Center>
      <div className={s.card}>
        <div className={s.header}>
          <h2 className={s.logo}>{icons.gradFill}</h2>
        </div>
        <div className={s.main}>
          Do you want to proceed?
        </div>
        <div className={s.footer}>
          <button className={style.button} onClick={handleYes}>Yes</button>
          <button className={style.button} onClick={handleNo}>No</button>
        </div>
      </div>
    </Center>
  );
}

export default Prompt;
