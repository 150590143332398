import React, { useRef, useState } from "react";
import { icons } from "../../../../../../utilities/icons";
import style from "../../../../components/css/buttons.module.css";
import s from "./css/top.module.css";
import { cleanString } from "../../../../../../utilities/methods";

function Top({ teachersCopy, setTeachers }) {
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    if (value.trim() === "") {
      setTeachers(teachersCopy);
    } else {
      setTeachers(
        teachersCopy.filter((prev) =>
          cleanString(prev.fname + prev.lname).includes(cleanString(value))
        )
      );
    }
  };

  const toggleSearch = () => {
    setSearchVisible((prevVisible) => !prevVisible);

    if (searchVisible) {
      setSearchQuery("");
      setTeachers(teachersCopy);
    } else {
      setTimeout(() => searchRef.current?.focus(), 0);
    }
  };

  return (
    <div className={s.parent}>
      <div className={s.right}>
          <input
            type="search"
            placeholder="Search..."
            className={style.search}
            ref={searchRef}
            value={searchQuery}
            onChange={handleSearch}
          />
        <button className={s.button} onClick={toggleSearch}>
          {icons.searchFill}
        </button>
      </div>
    </div>
  );
}

export default Top;
