import React, { useState } from "react";
import s from "./view.module.css";
import { icons } from "../../../../../../../utilities/icons";
import Announce from "./Announce";
import Email from "./Email";
import Prompt from "../../../../../components/Prompt/Prompt";
import Popup from "../../../../../components/Popup/Popup";
import { deleteDatas, postDatas } from "../../../../../../../services/api";
import Mail from "../../../../../components/Mail_layout/Mail";

function View({ datas, closeTeacherInfo, setTeachers }) {
  const [modalContent, setModalContent] = useState(null);
  const [isMailOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");

  const openMail = (Component, datas) => {
    setModalContent(
      <Component
        closeModal={closeModal}
        // prompt
        onConfirm={goConfirm}
        id={datas.teacher_id}
        datas={datas}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const goConfirm = async (confirmation, teacher_id) => {
    const { email } = datas;
    setError(null);
    setMessage(null);
    setActive(null);

    if (confirmation) {
      const url = `/admin-api/delete/teacher`;
      const datas = {
        teacher_id: teacher_id,
      };
      try {
        const res = await deleteDatas(url, datas, setError);

        if (res && res.response) {
          setActive(1);
          setMessage(res.response);
          setTeachers((prev) =>
            prev.filter((teacher) => teacher.teacher_id !== teacher_id)
          );
          const subject = `Notice of LMS Account Removal`;
          const text = `Your LMS account have been deleted by the administration, please contact the administration`;
          const emailDatas = {
            email: email,
            subject: subject,
            text: text,
          };

          try {
            setError(null);
            await postDatas(`/admin-api/mailer`, emailDatas, setError);
          } catch (error) {
            setActive(3);
            setMessage("Failed to Send Deletion Email Notification to Teacher");
          }
        }
      } catch (error) {
        setActive(3);
        setMessage("Failed to Remove Teacher");
        console.log(error);
      }
    }
    closeTeacherInfo();
  };

  return (
    <>
      <Popup message={message} active={active} />

      {isMailOpen ? (
        <Mail>{modalContent}</Mail>
      ) : (
        <>
          <div className={s.infoContainer}>
            <div className={s.header}>
              TEACHER INFORMATION
              <button className={s.modalButton} onClick={closeTeacherInfo}>
                Back
              </button>
            </div>

            <div className={s.teacherInfo}>
              <span className={s.teacherImage}>
                <img
                  src={datas.image}
                  className={s.image}
                  loading="lazy"
                  alt="profile"
                ></img>
              </span>
              <b className={s.label}>Name: </b>
              {`${datas.fname} ${
                datas.mname != null ? datas.mname + "." : " "
              } ${datas.lname}`}
              <br />
              <b className={s.label}>Contact: </b>
              {datas.contact}
              <br />
              <b className={s.label}>Email: </b>
              {datas.email}
              <br />
            </div>
            <div className={s.buttons}>
              <button
                type="button"
                className={s.button}
                onClick={() => openMail(Email, datas)}
              >
                <span className={s.icon}>{icons.emailFill}</span>
              </button>
              <button
                type="button"
                className={s.button}
                onClick={() => openMail(Announce, datas)}
              >
                <span className={s.icon}>{icons.notifFill}</span>
              </button>
              <button
                type="button"
                className={s.button}
                onClick={() => openMail(Prompt, datas)}
              >
                {icons.deleteFill}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default View;
