import React, { useEffect, useState } from "react";
import s from "../css/dashboard.module.css";
import { getDatas } from "../../../../../services/api";
import StudentClasses from "./StudentClasses";
import AvailableClasses from "./AvailableClasses";
import Default from "../../../../defaults/Default/Default";

function Main({ user }) {
  const [error, setError] = useState("");
  const [classes, setClasses] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchStudentClasses = async () => {
    setLoading(true);
    try {
      const res = await getDatas(
        `/student-api/learn/classes/${user.student_id}`,
        setError
      );
      if (res && res.response) {
        setClasses(res.response);
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
 
  };

  useEffect(() => {
    if (user && user.strand_id && user.student_id) {
      fetchStudentClasses();
    }
  }, [user]);

  const openModal = (Component, cl) => {
    setModalContent(
      <Component
        datas={cl}
        student_id={user?.student_id}
        setIsModalOpen={setIsModalOpen}
        fetchStudentClasses={fetchStudentClasses}
      />
    );
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen ? (
        modalContent
      ) : loading ? (
        <Default active={1} />
      ) : error ? (
        <Default active={2} />
      ) :(
        <section className={`${s.dashboardContainer} ${s.fadeIn}`}>
          <StudentClasses classes={classes} user={user} openModal={openModal} />
          <AvailableClasses
            classes={classes}
            user={user}
            fetchStudentClasses={fetchStudentClasses}
          />
        </section>
      )}
    </>
  );
}

export default Main;
