import React from "react";
import Center from "../Center_layout/Center";
import s from './style.module.css';
function Modal({ children }) {
  return (
    <Center>
      <section className={s.modalContainer}>
        {children}
      </section>
    </Center>
  );   
}

export default Modal;
 