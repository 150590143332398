// A.js
export function setFuckyou(fp) {
  const Fuckyou = new fp.GestureDescription("Fuck You");

  // Gesture I: 2 Nocurl 3 fullCurl
  Fuckyou.addCurl(fp.Finger.Thumb, fp.FingerCurl.FullCurl, 1.0);
  Fuckyou.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  Fuckyou.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  Fuckyou.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  Fuckyou.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  Fuckyou.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);

  return Fuckyou;
}
