import React, { useEffect, useState } from "react";
import { icons } from "../../../../../utilities/icons";
import s from "../css/top.module.css";
import { getDatas } from "../../../../../services/api";
import { handleToggler } from "../../../../../utilities/methods";
import Stats from "../modals/Stats";

function Top({ user, setClassworks, originalClassworks, openMail, setStats }) {
  const [error, setError] = useState("");
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false);
  const [classes, setClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClassId, setSelectedClassId] = useState("");

  const fetchStudentClasses = async () => {
    try {
      const res = await getDatas(
        `/student-api/scoreboard/classes/${user.enrollment_id}/${user.student_id}`,
        setError
      );
      if (res && res.response) {
        setClasses(res.response.filter((prev) => prev.participant_id !== null));
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchStudentClasses();
  }, [user]);

  useEffect(() => {
    setClassworks(
      originalClassworks.filter(
        (cw) =>
          (!selectedClassId || cw.class_id === parseInt(selectedClassId)) &&
          (!searchTerm ||
            cw.title.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  }, [selectedClassId, searchTerm, originalClassworks, setClassworks]);

  useEffect(() => {
    const newStats = originalClassworks
      .filter(
        (cw) => !selectedClassId || cw.class_id === Number(selectedClassId)
      )
      .reduce(
        (acc, cur) => {
          const { score, max_score,title } = cur;

          acc.totalScores += score || 0;
          acc.totalMaxScores += max_score || 0;
          acc.class_title = 
          acc.totalAssessments += 1;
          if (score !== null) acc.scoredAssessments += 1;

          return acc;
        },
        {
          totalScores: 0,
          totalMaxScores: 0,
          totalAssessments: 0,
          scoredAssessments: 0,
          average: 0,
          class_title:"",
        }
      );

    newStats.average = newStats.totalMaxScores
      ? (newStats.totalScores / newStats.totalMaxScores) * 100
      : 0;

    setStats(newStats);
  }, [selectedClassId, searchTerm, originalClassworks, setClassworks]);

  return (
    <div className={s.parent}>
      <div className={s.selectContainer}>
        <select
          name="class_id"
          onChange={(e) => setSelectedClassId(e.target.value)}
        >
          <option value="">Class</option>
          {classes.map((cl, i) => (
            <option value={cl.class_id} key={i}>
              {cl.title + " - " + cl.description}
            </option>
          ))}
        </select>
        <button
          className={s.averageButton}
          onClick={(e) => openMail(Stats, "", e)}
        >
          STATS
        </button>
        {isSearchbarVisible && (
          <input
            type="search"
            placeholder="Search by title"
            className={s.search}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
      </div>
      <div className={s.searchContainer}>
        <span
          className={s.searchIcon}
          onClick={() =>
            handleToggler(isSearchbarVisible, setIsSearchbarVisible)
          }
        >
          {icons.searchFill}
        </span>
      </div>
    </div>
  );
}

export default Top;
