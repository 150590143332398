import React, { useEffect, useReducer } from "react";
import s from "./default.module.css";
import loading from "../../../assets/loading3.gif";
import { icons } from "../../../utilities/icons";
// 1 = loading
// 2 = no data 
// 3 = error 
function reducer(state, action) {  
  switch (action.type) {
    case 1: 
      return { ...state, icon: "" };
    case 2: 
      return { ...state, icon: icons.infoFill,text:"No Record Found"};
    case 3:
      return { ...state, icon: icons.crossFill,text:"Something Went Wrong" }; 
    default:
      return { ...state, icon: "" };
  }
} 

function Default({ text, active }) {
  const initialState = { icon: "",text:"" };
  const [state, dispatch] = useReducer(reducer, initialState);

  // redispatch every component load or active state change
  useEffect(() => {
    dispatch({ type: active });
  }, [active]);

  return (
    <div className={s.defaultContainer}>
   
      {active === 1 ? (
      <img src = {loading} alt="loading screen" className = {s.loadingImage}></img>
      ): (
        <>
           <span>{state.icon}</span>
           <span>{state.text}</span>
        </>
      )}
    </div>
  );
}

export default Default;
