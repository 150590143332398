import React, { useState } from "react";
// styles
import s from "./view.module.css";
// icons
import { icons } from "../../../../../../../utilities/icons";
// component
import Announce from "./Announce";
import Email from "./Email";
import Prompt from "../../../../../components/Prompt/Prompt";
import Popup from "../../../../../components/Popup/Popup";
import Modal from "../../../../../components/Modal_layout/Modal";
import { deleteDatas, postDatas } from "../../../../../../../services/api";

function View({ datas, closeStudentInfo, setStudents }) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");

  const openModal = (Component, datas) => {
    setModalContent(
      <Component
        closeModal={closeModal}
        // prompt
        onConfirm={goConfirm}
        id={datas.user_id}
        datas={datas}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  // student dropping or delete
  const goConfirm = async (confirmation, user_id) => {
    const { email } = datas;
    setError(null);
    setMessage(null);
    setActive(null);

    if (confirmation) {
      const url = `/admin-api/delete/student`;
      const datas = {
        user_id,
      };
      try {
        const res = await deleteDatas(url, datas, setError);

        if (res) {
          setActive(1);
          setMessage("Student Dropping Successfull");
          // !error update UI
          setStudents((prev) =>
            prev.filter((stud) => stud.user_id !== user_id)
          );
          // sent email notification to teacher
          const subject = `Notice of LMS Account Removal`
          const text = `Your LMS account have been deleted by the administration, please contact the administration`
          const emailDatas = {
            email: email,
            subject: subject,
            text: text,
          }
          await postDatas(`/admin-api/mailer`, emailDatas, setError)
        }
      } catch (error) {
        setActive(3);
        setMessage("Failed to Drop Student");
        console.log(error);
      }
    }
    closeStudentInfo();
  };

  return (
    <>
      <Popup message={message} active={active} />

      {isModalOpen ? (
        <Modal>{modalContent}</Modal>
      ) : (
        <>
         
          <div className={s.infoContainer}>
            <div className={s.header}>STUDENT INFORMATION
            <button className={s.modalButton} onClick={closeStudentInfo}>
            Back
          </button>
            </div>

            <div className={s.studInfo}>
              <span className={s.studImage}>
                <img src={datas.image} className={s.image} loading="lazy" alt="profile"></img>
              </span>
              <b className={s.label}>Name: </b>
              {`${datas.fname} ${datas.mname != null ? datas.mname + "." : " "
                } ${datas.lname}`}
              <br />
              <b className={s.label}>Contact: </b>
              {datas.contact}
              <br />
              <b className={s.label}>Email: </b>
              {datas.email}
              <br />
            </div>
            <div className={s.buttons}>
              <button
                type="button"
                className={s.button}
                onClick={() => openModal(Email, datas)}
              >
                <span className={s.icon}>{icons.emailFill}</span>
              </button>
              <button
                type="button"
                className={s.button}
                onClick={() => openModal(Announce, datas)}
              >
                <span className={s.icon}>{icons.notifFill}</span>
              </button>
              <button
                type="button"
                className={s.button}
                onClick={() => openModal(Prompt, datas)}
              >
                Drop
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default View;
