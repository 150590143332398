// A.js
export function setLetterV(fp) {
    const letterV = new fp.GestureDescription("V");
  
 
    letterV.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
    letterV.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
    letterV.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
    letterV.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
    letterV.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);
  
  
    letterV.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.5);
    // letterV.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.8);
  
    letterV.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);
  
    // letterV.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpRight, 1.0);
    // letterV.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 0.8);
    return letterV;
  }
  