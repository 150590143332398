import React, { useEffect, useState } from "react";
// style
import style from "./css/Dashboard.module.css";
import s from '../../components/css/top_side_main.module.css';
 // components
import Center from "../../components/Center_layout/Center";
import Sidebar from "./Sidebar";
import Navbar from '../../components/Header/Header'
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { getDatas } from "../../../../services/api";

const Dashboard = () => {
const [modalContent, setModalContent] = useState();
const navigate = useNavigate();
const [error, setError] = useState("");
const [user, setUser] = useState([]);

const requestUserData = async () => {
  try {
    const res = await getDatas("/profile/data", setError);
    
    if (res && res.user) {
      setUser(res.user); 
      console.log(user);
      console.log(JSON.parse(localStorage.getItem('role')))
    } else {
      console.log("redirecting")
      navigate("/unauthorized"); 
    }
  } catch (error) {
    console.error("Error fetching user data:", error); 
  }
};


useEffect(() => {
  requestUserData();
}, []);


  return (
    <div className={style.dashboardContainer}>
      <header className={s.header}>
        <Navbar title="LMS" />
      </header>
      <aside className={s.sidebar}>
        <Sidebar setModalContent={setModalContent} />
      </aside> 
      <section className={s.main}>
        <Center>
          <Header setModalContent = {setModalContent} />
          {modalContent}
        </Center>
      </section>
    </div>
  );
};

export default Dashboard;
