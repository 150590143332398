import okay from "../../assets/images/words/okay.png";
import yehey from "../../assets/images/words/yehey.gif";
import no from "../../assets/images/words/no.png";
import thanks from "../../assets/images/words/thanks.png";
import hi from "../../assets/images/words/hi.png";
import space from "../../assets/images/letters/space.png";
import peace from "../../assets/images/words/peace.gif";
import dont from "../../assets/images/words/dont.gif";
import joke from "../../assets/images/words/joke.gif";
import approved from "../../assets/images/words/approved.png";
import click from "../../assets/images/words/click.png";
import coffee from "../../assets/images/words/coffee.png";
import correct from "../../assets/images/words/correct.png";
import disapproved from "../../assets/images/words/disapproved.png";
import eyyy from "../../assets/images/words/eyyy.png";
import good from "../../assets/images/words/good.png";
import love from "../../assets/images/words/love.png";
import praise from "../../assets/images/words/praise.png";
import pray from "../../assets/images/words/pray.png";
import right from "../../assets/images/words/right.png";
import save from "../../assets/images/words/save.png";
import up from "../../assets/images/words/up.png";
import write from "../../assets/images/words/write.png";
import wrong from "../../assets/images/words/wrong.png";
import zero from "../../assets/images/words/zero.png";
import one from "../../assets/images/words/one.png";
import two from "../../assets/images/words/two.png";
import three from "../../assets/images/words/three.png";
import four from "../../assets/images/words/four.png";
import five from "../../assets/images/words/five.png";
import six from "../../assets/images/words/six.png";
import seven from "../../assets/images/words/seven.png";
import eight from "../../assets/images/words/eight.png";
import nine from "../../assets/images/words/nine.png";
import ten from "../../assets/images/words/ten.png";
import kidding from "../../assets/images/words/kidding.gif";
import clap from "../../assets/images/words/clap.png";
import hello from "../../assets/images/words/hello.png";

import iloveyou from "../../assets/images/phrases/iloveyou.png";
import donot from "../../assets/images/phrases/donot.gif";
import washhands from "../../assets/images/phrases/washhands.gif";
import washhand from "../../assets/images/phrases/washhands.gif";
import callme from "../../assets/images/phrases/callme.png";
import fuckyou from "../../assets/images/phrases/fuckyou.png";
import getyourpencils from "../../assets/images/phrases/getyourpencils.png";
import getyourphones from "../../assets/images/phrases/getyourphones.png";
import lendmeahand from "../../assets/images/phrases/lendmeahand.png";
import letsbefriends from "../../assets/images/phrases/letsbefriends.png";
import morelove from "../../assets/images/phrases/morelove.png";
import takeapicture from "../../assets/images/phrases/takeapicture.png";
import thankyou from "../../assets/images/phrases/thankyou.png";

const wordImages = {
  dont,
  hi,
  okay,
  thanks,
  peace,
  no,
  joke,
  clap,
  approved,
  click,
  hello,
  coffee,
  correct,
  disapproved,
  eyyy,
  good,
  love,
  praise,
  pray,
  right,
  save,
  up,
  write,
  wrong,
  zero,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  kidding,
  yehey,
  " ": space,
};

const phraseImages = {
  "i love you": iloveyou,
  "thank you": thankyou,
  "do not": donot,
  "wash hands": washhands,
  "wash hand": washhand,
  "call me": callme,
  "fuck you": fuckyou,
  "get your pencils": getyourpencils,
  "get your phones": getyourphones,
  "lend me a hand": lendmeahand,
  "lets be friends": letsbefriends,
  "more love": morelove,
  "take a picture": takeapicture,
};

export { wordImages, phraseImages };
