import React from 'react'
import s from '../css/modal.module.css'

function Stats({ closeMail,datas, stats }) {
  console.log("stats: ", stats);

  return (
    <div className={`${s.statContainer} ${s.zoomIn}`}>
      <div className={s.header}>
        <span>STATS</span>
        <button onClick={closeMail}>Close</button>
      </div>
      <div className={s.statMain}>
        <span className={s.average}>
        <p className={s.sub}>Records:  {stats.scoredAssessments} / {stats.totalAssessments} </p>
          <p className={s.sub}>Total Scores: {stats.totalScores} / {stats.totalMaxScores}</p>
          <p  className={s.submain}>Average:</p>
          <span>{`${stats.average.toFixed(2)}%`}</span>
        </span>
      </div>
    </div>
  );
}

export default Stats;


// {
//   "totalScores": 15,
//   "totalMaxScores": 15,
//   "totalAssessments": 1,
//   "scoredAssessments": 1,
//   "average": 100
// }
