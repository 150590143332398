// react icons for this project
import { RiGraduationCapFill } from "react-icons/ri";
import { RiGraduationCapLine } from "react-icons/ri"; 
import { AiFillDashboard } from "react-icons/ai"; 
import { AiOutlineDashboard } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io"; 
import { IoSettingsOutline } from "react-icons/io5"; 
import { IoSettingsSharp } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5"; 
import { IoHomeSharp } from "react-icons/io5";
import { IoNewspaperSharp } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";  
import { FaRegEdit } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";  
import { FaEye } from "react-icons/fa"; 
import { FaRegEye } from "react-icons/fa";
import { MdAssessment } from "react-icons/md";
import { MdOutlineAssessment } from "react-icons/md";
import { HiOutlineTrophy } from "react-icons/hi2";
import { HiMiniTrophy } from "react-icons/hi2";
import { FaUserGraduate } from "react-icons/fa6";
import { PiStudent } from "react-icons/pi";
import { GiProgression } from "react-icons/gi"; 
import { MdAnnouncement } from "react-icons/md";
import { MdOutlineAnnouncement } from "react-icons/md";
import { RiAddBoxFill } from "react-icons/ri";
import { RiAddBoxLine } from "react-icons/ri";
import { FaHandsAslInterpreting } from "react-icons/fa6";
import { RiSpeakFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { IoNotifications } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TiWarning } from "react-icons/ti"; 
import { TiWarningOutline } from "react-icons/ti";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { IoLogOut } from "react-icons/io5";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoArrowUpCircle } from "react-icons/io5";
import { SiGoogleclassroom } from "react-icons/si";
import { FaUserLock } from "react-icons/fa6";
import { PiStudentBold } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaFilter } from "react-icons/fa6";
import { FaUsersCog } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";
import { TiThMenuOutline } from "react-icons/ti";
import { IoColorPalette } from "react-icons/io5";
import { IoColorPaletteOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoKeyOutline } from "react-icons/io5";
import { IoKeySharp } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";

import { CiCircleCheck } from "react-icons/ci";
import { FaBullhorn } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6"; 
import { FaRegCircleXmark } from "react-icons/fa6";
import { IoAttach } from "react-icons/io5"; 
import { FaRegCalendar } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegCopy,FaTools } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";


export const icons = {
  emailOpenLine:<HiOutlineMailOpen/>,
  toolFill: <FaTools/>,
  downloadFill: <IoMdDownload/>,
  //copy
  copyFill:<FaRegCopy/>,
  //kebab
  kebabLine:<CiMenuKebab/>,
  kebabFill:<HiDotsVertical/>,
  // horn
  hornFill :<FaBullhorn/>,
  //calender
  calendarFill :<FaRegCalendar/>,
  // clip
  clipFill :<IoAttach />,
  // cross<IoAttach />
  crossFill :<FaCircleXmark/>,
  crossLine :<FaRegCircleXmark/>,
  // check
  checkFill :<CiCircleCheck/>,
  checkLine :<FaCircleCheck/>,
  // update profile
  updateInfoFill :<CgProfile/>,
  //key
  keyFill :<IoKeySharp/>,
  keyLine :<IoKeyOutline/>,
  // learning, teaching
  gradFill: <RiGraduationCapFill />,
  gradLine: <RiGraduationCapLine />,
  // settings
  setFill: <IoSettingsSharp />,
  setLine: <IoSettingsOutline />,
  // dashboard
  dashFill: <AiFillDashboard />,
  dashLine: <AiOutlineDashboard />,
  // home
  homeFill: <IoHomeSharp />,
  homeLine: <IoHomeOutline />,
  // features add/ update/ delete/ view
  viewFill: <FaEye />,
  viewLine: <FaRegEye />,
  addFill: <RiAddBoxFill />,
  addLine: <RiAddBoxLine />,
  deleteFill: <BsFillTrashFill />,
  deleteLine: <BsTrash />,
  updateFill: <FaRegEdit />,
  updateLine: <FaEdit />,
  // color
  colorFill: <IoColorPalette />,
  colorLine: <IoColorPaletteOutline />,

  // news / updates / report
  newsFill: <IoNewspaperSharp />,
  newsLine: <IoNewspaperOutline />,
  //   assessment
  assFill: <MdAssessment />,
  assLine: <MdOutlineAssessment />,
  //ranking
  rankFill: <HiMiniTrophy />,
  rankLine: <HiOutlineTrophy />,
  //users
  userFill: <FaUserGraduate />,
  userLine: <PiStudent />,
  //   progress
  progFill: <GiProgression />,
  // announcement
  annFill: <MdAnnouncement />,
  annLine: <MdOutlineAnnouncement />,
  //speech2sign
  speechFill: <RiSpeakFill />,
  // sign2speech
  signFill: <FaHandsAslInterpreting />, 
  // email
  emailFill: <MdEmail />,
  emailLine: <MdOutlineEmail />,
  //notification
  notifFill: <IoNotifications />,
  notifLine: <IoMdNotificationsOutline />,
  // check
  checkFill: <IoIosCheckmarkCircle />,
  checkLine: <IoIosCheckmarkCircleOutline />,
  // warning
  warnFill: <TiWarning />,
  warnLine: <TiWarningOutline />,
  // warning
  backFill: <IoArrowBackCircleSharp />,
  backLine: <IoArrowBackCircleOutline />,
  // warning
  nextFill: <IoArrowForwardCircleSharp />,
  nextLine: <IoArrowForwardCircleOutline />,
  // drop
  dropFil: <IoLogOut />,
  // arrow right
  arFill: <IoIosArrowDroprightCircle />, // arrow up
  auFill: <IoArrowUpCircle />, 
  // strand
  classLine: <SiGoogleclassroom />,
  // account
  accFill: <FaUserLock />,
  // teacher
  teacherFill: <FaChalkboardTeacher />,
  // student
  studentFill: <FaUserGraduate />,
  studentLine: <PiStudentBold />,
  // search
  searchFill: <FaSearch />,
  // filter
  filterFill: <FaFilter />,
  // share
  shareFill: <FaUsersCog/>,
  infoFill:<FaInfoCircle />,
sendFill: <IoSend />,
// menu
hamburgerFill:<TiThMenu/>,
hamburgerLine:<TiThMenuOutline/>,
};
