import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// components
import Center from "../../../components/Center_layout/Center";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Default from "../../../../defaults/Default/Default";
import Main from "../parts/classes/classses_content/Main";
// style
import s from "../../../components/style_configs/top_side_main.module.css";
import style from "./classes.module.css";
// methods
import { getDatas } from "../../../../../services/api";

function Classes() {
  const { strand_id } = useParams();
  const [classes, setClasses] = useState([]);
  const [copy, setCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const fetchClasses = async (teacher_id) => {
    setError(null);
    setLoading(true);
    const url = `/teacher-api/classes/${strand_id}/${teacher_id}`;
    try {
      const datas = await getDatas(url, setError);
      if (datas && datas.response) {
        setClasses(datas.response);
        setCopy(datas.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && user.teacher_id) {
      fetchClasses(user.teacher_id);
    }
  }, [user]);

  return (
    <>
      {user ? (
        <div className={style.classContainer}>
          <header className={s.header}>
            <Header title="CLASSES" user={user} />
          </header>
          <aside className={s.sidebar}>
            <Sidebar />
          </aside>
          <section className={s.main}>
            <Center>
              {error ? (
                <Default active={3} />
              ) : loading ? (
                <Default active={1} />
              ) : (
                <Main
                  copy={copy}
                  Classes={classes}
                  strand_id={strand_id}
                  teacher_id={user.teacher_id}
                  setClasses={setClasses}
                  fetchClasses={fetchClasses}
                  user={user}
                />
              )}
            </Center>
          </section>
        </div>
      ) : (
        <Default active={1} />
      )}
    </>
  );
}

export default Classes;
