// A.js
export function setLetterJ(fp) {
    const letterJ = new fp.GestureDescription("J");
  
  // Gesture I: 2 Nocurl 3 fullCurl
  letterJ.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterJ.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterJ.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterJ.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterJ.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);

  letterJ.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalDownRight, 0.5);
  letterJ.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalDown, 1.0);
  letterJ.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalDownRight, 0.5);
  letterJ.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalDown, 1.0);
  letterJ.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalDownRight, 0.5);

    return letterJ;
  }
  