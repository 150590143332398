import React, { useEffect, useReducer, useRef } from "react";
// style
import s from "./popup.module.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "success":
      return {
        ...state,
        style: { backgroundColor: "#87ff00", borderColor: "green" },
        icon: "✅",
      };
    case "warning":
      return {
        ...state,
        style: { backgroundColor: "yellow", borderColor: "#fde126" },
        icon: "⚠️",
      };
    case "error":
      return {
        ...state,
        style: { backgroundColor: "#cc3e27", borderColor: "#cc1c00" },
        icon: "❌",
      };
    default:
      return state;
  }
};

function Popup({ message, active }) {
  const initial = {
    style: { backgroundColor: "", borderColor: "" },
    icon: "",
  };
  const [state, dispatch] = useReducer(reducer, initial);
  const popupContainerRef = useRef(null);
  const iconRef = useRef(null);

  function showPopup() {
    try {
      if (popupContainerRef.current && iconRef.current) {
        popupContainerRef.current.classList.remove(s.hide);
        popupContainerRef.current.classList.add(s.show);
        iconRef.current.classList.add(s.animated);

        setTimeout(() => {
          if (popupContainerRef.current && iconRef.current) {
            popupContainerRef.current.classList.remove(s.show);
            popupContainerRef.current.classList.add(s.hide);
            iconRef.current.classList.remove(s.animated);
          }
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (active) {
      switch (active) {
        case 1:
          dispatch({ type: "success" });
          break;
        case 2:
          dispatch({ type: "warning" });
          break;
        case 3:
          dispatch({ type: "error" });
          break;
        default:
          break;
      }
      // Delay the showPopup call to ensure refs are set
      setTimeout(() => {
        if (popupContainerRef.current && iconRef.current) {
          showPopup();
        }
      }, 0);
    }
  }, [active]);

  return (
    <div
      className={`${s.popupContainer} ${active ? s.show : s.hide}`}
      style={state.style}
      ref={popupContainerRef}
    >
      <span className={s.iconContainer}>
        <label className={s.icon} ref={iconRef}>
          {state.icon}
        </label>
      </span>
      <span className={s.msgContainer}>
        <label className={s.msg}>{message}</label>
      </span>
    </div>
  );
}

export default Popup;
