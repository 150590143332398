import React, { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// styles
import styles from "./css/signup.module.css";
import sample from "../../images/bg.jpg";
//contexts
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
// icons
import { icons } from "../../../../utilities/icons";
//methods
import {
  navigateTo,
  postData,
  previewFile,
} from "../../../../utilities/methods";
import { v4 } from "uuid";
// firebase
import { storage } from "../../../../configs/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Popup from "../../components/Popup/Popup";

function Signup() {
  const navigate = useNavigate();
  const { globalStyles } = useContext(GlobalStyleContext);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [profile, setProfile] = useState(null);
  const previewImageRef = useRef();
  const [values, setValues] = useState({
    image: "",
    description: "",
    fname: "",
    mname: "",
    lname: "",
    strand: "",
    year: "",
    contact: "",
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setActive(null);

    // if no profile dont proceed
    if (profile == null) {
      setActive(2);
      setMessage("Profile cannot be empty, Please select one");
      return;
    }

    // create unique file name
    const imageRef = ref(storage, `profile/${profile.name + v4()}`);
    try {
      // upload to storage
      await uploadBytes(imageRef, profile);

      const imageUrl = await getDownloadURL(imageRef);

      // Include the download URL in the values object
      const updatedValues = { ...values, image: imageUrl };
      const url = "/student-api/authentication/signup";
      // await posting response
      const response = await postData(url, updatedValues, setError);

      if (response) {
        setActive(1);
        setMessage(
          "Registration Complete You Can Now Login and Access the LMS!"
        );
        navigateTo(navigate, "/login");
      } else {
        setActive(3);
        setMessage("Registration Failed, Please Try Again");
      }
    } catch (error) {
      setActive(3);
      setMessage("Registration Failed, Please Try Again");
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // previewing image
  const previewImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setProfile(image);
      previewFile(image, previewImageRef);
    }
  };

  return (
    <>
      <Popup message={message} active={active} />

      <div
        className={`${styles.container}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div
          className={styles.loginCard}
          style={{ backgroundColor: globalStyles.rtertiary }}
        >
          {/* form on left */}
          <div className={styles.formSection}>
            <div
              className={styles.title}
              style={{ color: globalStyles.primary }}
            >
              Student Sign Up Page
            </div>
            <div
              className={styles.subtitle}
              style={{ color: globalStyles.secondary }}
            >
              Please Sign Up to create an account
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputGroup}>
                <div className={styles.profile}>
                  <input
                    className={styles.input}
                    type="file"
                    name="image"
                    onChange={previewImage}
                    required
                  />
                  <img
                    src=""
                    alt="profile"
                    className={styles.image}
                    ref={previewImageRef}
                  />
                </div>

                {/* description */}
                <input
                  className={styles.input}
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  required
                />

                {/* full name */}
                <div className={styles.name}>
                  {/* fname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="fname"
                    placeholder="First Name"
                    value={values.fname}
                    onChange={handleChange}
                    required
                  />
                  {/* mname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="mname"
                    placeholder="Middle Name"
                    value={values.mname}
                    onChange={handleChange}
                    required
                  />
                  {/* lname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="lname"
                    placeholder="last Name"
                    value={values.lname}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* contact */}
                <input
                  className={styles.input}
                  type="text"
                  name="contact"
                  placeholder="Contact"
                  value={values.contact}
                  onChange={handleChange}
                  required
                />

                <div className={styles.dropdown}>
                  <span>
                    <label>Strand:</label>
                    <select name="strand" onChange={handleChange}>
                      <option value="">Strand</option>
                      <option value="ICT">ICT</option>
                      <option value="STEM">STEM</option>
                      <option value="HUMSS">HUMSS</option>
                      <option value="ABM">ABM</option>
                      <option value="TVL">TVL</option>
                      <option value="GAS">GAS</option>
                    </select>
                  </span>
                  <span>
                    <label>Year:</label>
                    <select name="year" onChange={handleChange}>
                      <option value="">Year</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </span>
                </div>

                <div className={styles.account}>
                  Account Details:
                  {/* email */}
                  <input
                    className={styles.input}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {/* password */}
                  <input
                    className={styles.input}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                className={styles.button}
                style={{
                  backgroundColor: globalStyles.button,
                  color: globalStyles.buttonText,
                }}
              >
                Sign Up
              </button>
            </form>
          </div>
          {/* image on right */}
          <div className={styles.illustrationSection}>
            <div className={styles.illustration}>
              <img src={sample} alt="Illustration" className={styles.image} />
            </div>
          </div>
        </div>
        <Link
          to="/login"
          className={styles.backButton}
          style={{
            backgroundColor: globalStyles.button,
            color: globalStyles.buttonText,
          }}
        >
          <span className={styles.backButtonIcon}>{icons.backFill}</span>
          Back
        </Link>
      </div>
    </>
  );
}

export default Signup;
