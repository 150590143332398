import React, { useEffect, useState } from "react";
import s from "../css/style.module.css";
import Speech2Sign from "./Speech2Sign";
import { useNavigate, useParams } from "react-router-dom";
import socket from "../Utilities/socket";
import bg from "../../assets/images/whity.jpg";
import Footer from "../../../../components/Footer/Footer";
import { BsDoorOpenFill } from "react-icons/bs";

function HostHome() {
  const { room_name, host_name } = useParams();
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    // Check local storage for host info
    const storedHostName = localStorage.getItem("host_name");
    const storedRoomName = localStorage.getItem("room_name");

    if (storedHostName && storedRoomName) {
      // Re-join the room using stored info
      socket.emit("joinRoom", { user: storedHostName, room: storedRoomName });
    } else {
      // Save host info in local storage
      localStorage.setItem("host_name", host_name);
      localStorage.setItem("room_name", room_name);
      socket.emit("joinRoom", { user: host_name, room: room_name });
    }

    const handleParticipants = (data) => {
      setParticipants(data);
    };

    const handleUserJoined = (user) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        `${user} Joined the Room`,
      ]);
    };

    const handleUserLeft = (user) => {
      setMessages((prevMessages) => [...prevMessages, `${user} Left the Room`]);
    };

    const handleMessageSent = (datas) => {
      setChats((prevChats) => [...prevChats, datas]);
    };

    socket.on("participants", handleParticipants);
    socket.on("userJoined", handleUserJoined);
    socket.on("userLeft", handleUserLeft);
    socket.on("messageSent", handleMessageSent);

    // Cleanup listener on component unmount
    return () => {
      socket.off("participants", handleParticipants);
      socket.off("userJoined", handleUserJoined);
      socket.off("userLeft", handleUserLeft);
      socket.off("messageSent", handleMessageSent);
    };
  }, [socket, host_name, room_name]);

  const leaveRoom = () => {
    const datas = { user: host_name, room: room_name };
    socket.emit("leftRoom", datas);
    localStorage.removeItem("host_name");
    localStorage.removeItem("room_name");
    navigate(`/signify`)
  };

  return (
    <>
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>
      <span className={s.sign2speechLeaveRoom}>
        <span onClick={leaveRoom}>
          <BsDoorOpenFill /> <p>Leave</p>
        </span>
      </span>
     
      <Speech2Sign room={room_name} user={host_name} socket={socket} />
    </>
  );
}

export default HostHome;
