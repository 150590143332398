// A.js
export function setSpace(fp) {
  const space = new fp.GestureDescription("space");
  // GestureSpace: Palm flat (index finger extended)
  space.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  space.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalLeft, 0.5); // Index points diagonally

  space.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  // space.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.5); // Index points diagonally

  space.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  space.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 0.5); // Index points diagonally

  space.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
  // space.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpRight, 0.5); // Index points diagonally

  space.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);
  // space.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpRight, 0.5); // Index points diagonally


  return space;
}
