// A.js
export function setLetterI(fp) {
  const letterI = new fp.GestureDescription("I");

  // Gesture I: 2 Nocurl 3 fullCurl
  letterI.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterI.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterI.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterI.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterI.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);

  letterI.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpRight, 0.6);
  letterI.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp, 0.6);
  letterI.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpRight, 0.4);
  letterI.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 0.4);

  return letterI;
}
