import React, { useEffect, useState } from "react";
// icons
import { icons } from "../../../../../utilities/icons";
import s from "../css/top.module.css";
// methods
import Add from "../modals/Add";
import { getDatas } from "../../../../../services/api";
import { handleToggler } from "../../../../../utilities/methods";
import Default from "../../../../defaults/Default/Default";

function Top({
  user,
  openMail,
  strands,
  setStrands,
  classes,
  setClasses,
  setClassworks,
  copyClasswork,
}) {
  const [error, setError] = useState("");
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectChange = async (e) => {
    const { name, value } = e.target;
    if (name === "strand_id") {
      try {
        const res = await getDatas(
          `/teacher-api/classes/${value}/${user.teacher_id}`,
          setError
        );
        if (res && res.response) {
          setClasses(res.response);
          setClassworks(copyClasswork);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (name === "class_id") {
      setClassworks(copyClasswork);
      setClassworks((prev) =>
        prev.filter(
          (classwork) => parseInt(classwork.class_id) === parseInt(value)
        )
      );
    }
  };

  const fetchStrands = async (teacher_id) => {
    setLoading(true);
    setError(null);

    const url = `/teacher-api/strand/${teacher_id}`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setStrands(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStrands(user.teacher_id);
  }, [user]);

  return (
    <div className={s.parent}>
      <div className={s.selectContainer}>
        <button className={s.button} onClick={(e) => openMail(Add, "", e)}>
          {icons.updateFill}
          Create
        </button>

        <select name="strand_id" onChange={(e) => handleSelectChange(e)}>
          <option value={null}>Strand</option>
          {loading ? (
            <Default active={1} />
          ) : (
            strands.map((str, i) => (
              <option value={str.strand_id} key={i}>
                {str.year + " - " + str.strand}
              </option>
            ))
          )}
        </select>
        <select name="class_id" onChange={(e) => handleSelectChange(e)}>
          <option value={null}>Class</option>

          {classes.map((cl, i) => (
            <option value={cl.class_id} key={i}>
              {cl.title + " - " + cl.description}
            </option>
          ))}
        </select>
        {isSearchbarVisible && (
          <input
            type="search"
            placeholder="Search by title"
            className={s.search}
          />
        )}
      </div>
    </div>
  );
}

export default Top;
