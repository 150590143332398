import React, { useContext } from "react";
// styles
import styles from "../css/tool.module.css";
// contexts
import { GlobalStyleContext } from "../../../../../components/other/styles/globalcss";

function Tool({ items }) {
  const { globalStyles } = useContext(GlobalStyleContext);

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={styles.toolContainer}
      onClick={() => handleClick(items.link)}
      style={{ background: globalStyles.bg2 }}
    >
      <img
        src={items.image}
        height="100px"
        width="100px"
        alt={items.description}
        onClick={() => handleClick(items.link)}
        className={styles.toolImage}
      ></img>
      <h4
        className={styles.toolTitle}
        style={{ color: globalStyles.secondary }}
      >
        {items.resourceName}
      </h4>
      <div
        className={styles.descriptionParent}
        style={{ background: globalStyles.rhighlight }}
      >
        <span className={styles.descriptionContainer}>
          <h4 className={styles.toolTitle} style={{ color: globalStyles }}>
            {items.resourceName}
          </h4>
          <p className={styles.toolParagraph} style={{ color: globalStyles }}>
            {items.description}
          </p>
        </span>
      </div>
    </div>
  );
}

export default Tool;
