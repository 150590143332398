import { words,phrase } from "../datas/words";
import socket from "./socket";

 function cleanInput(input) {
    // Replace any non-alphanumeric characters with an empty string
    let cleaned = input.replace(/[^a-zA-Z0-9 ]/g, '');
    
    // Replace multiple consecutive spaces with a single space
    cleaned = cleaned.replace(/\s+/g, ' ');
  
    // Trim leading and trailing spaces
    cleaned = cleaned.trim();
  
    return cleaned;
  }

function processWord(word){
  console.log(word,'exists')
}

function wordExists(word){
    return words.includes(word);
}

function phraseExists(word){
  return phrase.includes(word);
}

export function calculateDistance(point1, point2) {
  const x1 = point1[0],
    y1 = point1[1],
    z1 = point1[2];
  const x2 = point2[0],
    y2 = point2[1],
    z2 = point2[2];

  const distance = Math.sqrt(
    Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) + Math.pow(z2 - z1, 2)
  );

  return distance;
}

export function calculateGap(point1, point2) {
  const x1 = point1[0],
    y1 = point1[1];
  const x2 = point2[0],
    y2 = point2[1];

  const distance = x1 - x2 + (y1 - y2);

  return distance;
}

export const normalizeDistance = (distance, boxAreaSize) => {
  return distance / Math.sqrt(boxAreaSize);
};

export const getThresholds = (boxAreaSize) => {
  let threshold_pinky_thumb = 0.5; // higher than this = A
  let threshold_pinky_palm = 0.375; // higher than this = E
  let threshold_ring_palm = -0.25; // higher than this = N else = M
  let threshold_mid_palm = -0.25; // higher than this = N or M else = S 

  return { 
      threshold_pinky_palm: threshold_pinky_palm,
      threshold_ring_palm: threshold_ring_palm,
      threshold_mid_palm: threshold_mid_palm,
      threshold_pinky_thumb: threshold_pinky_thumb,
  }
}

export {cleanInput,processWord,wordExists,phraseExists};