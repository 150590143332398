export function setLetterY(fp) {
  const letterY = new fp.GestureDescription("Y");

  // Gesture Y: Thumb up, other fingers curled
  letterY.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  letterY.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterY.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterY.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterY.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);

  // letterY.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpLeft, 0.1)
  letterY.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpLeft, 0.5)
  return letterY;
}
