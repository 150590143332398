import React, { useEffect, useState } from "react";
import s from "../css/main.module.css";
import { icons } from "../../../../../utilities/icons";
import Top from "./Top";
// components
import Popup from "../../../components/Popup/Popup";
import Default from "../../../../defaults/Default/Default";
import { getDatas } from "../../../../../services/api";
import { cleanDate } from "../../../../../utilities/methods";
import { useNavigate } from "react-router-dom";
import Mail from "../../../components/Mail_layout/Mail";
import View from "../modals/View";
import Modal from "./../../../../Teacher/components/Modal_layout/Modal";

function Main({
  announcements,
  setAnnouncements,
  fetchAnnouncements,
  copyAnnouncements,
}) {
  const [modalContent, setModalContent] = useState(null);
  const [mailContent, setMailContent] = useState(null);
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [viewedAnnouncements, setViewedAnnouncements] = useState(() => {
    const storedViewed = localStorage.getItem("viewedAnnouncements");
    return storedViewed ? JSON.parse(storedViewed) : [];
  });
  let type = "";

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const openMail = (Component, datas, e) => {
    setModalContent(null);
    showType(datas);
    setMailContent(
      <Component user={user} type={type} datas={datas} closeMail={closeMail} />
    );
    setIsMailOpen(true);
  };

  /**
   * Update local storage announcement IDs
   */
  useEffect(() => {
    localStorage.setItem(
      "viewedAnnouncements",
      JSON.stringify(viewedAnnouncements)
    );
  }, [viewedAnnouncements]);

  const openModal = (Component, datas, e) => {
    setModalContent(null);
    showType(datas);
    e.stopPropagation();
    setModalContent(
      <Component user={user} type={type} datas={datas} closeMail={closeMail} />
    );
    setIsModalOpen(true);
    setIsMailOpen(false);

    // Mark announcement as viewed
    const announcementId = datas.announcement_id;
    if (!viewedAnnouncements.includes(announcementId)) {
      setViewedAnnouncements((prev) => [...prev, announcementId]);
    }
  };

  const closeMail = () => {
    fetchAnnouncements(user.teacher_id);
    setIsMailOpen(false);
    setIsModalOpen(false);
    setModalContent(null);
  };

  /**
   * Customized type for each announcement
   */
  const showType = (announce) => {
    if (announce.strand_id && announce.class_id) {
      type = "Class";
      return "Class";
    } else if (!announce.class_id && announce.strand_id) {
      type = "Strand";
      return "Strand";
    } else {
      type = "Others";
      return "Others";
    }
  };

  // Mark as unread function
  const markAsUnread = (id, e) => {
    e.stopPropagation();
    const newReadAnnouncements = viewedAnnouncements.filter(
      (annId) => annId !== id
    );
    setViewedAnnouncements(newReadAnnouncements);
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>

      <Top
        copyAnnouncements={copyAnnouncements}
        openMail={(Component, datas, e) => openMail(Component, datas, e)}
        setAnnouncements={setAnnouncements}
        announcements={announcements}
      />
      {isModalOpen ? (
        <Modal>{modalContent}</Modal>
      ) : (
        <>
          {announcements.length === 0 ? (
            <Default text={"No Announcement Records Found"} active={2} />
          ) : (
            <div className={s.annParent}>
              <div className={s.mainHeader}></div>

              {announcements.map((announce, i) => {
                const isViewed = viewedAnnouncements.includes(
                  announce.announcement_id
                );

                return (
                  <div className={s.card} key={i}>
                    <div
                      className={s.cardBody}
                      onClick={(e) => {
                        if (e.target.tagName !== "INPUT") {
                          openModal(View, announce, e);
                        }
                      }}
                    >
                      <p
                        className={s.annType}
                        style={{
                          color: isViewed ? "gray" : "black",
                          fontWeight: isViewed ? "normal" : "bold",
                        }}
                      >
                        ⭐ {showType(announce)}
                      </p>
                      <p className={s.annTitle}>{announce.title}</p>
                      <p className={s.annContent}>{announce.content}</p>
                      <p className={s.annExpiry}>
                        Expiring: {cleanDate(announce.expires_at)}
                      </p>
                    </div>
                    <span className={s.annMarkAsUnread}>
                      <p
                        onClick={(e) =>
                          markAsUnread(announce.announcement_id, e)
                        }
                      >
                        {isViewed ? icons.emailOpenLine : icons.emailFill}
                      </p>
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}

      {isMailOpen && (
        <Mail title="Modal Title" isClose={closeMail} isOpen={isMailOpen}>
          {mailContent}
        </Mail>
      )}
    </div>
  );
}

export default Main;
