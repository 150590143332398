import React, { useEffect, useState } from "react";
// styles
import s from "./css/request.module.css";
// icons
import { icons } from "../../../../../../utilities/icons";
// methods
import { deleteData, fetchData, postData  } from "../../../../../../utilities/methods";
// component
import Default from "../../../../../defaults/Default/Default";
import Prompt from "../../../../components/Prompt/Prompt";
import Top from "./TopStrandHandling";
import View from "./modal/View"; 
import Popup from "../../../../components/Popup/Popup";
import { useNavigate } from "react-router-dom";
import { getDatas } from "../../../../../../services/api";
 
function Requests({ reCount }) {
  const [requests, setRequests] = useState([]);
  const [requestsCopy, setRequestsCopy] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      
      if (res && res.user) {
        setUser(res.user); 
      } else {
      console.log("redirecting")

        navigate("/unauthorized"); 
      }
    } catch (error) {
      console.error("Error fetching user data:", error); 
    }
  };
  

  useEffect(() => {
    requestUserData();
  }, []);

  // *******************************************************
  // get all strand handling requests
  // *******************************************************
  const getRequests = async () => {
    setError(null);
    const url = `/admin-api/get/requests`;
    const datas = await fetchData(url, setError);
    if (datas) {
      setRequests(datas);
      setRequestsCopy(datas);
      setLoading(false);
    }
  };

  // *******************************************************
  // accept teacher strand handling request
  // *******************************************************
  const goAccept = async (req) => {
    const { year, strand } = req;
    const email = req?.email;
    console.log(req);
    setError(null)
    setMessage("")
    setActive(null)

    const acceptUrl = `/admin-api/post/collaborator`
    const rejectUrl = `/admin-api/delete/request`;
    const acceptDatas = { strand_id: req.strand_id, teacher_id: req.teacher_id }
    const rejectDatas = { request_id: req.request_id }

    try {
      const accepted = await postData(acceptUrl, acceptDatas, setError);

      if (accepted) {
        setError(null)
        // delete the accepted request on the table
        await deleteData(rejectUrl, rejectDatas, setError);
        setActive(1);
        setMessage("Requests Granted Successfully!");
        getRequests();
        reCount();
        // email notification
        const emailDatas = {
          email: email,
          subject: `Strand Handling Request Status - Approved`,
          text: `Your Request to handle ${year} ${strand} has been approved by the administrator`,
        }

        setError(null);
        const sent = await postData("/admin-api/mailer", emailDatas, setError)
        if (sent) {
          console.log("Email Sent")
        } else {
          console.log("Faile dot Email")
        }
      }
    } catch (error) {
      setActive(3);
      setMessage("Failed to Accept teacher strand handling request");
    }
  }

  // ***************************************************
  // reject teacher strand handling requests
  // ***************************************************
  const goReject = async (confirmation, request_id, datas) => {
    console.log(datas);
    const year = datas?.year;
    const strand = datas?.strand;
    const email = datas?.email;

    setError(null);
    setActive(null);

    if (confirmation) {
      const url = `/admin-api/delete/request`;
      const datas = { request_id };
      const res = await deleteData(url, datas, setError);

      if (res) {
        setActive(1);
        setMessage("Successfully Rejected strand handling requests");
        setRequests(prev => prev.filter((req) => req.request_id !== request_id))
        reCount();
        // email notification
        const emailDatas = {
          email: email,
          subject: `Strand Handling Request Status - Rejected`,
          text: `Your Request to handle ${year} ${strand} has been Rejected by the administrator`,
        }

        const sent = await postData("/admin-api/mailer", emailDatas, setError)
        if (sent) {
          console.log("Email Sent")
        } else {
          console.log("Faile dot Email")
        }

      }
    }
    setIsModalOpen(false);
  };

  // *******************************************************
  // modal toggler and use Effect
  // *******************************************************
  const openModal = (Component, req) => {
    setModalContent(<Component datas={req} id={req.request_id}

      closeStudentInfo={() => setIsModalOpen(false)}
      onConfirm={goReject} />);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        requestsCopy={requestsCopy}
        setRequests={setRequests}
      />

      {isModalOpen ? (
        <>{modalContent}</>
      ) : (
        <section className={s.parent}>
          {error ? (
            <Default active={2} />
          ) : loading ? (
            <Default
              active={1}
            />
          ) : (
            <>
              {requests.length === 0 ? (
                <Default active={2} />
              ) : (
                <div className={s.container}>
                  {requests.map((req) => (
                    <div key={req.request_id} className={s.card}>
                      {/* ************ main ************  */}
                      <div className={s.main}>
                        Instructor <b>{req.fname} {req.lname}</b>{" "}
                        Wishes to handle <b>{req.year} - {req.strand}</b>
                      </div>
                      {/* ************ buttons ************ */}
                      <div className={s.buttons}>
                        <button onClick={() => openModal(View, req)}>
                          <span className={s.button}>View info</span>
                        </button>
                        <button onClick={() => goAccept(req)}>
                          <span className={s.button}>{icons.checkFill}</span>
                        </button>
                        <button onClick={() => openModal(Prompt, req)}>
                          <span className={s.button}>{icons.deleteFill}</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

export default Requests;
