import React, { useState } from "react";
import s from "./contact.module.css";
import bg from "../../../assets/whity.jpg";
import contact1 from "../../../assets/contact1.png";
import people from "../../../assets/people.png";
import Footer from "../../../components/Footer/Footer";
import { postDatas } from "../../../services/api";

function Contact() {
  const [values, setValues] = useState({
    email: "",
    subject: "",
    content: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const sendEmail = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true); // Start loading when email is sent

    const res = await postDatas(`/mailer/developers`, values, setError);

    if (res && res.response) {
      setSuccessMessage("Email sent successfully!");
    } else {
      setErrorMessage("Failed to send email. Please try again.");
    }

    setLoading(false); // Stop loading after response
    setValues({
      email: "",
      subject: "",
      content: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  };

  return (
    <>
      <div className={s.contactHeader}>
        <h2>Contact Us</h2>
      </div>

      {/* Background Container */}
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>

      <section className={s.contactContainer}>
        <section className={s.contactInformations}>
          <img src={people} alt="note" className={s.contactTemplateImage}></img>
        </section>

        <form onSubmit={handleSubmit} className={s.contactForm}>
          <h4>Let's Talk</h4>
          <p>
            Reach us by sending emails for inquiries and assistance. We will get
            back to you as soon as possible, we hope you enjoy your time here.
          </p>
          
          {/* Success and Error Messages */}
          {successMessage && <p className={s.successMessage}>{successMessage}</p>}
          {errorMessage && <p className={s.errorMessage}>{errorMessage}</p>}

          <div className={s.formGroup}>
            <label htmlFor="email">Email</label>
            <select
              name="email"
              value={values.email}
              onChange={handleChange}
              required
              className={s.email}
            >
              <option value="">Select an email</option>
              <option value="cascallalaudzion19@gmail.com">
                Laud Zion Cascalla (Main Developer)
              </option>
              <option value="desilvamaxdaniel18@gmail.com">
                Max Daniel De Silva (UI Designer)
              </option>
              <option value="andeskyla117@gmail.com">
                Kyla Andes (Project Manager)
              </option>
            </select>
          </div>
          <div className={s.formGroup}>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className={s.formGroup}>
            <label htmlFor="content">Message</label>
            <textarea
              name="content"
              value={values.content}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className={s.submitButton} disabled={loading}>
            {loading ? "Sending..." : ` Send Email`}
          </button>
        </form>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
