import React, { useEffect, useState } from "react";
import s from "./css/layout.module.css";
import RoomList from "./components/RoomList";
import AddRoom from "./components/CreateRoom";
import { useNavigate } from "react-router-dom";
import socket from "./Utilities/socket";
import Footer from "../../../components/Footer/Footer";

function Main() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const createRoom = (host, room, room_password, room_state) => {
    setIsModalOpen(!isModalOpen);
    if (!room || !host) {
      return;
    }

    const datas = {
      room: room,
      user: host,
      pass: room_password,
      state: room_state,
    };
    const createRoom = socket.emit("hostRoom", datas);
    if (createRoom) {
      alert("Room Created Successfully");
      navigate(`/signify/hostRoom/${room}/${host}`);
    }
  };

  return (
    <>
      <div className={s.parent}>
        <RoomList createRoom={createRoom} socket={socket} />
      </div>

      {isModalOpen && <AddRoom createRoom={createRoom} />}
      <Footer></Footer>

    </>
  );
}

export default Main;
