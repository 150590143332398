import React, { useState } from "react";
import s from "../css/modals.module.css";
import { getDatas, putDatas, uploadToS3 } from "../../../../../services/api";
import Popup from "../../../components/Popup/Popup";
import { icons } from "../../../../../utilities/icons";

function Edit({ closeMail, datas, user, type }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [error, setError] = useState("");
  const [newFile, setNewFile] = useState(null);
  const [values, setValues] = useState({
    title: datas.title,
    content: datas.content,
    file: datas.file,
    expires_at: datas.expires_at,
    updated_by: datas.updated_by,
    announcement_id: datas.announcement_id,
  });

  const updateAnnouncement = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    const { title, content, expires_at, file } = values;
    if (!title || !content || !expires_at) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }
    if (newFile) {
      try {
        const { url } = await getDatas(
          `/getS3Access/announcements/files/${file.name}`,
          setError
        );
        if (url) {
          await uploadToS3(url, file);
          values.file = url.split("?")[0];
        }
      } catch (error) {
        console.log(error);
      }
    }

    const res = await putDatas(
      `/teacher-api/announcements/update`,
      values,
      setError
    );
    if (res && res.response) {
      setActive(1);
      setMessage(res.response);
    } else {
      setActive(3);
      setMessage(res.error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValues({ ...values, file });
      setNewFile(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={s.container}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        <span>{type} Update</span>
        <button onClick={closeMail}>Close</button>
      </div>

      <form className={s.form} onSubmit={updateAnnouncement}>
        <input
          type="text"
          name="to"
          placeholder="to "
          className={s.input}
          value={`For: ${datas.year} - ${datas.strand}  ${
            type === "Class"
              ? " ( " + (datas.class_title + " - " + datas.class_section) + " )"
              : ""
          }`}
          required
          readOnly
        />
        <input
          type="text"
          name="title"
          placeholder="Subject "
          className={s.input}
          value={values.title}
          onChange={handleChange}
          required
        />
        <textarea
          name="content"
          placeholder="Message"
          className={s.textarea}
          value={values.content}
          onChange={handleChange}
          required
        />
        <span className={s.lastSection}>
          <div className={s.fileInputWrapper}>
            <input
              type="file"
              name="file"
              className={s.hiddenInput}
              onChange={handleFileChange}
              id="fileInput"
            />
            <label htmlFor="fileInput" className={s.clipButton}>
              {icons.clipFill}
            </label>
          </div>
          <input
            type="datetime-local"
            name="expires_at"
            className={s.expires}
            onChange={handleChange}
            id="dateInput"
          />

          <div className={s.buttons}>
            <button type="submit" className={s.submitButton}>
              Update {icons.sendFill}
            </button>
          </div>
        </span>
      </form>
    </div>
  );
}

export default Edit;
