import React, { useState, useEffect, createContext } from "react";
import { fetchData } from "../utilities/methods";

export const UserDataContext = createContext();
  
function UserDataProvider({ children }) {
  const [userData, setUserData] = useState(null); 
  const [datas, setDatas] = useState([]);
  const [error, setError] = useState(null);
  const [fetchDatas, setFetchDatas] = useState(() => () => {}); 

  const fetchDataFromApi = async (user_id, strand_id, teacher_id) => {
    setError(null);
    const url = `/student-api/learn/allaboutlearning/${user_id}/${teacher_id}/${strand_id}`;
    const data = await fetchData(url,setError);
    if (data) {
      setDatas(data);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setFetchDatas(() => (user_id, strand_id, teacher_id) => 
        fetchDataFromApi(user_id, teacher_id,strand_id,)
      );
    }
  }, [userData]);

  return (
    <UserDataContext.Provider value={{ userData, setUserData, fetchDatas, datas, setDatas }}>
      {children}
    </UserDataContext.Provider>
  );
}

export default UserDataProvider;
