// A.js
export function setLetterK(fp) {
  const letterK = new fp.GestureDescription("K");

  // Gesture K: Thumb up, other fingers curled
  letterK.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  letterK.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterK.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  letterK.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 0.8);
  letterK.addCurl(fp.Finger.Ring, fp.FingerCurl.HalfCurl, 0.4); 
  // letterK.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);
  // letterK.addCurl(fp.Finger.Pinky, fp.FingerCurl.HalfCurl, 0.4);

  letterK.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalRight, 0.2);
  letterK.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpRight, 0.8);
  // letterK.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpLeft, 0.3);
  // letterK.addDirection(fp.Finger.Index, fp.FingerDirection.HorizontalLeft, 1.0);
  letterK.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpRight, 1.0);
  
  letterK.addDirection(fp.Finger.Middle, fp.FingerDirection.HorizontalRight, 0.4 );

  // letterK.addDirection(fp.Finger.Ring, fp.FingerDirection.HorizontalLeft, 0.5);
  // letterK.addDirection(fp.Finger.Pinky, fp.FingerDirection.HorizontalLeft, 0.5);

  return letterK;
}
