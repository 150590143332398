import React, {  useState, useRef } from "react";
import { v4 } from "uuid";
// styles
import styles from "../css/create.module.css";
import s from "../../../../../components/css/buttons.module.css";
import sample from "../../../../../images/bg.jpg";
//contexts
//methods

import { previewFile,postData } from "../../../../../../../utilities/methods";
// firebase
import { storage } from "../../../../../../../configs/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// components
import Popup from "../../../../../components/Popup/Popup";

function CreateTeacher({ closeModal }) {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [profile, setProfile] = useState(null);
  const previewImageRef = useRef();
  const [values, setValues] = useState({
    image: "",
    description: "",
    fname: "",
    mname: "",
    lname: "",
    contact: "",
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setActive(null);

    let imageUrl
    const imageRef = ref(storage, `profile/${profile.name + v4()}`);

    try {
      await uploadBytes(imageRef, profile);
      imageUrl = await getDownloadURL(imageRef);
    } catch (error) {
      setActive(3);
      setMessage("Failed to upload profile image please try uploading again later");
      console.log(error);
    }

    if (imageUrl) {
      const updatedValues = { ...values, image: imageUrl };
      const url = "/admin-api/post/teacher";
      const response = await postData(url, updatedValues, setError);

      if (response) {
        setActive(1);
        setMessage("Teacher Account Created Successfully");
      } else {
        setActive(3);
        setMessage("Failed to Create Account");
      }
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // previewing image
  const previewImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setProfile(image);
      previewFile(image, previewImageRef);
    }
  };

  return (
    <>
      <Popup message={message} active={active} />

      <div className={`${styles.container}`}>
        <div className={styles.loginCard}>
          {/* form on left */}
          <div className={styles.formSection}>
            <div className={styles.title}>
              CREATE TEACHER ACCOUNT
              <button className={s.modalButton} onClick={closeModal}>
                Dashboard
              </button>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputGroup}>
                <div className={styles.profile}>
                  <input
                    className={styles.input}
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={previewImage}
                    required
                  />
                  <img
                    src=""
                    alt="profile"
                    className={styles.image}
                    ref={previewImageRef}
                  />
                </div>

                {/* description */}
                <input
                  className={styles.input}
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  required
                />

                {/* full name */}
                <div className={styles.name}>
                  {/* fname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="fname"
                    placeholder="First Name"
                    value={values.fname}
                    onChange={handleChange}
                    required
                  />
                  {/* mname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="mname"
                    placeholder="Middle Name"
                    value={values.mname}
                    onChange={handleChange}
                  />
                  {/* lname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="lname"
                    placeholder="last Name"
                    value={values.lname}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* contact */}
                <input
                  className={styles.input}
                  type="text"
                  name="contact"
                  placeholder="Contact"
                  value={values.contact}
                  onChange={handleChange}
                />

                <div className={styles.account}>
                  Account Details:
                  {/* email */}
                  <input
                    className={styles.input}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {/* password */}
                  <input
                    className={styles.input}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <button type="submit" className={styles.button}>
                Register Account
              </button>
            </form>
          </div>
          {/* image on right */}
          <div className={styles.illustrationSection}>
            <div className={styles.illustration}>
              <img
                src={sample}
                alt="Illustration"
                className={styles.image}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreateTeacher;
