import React, { useState, useEffect } from "react";
import Enrollment from "./enrollment/Enrollment";
import Default from "../../../defaults/Default/Default";
import { getDatas } from "../../../../services/api";
import { Navigate, useNavigate } from "react-router-dom";

function Main() {
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null); 

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user); 
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  useEffect(() => {
    if (user && user.enrollment_status) {
      const status = user.enrollment_status;
      const loweredStatus = status.toLowerCase();
      setUserStatus(loweredStatus);
    }
  }, [user]); 

  useEffect(() => {
    if (userStatus === "pending" || userStatus === null) {
      window.open('/student/pending','_parent')
    }
  }, [userStatus]);

  if (!user) { 
    return <Default text="Loading, Please Wait..." active={1} />;
  }

  return (
    <>
      {userStatus === "approved" ? (
       <Navigate to = "/student/dashboard"/>
      ) : (
        <Enrollment />
      )}
    </>
  );
}

export default Main;
