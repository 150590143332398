// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwCYZaveVFkFacoN1Q0o_ZqCxxmJa_4WY",
  authDomain: "capstone-1cc5f.firebaseapp.com",
  projectId: "capstone-1cc5f",
  storageBucket: "capstone-1cc5f.appspot.com",
  messagingSenderId: "956233151888", 
  appId: "1:956233151888:web:6483fb455bbac213632f15",
  measurementId: "G-8ZN2GVX5WW",
};   

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app); 
// const analytics = getAnalytics(app);
