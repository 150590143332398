import React, { createContext, useState, useEffect } from 'react';

export const RoleContext = createContext();

const RoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const storedRole = localStorage.getItem('role');
    const storedStatus = localStorage.getItem('isLoggedIn') === 'true';
    setStatus(storedStatus);
    setUserRole(storedRole)
    setLoading(false); 
  }, []); 

  return (
    <RoleContext.Provider value={{ userRole, setUserRole, status, setStatus, loading }}>
       {children}
    </RoleContext.Provider>
  );
};

export default RoleProvider;