import React from 'react'
import s from './mail.module.css'

function Mail({children}) {
  return (
    <div className = {s.mailParent}>
    <div className = {s.mailContainer}>{children}</div>
    </div>
  )
}     
   
export default Mail