import React, { useState } from "react";
// style
import forms from "../../../../../components/css/forms.module.css";
// context
import { icons } from "../../../../../../../utilities/icons";
import {
  getDatas,
  postDatas,
  uploadToS3,
} from "../../../../../../../services/api";

function Add() {
  const [error, setError] = useState(null);
  const [isNotifVisible, setIsNotifVisible] = useState(false);
  const [notification, setNotification] = useState("");
  const [values, setValues] = useState({
    image: "",
    strand: "",
    year: "",
    title: "",
    description: "",
    color: "",
    created_by: "admin",
    updated_by: "admin",
  });

  // ***************************************************
  // create strand
  // ***************************************************
  const handleSubmit = async (e) => {
    setIsNotifVisible(false);
    let { image, title, year, strand, color } = values;
    e.preventDefault();
    setError(null);

    if (!title || !year || !strand) {
      return;
    }

    if (!color) {
      color = "#242629";
    }

    const { url } = await getDatas(
      `/getS3Access/strands/admin/${image.name}`,
      setError
    );
    try {
      if (url) {
        await uploadToS3(url, image);
        const imageUrl = url.split("?")[0];

        if (imageUrl) {
          values.image = imageUrl;
          console.log(values);
          const res = await postDatas(
            `/admin-api/post/strand`,
            values,
            setError
          );

          if (res && res.response) {
            setIsNotifVisible(true);
            setNotification(res.response);
          } else {
            setIsNotifVisible(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ***************************************************
  // handle image change
  // ***************************************************
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValues({ ...values, image: file });
    }
  };

  // ***************************************************
  // handle input change
  // ***************************************************
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <div className={forms.parent}>
        <form className={forms.form} onSubmit={handleSubmit}>
          {/* title and description */}
          <input
            type="text"
            name="title"
            placeholder="Title"
            className={forms.input}
            onChange={handleChange}
            required
          ></input>
          <textarea
            type="text"
            name="description"
            placeholder="Description..."
            className={forms.textarea}
            onChange={handleChange}
          ></textarea>

          {/* strand and year */}
          <div className={forms.biInputGroup}>
            <select
              name="strand"
              className={forms.select}
              onChange={handleChange}
              value={values.strand}
              required
            >
              <option defaultChecked>Select Strand</option>
              <option value="ABM">ABM</option>
              <option value="HUMSS">HUMSS</option>
              <option value="ICT">ICT</option>
              <option value="STEM">STEM</option>
              <option value="GAS">GAS</option>
            </select>
            <select
              name="year"
              className={forms.select}
              onChange={handleChange}
              value={values.year}
              required
            >
              <option defaultChecked>Select Year</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
          {/* images and color */}
          <div className={forms.biInputGroup}>
            <span className={forms.cLabel}>
              Select Template:
              <input
                type="file"
                name="image"
                accept="image/*"
                className={forms.file}
                onChange={handleImageChange}
                required
              ></input>
            </span>
          </div>
          <select
            name="color"
            className={forms.select}
            onChange={handleChange}
            required
          >
            <option defaultChecked>Select Color</option>
            <option value="red">Red</option>
            <option value="yellow">Yellow</option>
            <option value="green">Green</option>
          </select>
          {isNotifVisible && (
            <p className={forms.success}>
              {icons.checkLine}
              {notification}
            </p>
          )}
          <div className={forms.buttons}>
            <button type="submit" className={forms.submitButton}>
              Create Strand
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Add;
