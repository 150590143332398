import React from 'react'
import Card from './parts/Card'
import s from './css/home.module.css'

function Home() {
  return (
    <div className = {s.homeContainer}>Coming Soon...</div>
  )
}

export default Home