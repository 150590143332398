import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Center from "../../Student/components/Center_layout/Center";
import { icons } from "../../../utilities/icons";
import s from "./pending.module.css";
import { getDatas, postDatas, putDatas } from "../../../services/api";
import logo from "../../../assets/logo.jpg";
function Pending() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const cancelEnroll = async () => {
    const url = `/student-api/enrollments/cancel`;

    const values = {
      student_id: user.student_id,
    };

    const datas = await putDatas(url, values, setError);
    if (datas && datas.response) {
      alert(datas.response);

      const url = "/student-api/authentication/logout";
      const res = await postDatas(url, {}, setError);
      if (res) {
        navigate("/student/login");
      }
    }
  };

  if (!user) {
    console.log("redirecting")

    return <Navigate to="/unauthorized" />;
  }

  return (
    <>
      <div className={s.header}>
        <span>
          <img src={logo} alt="logo" className={s.logo}></img>
          <h2 className={s.title}>Trackademy</h2>
        </span>
        <button
          type="button"
          onClick={() => navigate("/")}
          className={s.backButton}
        >
          &larr; Back
        </button>
      </div>

      <Center>
        <div className={s.pendingContainer}>
          <div className={s.icon}>{icons.warnFill}</div>
          <span>
            Your <span className={s.active}>Enrollment Status</span> is{" "}
            <span className={s.active}>Pending</span>
            <br />
            Please <span className={s.active}>Notify</span> your instructor
          </span>
          <div className={s.cancel}>
            <button type="button" onClick={cancelEnroll} className={s.button}>
              Cancel Enrollment
            </button>
          </div>
        </div>
      </Center>
      <div className={s.footer}></div>
    </>
  );
}

export default Pending;
