export function setLetterZ(fp) {
  const letterZ = new fp.GestureDescription("Z");

  letterZ.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterZ.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 0.4);
  letterZ.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterZ.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterZ.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterZ.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);


  letterZ.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.5);
  letterZ.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 0.5);
  letterZ.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 0.5);

  return letterZ;
}
