import React, { useEffect, useState } from "react";
import s from "./css/teachers.module.css";
import { fetchData } from "../../../../../../utilities/methods";
import View from "./modal/View";
import Default from "../../../../../defaults/Default/Default";
import Top from "./Top";
import UpdateTeacher from "./modal/UpdateTeacher";
import Prompt from "../../../../components/Prompt/Prompt";
import { icons } from "../../../../../../utilities/icons";
import Popup from "../../../../components/Popup/Popup";
import { deleteDatas } from "../../../../../../services/api";
import Mail from "../../../../components/Mail_layout/Mail";

function Teachers() {
  const [teachers, setTeachers] = useState([]);
  const [teachersCopy, setTeachersCopy] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // ***************************************************
  // delete teacher
  // ***************************************************
  const goConfirm = async (confirmation, id, data, imageUrl) => {
    setError(null);
    setActive(null);

    if (confirmation) {
      if (imageUrl) {
        const imageFilename = imageUrl.split("/").pop();
        const folder = "profiles";
        const role = "teacher";

        // Call the API to delete the image from S3
        const deleteImageUrl = `/deleteS3Asset/${folder}/${role}/${imageFilename}`;
        const deleteImageRes = await deleteDatas(
          deleteImageUrl,
          null,
          setError
        );

        if (!deleteImageRes) {
          setTimeout(() => {
            setActive(3);
            setMessage("Deleting Image Failed");
            throw new Error("Failed to delete image from S3");
          }, 0);
        }
      }
      const url = `/admin-api/delete/account`;
      const datas = { role: "Teacher", id };
      const res = await deleteDatas(url, datas, setError);

      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        getTeachers((prev) => prev.filter((tr) => tr.teacher_id !== id));
      } else {
        setActive(3);
        setMessage("Deleting Teacher Account Failed");
      }
    }
    setIsModalOpen(false);
  };

  const openModal = (Component, tr) => {
    setModalContent(
      <Component
        datas={tr}
        closeTeacherInfo={closeTeacherInfo}
        closeModal={() => setIsModalOpen(false)}
        getTeachers={getTeachers}
        onConfirm={goConfirm}
        id={tr.teacher_id}
        imageUrl={tr.image}
        setTeachers={setTeachers}
      />
    );
    setIsModalOpen(true);
  };

  const closeTeacherInfo = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const getTeachers = async () => {
    setError(null);
    const url = `/admin-api/get/teachers`;
    const datas = await fetchData(url, setError);
    if (datas) {
      setTeachers(datas);
      setTeachersCopy(datas);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeachers();
  }, []);

  return (
    <>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        teachersCopy={teachersCopy}
        setTeachers={setTeachers}
      />

      {isModalOpen ? (
        <Mail>{modalContent}</Mail>
      ) : (
        <section className={s.parent}>
          {error ? (
            <Default active={2} />
          ) : loading ? (
            <Default active={1} />
          ) : (
            <>
              {teachers.length === 0 ? (
                <Default active={2} />
              ) : (
                <div className={s.container}>
                  {teachers.map((tr) => (
                    <div key={tr.teacher_id} className={s.card}>
                      {tr.fname} {tr.lname}
                      <div className={s.buttons}>
                        <button onClick={() => openModal(View, tr)}>
                          info
                        </button>
                        <button onClick={() => openModal(UpdateTeacher, tr)}>
                          {icons.updateFill}
                        </button>
                        <button onClick={() => openModal(Prompt, tr)}>
                          {icons.deleteFill}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

export default Teachers;
