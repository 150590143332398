import React, { useEffect, useState } from "react";
import s from "../css/main.module.css";
import Popup from "../../../components/Popup/Popup";
import Modal from "../../../components/Modal_layout/Modal";
import Mail from "../../../components/Mail_layout/Mail";
import Top from "./Top";
import { getDatas } from "../../../../../services/api";
import { icons } from "../../../../../utilities/icons";
import Default from "../../../../defaults/Default/Default";

function Main({user}) {
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mailContent, setMailContent] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [error, setError] = useState("");
  const [active, setActive] = useState("");
  const [message, setMessage] = useState("");
  const [classworks, setClassworks] = useState([]);
  const [originalClassworks, setOriginalClassworks] = useState([]);
  const [stats, setStats] = useState([]);

  const fetchStudentScores = async () => {
    setLoading(true);
    try {
      const res = await getDatas(
        `/student-api/scoreboard/classworks/${user.strand_id}/${user.student_id}`,
        setError
      );
      if (res && res.response) {
        setClassworks(res.response);
        setOriginalClassworks(res.response);
      }
      setLoading(false);

    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    if (user && user.strand_id && user.student_id) {
      fetchStudentScores();
    }
  }, [user]);

  const openMail = (Component, datas, e) => {
    e.stopPropagation();
    setModalContent(null);
    setMailContent(
      <Component
        user={user}
        datas={datas}
        stats={stats}
        closeMail={closeMail}
      />
    );
    setIsMailOpen(true);
  };

  const closeMail = () => {
    setIsMailOpen(false);
    setModalContent(null);
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <Top
        user={user}
        openMail={openMail}
        setClassworks={setClassworks}
        originalClassworks={originalClassworks}
        setStats={setStats}
      />

      <Modal>
        {loading ? (
          <Default active={1} />
        ) : classworks.length === 0 ? (
          <Default active={2} />
        ) :(
          <div className={s.cardWrapper}>
            {classworks.map((classwork, i) => (
              <span
                className={s.card}
                key={i}
                style={{ backgroundColor: classwork.color }}
              >
                <span className={s.startBanner}>
                  <span className={s.score} style={{ color: classwork.color }}>
                    {classwork.score} / {classwork.max_score}{" "}
                  </span>
                  <span className={s.kebab}>{icons.rankFill}</span>
                </span>
                <span className={s.title}>{classwork.title}</span>
{/* 
                {activeMenu === classwork.classwork_id && (
                  <span className={s.cardMenu}>
                    <button>{icons.rankFill}</button>
                    <button>{icons.deleteFill}</button>
                    <button>{icons.updateFill}</button>
                  </span>
                )} */}
              </span>
            ))}
          </div>
        )}
      </Modal>

      {isMailOpen && (
        <Mail title="Modal Title" isClose={closeMail} isOpen={isMailOpen}>
          {mailContent}
        </Mail>
      )}
    </div>
  );
}

export default Main;
