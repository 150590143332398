import letterImages from "../datas/letter-images";
import {
  wordImages,
  phraseImages,
} from "../datas/word-images";
import { wordExists, phraseExists } from "./methods";
import space from "../../assets/images/space.png";
import sound from '../../assets/sounds/bubble.mp3';
let TimeoutId = [];
const audio = new Audio(sound);

const startTranslation = (userInput, speed, callback) => {
  const container = document.querySelector(".translate-hand-container");
  const inputWords = userInput.toLowerCase().match(/\b\w+\b|\s/g); // Match words and spaces

  if (!inputWords) {
    return;
  }

  // Stop previous translation
  TimeoutId.forEach((timeout) => {
    clearTimeout(timeout);
  });

  container.innerHTML = ""; // Clear container
  const animationDuration =
    speed === "fast" ? 500 : speed === "slow" ? 1500 : 1000; // Animation duration in milliseconds
  const transitionDelay = 300; // Additional delay for transition
  const totalDelay = animationDuration + transitionDelay;

  let delayCounter = 0;
  let phraseContainer = [];
  let phrase = "";
  let animationStopper = true;

   // Ensure userInput is a string
   if (typeof userInput !== 'string') {
    console.error('userInput must be a string');
    return;
  }


  // Complete the phrase first then clean it for conditions
  inputWords.forEach((w) => {
    phraseContainer.push(w);
    phrase = phraseContainer.join("");
  });

  inputWords.forEach((wrd, index) => {
    // console.log(wrd)
    if (wrd.trim() === "") {
      // Pause for spaces
      const timeoutSpace = setTimeout(() => {
        if (index === inputWords.length - 1) {
          if (callback) callback();
        }
      }, delayCounter);
      //add timeout to array for deletion
      TimeoutId.push(timeoutSpace);
      // Increment delay counter for space
      delayCounter += totalDelay;
    } else {
      //check if the combined words that became phrase exist in the array of phrases
      if (!phraseExists(phrase)) {
        if (wordExists(wrd)) {
          const timeoutWordFound = setTimeout(() => {
            findLetterGesture(container, wrd, 1);
          }, delayCounter);
          //add timeout to array for deletion
          TimeoutId.push(timeoutWordFound);
          delayCounter += totalDelay * wrd.length;
        } else {
          wrd.split("").forEach((letter) => {
            let ucLetter = letter.toUpperCase();
            const timeoutLetterNotFound = setTimeout(() => {
              findLetterGesture(container, ucLetter, 2);
            }, delayCounter);
            //add timeout to array for deletion
            TimeoutId.push(timeoutLetterNotFound);
            delayCounter += totalDelay;
          });
        }
      } else {
        const timeoutPhraseFound = setTimeout(() => {
          if (animationStopper) {
            findLetterGesture(container, phrase, 3);
            animationStopper = false;
          }
        }, delayCounter);
        //add timeout to array for deletion
        TimeoutId.push(timeoutPhraseFound);
        delayCounter += totalDelay * (phrase.length / 3);
      }
    }
  });
};

const findLetterGesture = (container, letter, flag) => {

  // check if the container is passed and ready
  if (!container) return;
  let imageSrc;
  switch (flag) {
    case 1:
      imageSrc = wordImages[letter] || space;
      break;
    case 2:
      imageSrc = letterImages[letter] || space;
      break;
    default:
      imageSrc = phraseImages[letter] || space;
      break;
  }

  //create image element
  const createImage = document.createElement("img");
  createImage.src = imageSrc;
  createImage.classList.add("translate-hand-image");

  //set delays for adding and removing animations
  const addTimeoutId = setTimeout(() => {
    createImage.classList.add("hand-animation");
    container.innerHTML = "";
    container.appendChild(createImage);

  }, 500);

  //add interval to be deleted later
  TimeoutId.push(addTimeoutId);
  const removeTimeoutId = setTimeout(() => {
    createImage.classList.remove("hand-animation");
  audio.play();
  }, 1000);

  //add interval to be deleted later
  TimeoutId.push(removeTimeoutId);
};

export { startTranslation, findLetterGesture };
