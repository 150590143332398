import React, { useState } from 'react';
import s from '../css/modal.module.css';

function ViewContent(props) {
    const { datas, setIsMailOpen } = props;
    const [viewMode, setViewMode] = useState('video'); // Default to 'video'

    return (
        <div className={`${s.contentContainer} ${s.fadeIn}`}>
            <div className={s.contentHeader}>
                <div className={s.title}>{datas?.title}</div>
                <button className={s.closeButton} onClick={() => setIsMailOpen(false)}>Close</button>
            </div>

            <div className={s.toggler}>
                <button 
                    className={`${s.toggleButton} ${viewMode === 'video' ? s.active : ''}`} 
                    onClick={() => setViewMode('video')}
                >
                    Video
                </button>
                <button 
                    className={`${s.toggleButton} ${viewMode === 'image' ? s.active : ''}`} 
                    onClick={() => setViewMode('image')}
                >
                    Image
                </button>
            </div>

            <div className={s.main}>
                {viewMode === 'video' && datas?.video && (
                    <video className={s.media} src={datas.video} controls />
                )}
                {viewMode === 'image' && datas?.image && (
                    <img className={`${s.media}`} src={datas.image} alt={datas.title} />
                )}
                {datas?.description && <p className={s.description}>{datas.description}</p>}
            </div>
        </div>
    );
}

export default ViewContent;
