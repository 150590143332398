import React, { useContext, useEffect, useState } from "react";
// styles
import style from "../css/sidebar.module.css";
// contexts
import { GlobalStyleContext } from "../../../../../components/other/styles/globalcss";
// data
import { categories } from "../datas/sidebar-menu";
import Item from "./Item";
// icons
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Sidebar({ filterTools, handleSearch }) {
  const [sidebarInfos, setSidebarInfos] = useState([]);
  const { globalStyles } = useContext(GlobalStyleContext);

  const loadSidebarInfos = () => {
    setSidebarInfos(categories);
  };

  useEffect(() => {
    loadSidebarInfos();
  }, []);

  return (
    <aside className={style.sidebarParent} style={{ background: globalStyles.bg2 }}>
      <h4 className={style.sidebarTitle} style={{ color: globalStyles.primary }}>
        <div className={style.searchContainer} style={{ backgroundColor: globalStyles.rtertiary }}>
          <Link to={'/student/dashboard'}>Dashboard</Link>
          <input
            type="search"
            className={style.search}
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}  // Call handleSearch on input change
          />
          <label className={style.searchIcon}>
            <FaSearch />
          </label>
        </div>
      </h4>
      <ul className={style.sidebarContainer}>
        {sidebarInfos.map((info) => (
          <Item key={info.id} info={info} filterTools={() => filterTools(info.id)} />
        ))}
      </ul>
    </aside>
  );
}

export default Sidebar;
