import React, { useContext, useEffect, useRef, useState } from "react";
// styles
import forms from "../../../../../components/css/forms.module.css";
// methods
import {
  handleUpload,
  previewFile,
  updateData,
} from "../../../../../../../utilities/methods";
// contexts
import { UserDataContext } from "../../../../../../../contexts/UserDataProvider";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../../../../../../configs/firebase";
// components
import Popup from "../../../../../components/Popup/Popup";

function Edit({ closeModal, datas  }) {
  const { userData } = useContext(UserDataContext);
  const previewImageRef = useRef(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [image, setImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [values, setValues] = useState({
    image: datas.image,
    strand: datas.strand || "",
    year: datas.year || "",
    title: datas.title,
    description: datas.description,
    color: datas.color || "",
    updated_by: "admin",
    strand_id: datas.strand_id,
  });

  // ***************************************************
  // handle Strand Editing
  // ***************************************************
  const handleSubmit = async (e) => {
    const { title, description } = values;
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    if (!title || !description) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }

    try {
      if (image) {
        if (prevImage) {
          const prevImageRef = ref(storage, prevImage);
          await deleteObject(prevImageRef);
        }
        values.image = await handleUpload(image, "strand/images");
      }
      
      if (userData) {
        const url = `/admin-api/put/strand`;
        const newStrand = {
          ...values,
          updated_by: `admin`,
        };

        const response = await updateData(url, newStrand, setError);

        if (response) {
          setActive(1);
          setMessage("Updating Strand Successful!");
        } else {
          setActive(3);
          setMessage("Updating Strand Failed!");
        }
      }
    } catch (error) {
      setActive(3);
      setMessage("Updating Strand Failed!");
      console.log(error);
    }
  };
  // ***************************************************
  // handle image change
  // ***************************************************
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      previewFile(file, previewImageRef);
    }
  };
  // ***************************************************
  // handle input Change 
  // ***************************************************
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    setPrevImage(values.image);
  }, [values]);

  return (
    <>
      <Popup message={message} active={active} />

      <div className={forms.buttons}>
        <button className={forms.button} onClick={closeModal}>
          Back
        </button>
      </div>

      <div className={forms.header}>EDIT STRAND</div>
      <div className={forms.parent}>
        <form className={forms.form} onSubmit={handleSubmit}>
          {/* title and description */}
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={values.title}
            onChange={handleChange}
            className={forms.input}
          ></input>
          <textarea
            type="text"
            name="description"
            placeholder="Description..."
            className={forms.textarea}
            value={values.description}
            onChange={handleChange}
          ></textarea>

          {/* strand and year */}
          <div className={forms.biInputGroup}>
            <select
              name="strand"
              className={forms.select}
              value={values.strand}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Strand
              </option>
              <option value="ABM">ABM</option>
              <option value="HUMSS">HUMSS</option>
              <option value="ICT">ICT</option>
              <option value="STEM">STEM</option>
              <option value="GAS">GAS</option>
            </select>
            <select
              name="year"
              className={forms.select}
              value={values.year}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Year
              </option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>

          {/* images and color */}
          <div className={forms.biInputGroup}>
            <span className={forms.cLabel}>
              Select Template:
              <input
                type="file"
                name="image"
                className={forms.file}
                onChange={handleImageChange}
              ></input>
            </span>
          </div>
          <select
            name="color"
            className={forms.select}
            value={values.color}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Color
            </option>
            <option value="red">Red</option>
            <option value="yellow">Yellow</option>
            <option value="green">Green</option>
          </select>
          <div className={forms.buttons}>
            <button type="submit" className={forms.submitButton}>
              Update
            </button>
          </div>
        </form>
      </div>

      <div className={forms.header}>Preview Panel</div>
      <div className={forms.previews}>
        <span className={forms.cLabel}>
          Selected File:
          <img
            ref={previewImageRef}
            src={values.image}
            className={forms.imgPreview}
            alt="preview"
            loading="lazy"
          />
        </span>
      </div>
    </>
  );
}

export default Edit;
