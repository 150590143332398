import React, { useEffect, useState } from "react";
// style
import s from "../../components/style_configs/top_side_main.module.css";
import style from "./css/announcements.module.css";
// components
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Center from "../../components/Center_layout/Center";
import Main from "./components/Main";
import Default from "../../../defaults/Default/Default";
//methods
import { getDatas } from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [copyAnnouncements, setCopyAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const fetchAnnouncements = async (teacher_id) => {
    setError(null);
    setLoading(true);
    const url = `/teacher-api/announcements/${teacher_id}`;

    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setAnnouncements(res.response);
        setCopyAnnouncements(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && user.teacher_id) {
      fetchAnnouncements(user.teacher_id);
    }
  }, [user]);

  return (
    <>
      {user ? (
        <div className={style.AnnouncementsContainer}>
          <header className={s.header}>
            <Header title="ARCHIVE" user={user} />
          </header>
          <aside className={s.sidebar}>
            <Sidebar />
          </aside>
          <section className={s.main}>
            <Center>
              {error ? (
                <Default active={2} />
              ) : loading ? (
                <Default active={1} />
              ) : (
                <Main
                  user={user}
                  copyAnnouncements={copyAnnouncements}
                  announcements={announcements}
                  setAnnouncements={setAnnouncements}
                  fetchAnnouncements={fetchAnnouncements}
                />
              )}
            </Center>
          </section>
        </div>
      ) : (
        <Default active={1} />
      )}
    </>
  );
};

export default Announcements;
