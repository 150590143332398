//notes ct2,icon2 are dark
// notes ct1,icon1 are light
export const colors = {
  amber: {
    bg: "#FADA7A",
    link:'#eaeaea',
    logo: "#FADA7A",
    t1: "#FFFFFF",
    secondary: "#FADA7A",
    tertiary: "#FFBF00",
    highlight: "#FDCD3D",
    button: "#FADA7A",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#FDCD3D",
    ct1: "",
    t2: "#FADA7A",
    bg2: "#242629",
    rhighlight: "#FDCD3D",
    stroke: "#FFBF00",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FFBF00",
    modalHighlight: "#FADA7A",
    modalHighlightText: "#383232",
  },
  beige: {
    bg: "#F5F5DC",
    link:'#eaeaea',
    logo: "#E3D3AF",
    t1: "#FFFFFF",
    secondary: "#F1EDD1",
    tertiary: "#F5F5DC",
    highlight: "#ECE4C6",
    button: "#E3D3AF",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#E3D3AF",
    ct1: "",
    t2: "#E3D3AF",
    bg2: "#242629",
    rhighlight: "#E3D3AF",
    stroke: "#F5F5DC",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#F5F5DC",
    modalHighlight: "#E3D3AF",
    modalHighlightText: "#383232",
  },
  black: {
    bg: "#000000",
    link:'#eaeaea',
    logo: "#FFFFFF",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#000000",
    highlight: "#1C1919",
    button: "#383232",
    buttonText: "#eaeaea",
    input: "#FFFFFF",
    inputText: "#000000",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#383232",
    ct1: "",
    t2: "#383232",
    bg2: "#242629",
    rhighlight: "#383232",
    stroke: "#000000",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#000000",
    modalHighlight: "#383232",
    modalHighlightText: "#FFFFFF",
  },
  blue: {
    bg: "#0000FF",
    link:'#eaeaea',
    logo: "#1F1F80",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#0000FF",
    highlight: "#1010C0",
    button: "#1F1F80",
    buttonText: "#eaeaea",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#1F1F80",
    ct1: "",
    t2: "#1F1F80",
    bg2: "#242629",
    rhighlight: "#1F1F80",
    stroke: "#0000FF",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#0000FF",
    modalHighlight: "#1F1F80",
    modalHighlightText: "#383232",
  },
  bronze: {
    bg: "#CD7F32",
    link:'#eaeaea',
    logo: "#A6611C",
    t1: "#FFFFFF",
    secondary: "#C4782D",
    tertiary: "#CD7F32",
    highlight: "#BA7027",
    button: "#A6611C",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#A6611C",
    ct1: "",
    t2: "#A6611C",
    bg2: "#242629",
    rhighlight: "#A6611C",
    stroke: "#CD7F32",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#CD7F32",
    modalHighlight: "#A6611C",
    modalHighlightText: "#383232",
  },
  brown: {
    bg: "#633200",
    link:'#eaeaea',
    logo: "#633200",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#633200",
    highlight: "#492A0B",
    button: "#2E2115",
    buttonText: "#eaeaea",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#2E2115",
    ct1: "",
    t2: "#2E2115",
    bg2: "#242629",
    rhighlight: "#2E2115",
    stroke: "#633200",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#633200",
    modalHighlight: "#2E2115",
    modalHighlightText: "#FFFFFF",
  },
  burgundy: {
    bg: "#6E0A1E",
    link:'#eaeaea',
    logo: "#C91035",
    t1: "#FFFFFF",
    secondary: "#C91035",
    tertiary: "#6E0A1E",
    highlight: "#9C0D2A",
    button: "#6E0A1E", //#C91035
    buttonText: "#eaeaea",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#6E0A1E",
    ct1: "",
    t2: "#C91035",
    bg2: "#242629",
    rhighlight: "#6E0A1E",
    stroke: "#6E0A1E",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#6E0A1E",
    modalHighlight: "#C91035",
    modalHighlightText: "#FFFFFF",
  },
  burntSienna: {
    bg: "#E97451",
    link:'#eaeaea',
    logo: "#963112",
    t1: "#FFFFFF",
    secondary: "#D56442",
    tertiary: "#E97451",
    highlight: "#C05332",
    button: "#963112",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#963112",
    ct1: "",
    t2: "#963112",
    bg2: "#242629",
    rhighlight: "#963112",
    stroke: "#E97451",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#E97451",
    modalHighlight: "#963112",
    modalHighlightText: "#FFFFFF",
  },
  champagne: {
    bg: "#F7E7CE",
    link:'#eaeaea',
    logo: "#F0C786",
    t1: "#FFFFFF",
    secondary: "#F6DFBC",
    tertiary: "#F7E7CE",
    highlight: "#F4D7AA",
    button: "#F0C786",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#F0C786",
    ct1: "",
    t2: "#F0C786",
    bg2: "#242629",
    rhighlight: "#F0C786",
    stroke: "#F7E7CE",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#F7E7CE",
    modalHighlight: "#F0C786",
    modalHighlightText: "#383232",
  },
  chartreuse: {
    bg: "#DFFF00",
    link:'#eaeaea',
    logo: "#80FF00",
    t1: "#FFFFFF",
    secondary: "#C8FF00",
    tertiary: "#DFFF00",
    highlight: "#B0FF00",
    button: "#80FF00",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#80FF00",
    ct1: "",
    t2: "#80FF00",
    bg2: "#242629",
    rhighlight: "#80FF00",
    stroke: "#DFFF00",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#DFFF00",
    modalHighlight: "#80FF00",
    modalHighlightText: "#383232",
  },
  cobaltBlue: {
    bg: "#0047AB",
    link:'#eaeaea',
    logo: "#eaeaea",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#0047AB",
    highlight: "#06377D",
    button: "#0B274F",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#0B274F",
    ct1: "",
    t2: "#0B274F",
    bg2: "#242629",
    rhighlight: "#0B274F",
    stroke: "#0047AB",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#0047AB",
    modalHighlight: "#0B274F",
    modalHighlightText: "#FFFFFF",
  },
  cognac: {
    bg: "#9A463D",
    link:'#eaeaea',
    logo: "#eaeaea",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#9A463D",
    highlight: "#75281F",
    button: "#4F0901",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#4F0901",
    ct1: "",
    t2: "#4F0901",
    bg2: "#242629",
    rhighlight: "#4F0901",
    stroke: "#9A463D",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#9A463D",
    modalHighlight: "#4F0901",
    modalHighlightText: "#FFFFFF",
  },
  coral: {
    bg: "#FF7F50",
    link:'#eaeaea',
    logo: "#FABDA7",
    t1: "#FFFFFF",
    secondary: "#FE8F66",
    tertiary: "#FF7F50",
    highlight: "#FD9E7C",
    button: "#FABDA7",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#FABDA7",
    ct1: "",
    t2: "#FABDA7",
    bg2: "#242629",
    rhighlight: "#FABDA7",
    stroke: "#FF7F50",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF7F50",
    modalHighlight: "#FABDA7",
    modalHighlightText: "#383232",
  },
  ebony: {
    bg: "#555D50",
    link:'#eaeaea',
    logo: "#C0C0C0",
    t1: "#FFFFFF",
    secondary: "#C0C0C0",
    tertiary: "#555D50",
    highlight: "#383E34",
    button: "#1A1E17",
    buttonText: "#C0C0C0",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#C0C0C0",
    card2: "#1A1E17",
    ct1: "C0C0C0",
    t2: "#1A1E17",
    bg2: "#242629",
    rhighlight: "#1A1E17",
    stroke: "#555D50",
    cardParent: "",
    icon1: "",
    icon2: "",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#555D50",
    modalHighlight: "#1A1E17",
    modalHighlightText: "#FFFFFF",
  },
  fuchsia: {
    bg: "#C154C1",
    link:'#eaeaea',
    logo: "#F23FF2",
    t1: "#FFFFFF",
    secondary: "#CE4FCE",
    tertiary: "#C154C1",
    highlight: "#DA4ADA",
    button: "#F23FF2",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#F23FF2",
    ct1: "",
    t2: "#F23FF2",
    bg2: "#242629",
    rhighlight: "#F23FF2",
    stroke: "#C154C1",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#C154C1",
    modalHighlight: "#F23FF2",
    modalHighlightText: "#383232",
  },
  gold: {
    bg: "#DBB000",
    link:'#eaeaea',
    logo: "#A68916",
    t1: "#FFFFFF",
    secondary: "#CEA706",
    tertiary: "#DBB000",
    highlight: "#C19D0B",
    button: "#A68916",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#A68916",
    ct1: "",
    t2: "#A68916",
    bg2: "#242629",
    rhighlight: "#A68916",
    stroke: "#DBB000",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#DBB000",
    modalHighlight: "#A68916",
    modalHighlightText: "#383232",
  },
  gray: {
    bg: "#808080",
    link:'#eaeaea',
    logo: "#EBEBEB",
    t1: "#FFFFFF",
    secondary: "#EBEBEB",
    tertiary: "#808080",
    highlight: "#B6B6B6",
    button: "#EBEBEB",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#EBEBEB",
    ct1: "",
    t2: "#EBEBEB",
    bg2: "#242629",
    rhighlight: "#EBEBEB",
    stroke: "#808080",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#808080",
    modalHighlight: "#EBEBEB",
    modalHighlightText: "#383232",
  },
  green: {
    bg: "#00FF00",
    link:'#eaeaea',
    logo: "#02A302",
    t1: "#FFFFFF",
    secondary: "#01E801",
    tertiary: "#00FF00",
    highlight: "#01D101",
    button: "#00FF00",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#00FF00",
    card2: "#02A302",
    ct1: "",
    t2: "#02A302",
    bg2: "#242629",
    rhighlight: "#02A302",
    stroke: "#00FF00",
    cardParent: "",
    icon1: "",
    icon2: "",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#00FF00",
    modalHighlight: "#02A302",
    modalHighlightText: "#383232",
  },
  indigo: {
    bg: "#4B0082",
    link:'#eaeaea',
    logo: "#eaeaea",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#4B0082",
    highlight: "#390161",
    button: "#260240",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#260240",
    ct1: "",
    t2: "#260240",
    bg2: "#242629",
    rhighlight: "#260240",
    stroke: "#4B0082",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#4B0082",
    modalHighlight: "#260240",
    modalHighlightText: "#FFFFFF",
  },
  lavender: {
    bg: "#DCD0FF",
    link:'#eaeaea',
    logo: "#684BBD",
    t1: "#FFFFFF",
    secondary: "#BFAFEF",
    tertiary: "#DCD0FF",
    highlight: "#856DCE",
    button: "#684BBD",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#DCD0FF",
    card2: "#684BBD",
    ct1: "",
    t2: "#684BBD",
    bg2: "#242629",
    rhighlight: "#684BBD",
    stroke: "#DCD0FF",
    cardParent: "",
    icon1: "",
    icon2: "",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#DCD0FF",
    modalHighlight: "#684BBD",
    modalHighlightText: "#FFFFFF",
  },
  lilac: {
    bg: "#FF00FF",
    link:'#eaeaea',
    logo: "#D61184",
    t1: "#FFFFFF",
    secondary: "#D61184",
    tertiary: "#FF00FF",
    highlight: "#EB09C2",
    button: "#D61184",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#D61184",
    ct1: "",
    t2: "#D61184",
    bg2: "#242629",
    rhighlight: "#D61184",
    stroke: "#FF00FF",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF00FF",
    modalHighlight: "#D61184",
    modalHighlightText: "#FFFFFF",
  },
  magenta: {
    bg: "#FF00FF",
    link:'#eaeaea',
    logo: "#D61184",
    t1: "#FFFFFF",
    secondary: "#F505E1",
    tertiary: "#FF00FF",
    highlight: "#EB09C2",
    button: "#F505E1",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#F505E1",
    ct1: "",
    t2: "#D61184",
    bg2: "#242629",
    rhighlight: "#F505E1",
    stroke: "#FF00FF",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF00FF",
    modalHighlight: "#D61184",
    modalHighlightText: "#FFFFFF",
  },
  maroon: {
    bg: "#800000",
    link:'#eaeaea',
    logo: "#eaeaea",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#800000",
    highlight: "#6C0808",
    button: "#570F0F",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#570F0F",
    ct1: "",
    t2: "#570F0F",
    bg2: "#242629",
    rhighlight: "#570F0F",
    stroke: "#800000",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#800000",
    modalHighlight: "#570F0F",
    modalHighlightText: "#FFFFFF",
  },
  mauve: {
    bg: "#E0B0FF",
    link:'#eaeaea',
    logo: "#AD62DF",
    t1: "#FFFFFF",
    secondary: "#D49DF7",
    tertiary: "#E0B0FF",
    highlight: "#C789EF",
    button: "#AD62DF",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#E0B0FF",
    card2: "#AD62DF",
    ct1: "",
    t2: "#AD62DF",
    bg2: "#242629",
    rhighlight: "#AD62DF",
    stroke: "#E0B0FF",
    cardParent: "",
    icon1: "",
    icon2: "",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#E0B0FF",
    modalHighlight: "#AD62DF",
    modalHighlightText: "#FFFFFF",
  },
  mustard: {
    bg: "#FFDB58",
    link:'#eaeaea',
    logo: "#DEAE02",
    t1: "#FFFFFF",
    secondary: "#F7D043",
    tertiary: "#FFDB58",
    highlight: "#EFC52D",
    button: "#DEAE02",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#DEAE02",
    ct1: "",
    t2: "#DEAE02",
    bg2: "#242629",
    rhighlight: "#DEAE02",
    stroke: "#FFDB58",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FFDB58",
    modalHighlight: "#DEAE02",
    modalHighlightText: "#FFFFFF",
  },
  orange: {
    bg: "#FF6900",
    link:'#eaeaea',
    logo: "#FD8835",
    t1: "#FFFFFF",
    secondary: "#FF710E",
    tertiary: "#FF6900",
    highlight: "#FE791B",
    button: "#FD8835",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#FD8835",
    ct1: "",
    t2: "#FD8835",
    bg2: "#242629",
    rhighlight: "#FD8835",
    stroke: "#FF6900",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF6900",
    modalHighlight: "#FD8835",
    modalHighlightText: "#FFFFFF",
  },
  periwinkle: {
    bg: "#CCCCFF",
    link:'#eaeaea',
    logo: "#A6A6E0",
    t1: "#FFFFFF",
    secondary: "#A6A6E0",
    tertiary: "#CCCCFF",
    highlight: "#8080C0",
    button: "#343480",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#CCCCFF",
    card2: "#343480",
    ct1: "",
    t2: "#343480",
    bg2: "#242629",
    rhighlight: "#343480",
    stroke: "#CCCCFF",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#CCCCFF",
    modalHighlight: "#343480",
    modalHighlightText: "#FFFFFF",
  },
  pink: {
    bg: "#FF66C4",
    link:'#eaeaea',
    logo: "#F7C1E2",
    t1: "#FFFFFF",
    secondary: "#FD7DCC",
    tertiary: "#FF66C4",
    highlight: "#FB94D3",
    button: "#FD7DCC",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#FD7DCC",
    ct1: "",
    t2: "#F7C1E2",
    bg2: "#242629",
    rhighlight: "#FD7DCC",
    stroke: "#FF66C4",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF66C4",
    modalHighlight: "#F7C1E2",
    modalHighlightText: "#383232",
  },
  purple: {
    bg: "#800080",
    link:'#eaeaea',
    logo: "#eaeaea",
    t1: "#FFFFFF",
    secondary: "#eaeaea",
    tertiary: "#800080",
    highlight: "#540954",
    button: "#330D33",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#330D33",
    ct1: "",
    t2: "#330D33",
    bg2: "#242629",
    rhighlight: "#330D33",
    stroke: "#800080",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#800080",
    modalHighlight: "#330D33",
    modalHighlightText: "#FFFFFF",
  },
  red: {
    bg: "#FF0000",
    link:'#eaeaea',
    logo: "#BC0B0B",
    t1: "#FFFFFF",
    secondary: "#F30000",
    tertiary: "#FF0000",
    highlight: "#E20000",
    button: "#BC0B0B",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#BC0B0B",
    ct1: "",
    t2: "#BC0B0B",
    bg2: "#242629",
    rhighlight: "#BC0B0B",
    stroke: "#FF0000",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FF0000",
    modalHighlight: "#BC0B0B",
    modalHighlightText: "#FFFFFF",
  },
  royalBlue: {
    bg: "#4169E1",
    link:'#eaeaea',
    logo: "#26298E",
    t1: "#FFFFFF",
    secondary: "#426EE7",
    tertiary: "#4169E1",
    highlight: "#4174F0",
    button: "#26298E",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#eaeaea",
    card2: "#26298E",
    ct1: "",
    t2: "#26298E",
    bg2: "#242629",
    rhighlight: "#26298E",
    stroke: "#4169E1",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#4169E1",
    modalHighlight: "#26298E",
    modalHighlightText: "#FFFFFF",
  },
  salmon: {
    bg: "#FA8072",
    link:'#eaeaea',
    logo: "#F08778",
    t1: "#FFFFFF",
    secondary: "#FB867F",
    tertiary: "#FA8072",
    highlight: "#FB8E86",
    button: "#F08778",
    buttonText: "#383232",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#383232",
    card2: "#F08778",
    ct1: "",
    t2: "#F08778",
    bg2: "#242629",
    rhighlight: "#F08778",
    stroke: "#FA8072",
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#FA8072",
    modalHighlight: "#F08778",
    modalHighlightText: "#383232",
  },
  seafoamGreen: {
    bg: "#9FE2BF",
    link:'#eaeaea',
    logo: "#428B69",
    t1: "#FFFFFF",
    secondary: "#87E4C3",
    tertiary: "#9FE2BF",
    highlight: "#6AD5A1",
    button: "#428B69",
    buttonText: "#FFFFFF",
    input: "#FFFFFF",
    inputText: "#383232",
    card1: "#242629",
    ct2: "#9FE2BF",
    card2: "#428B69",
    ct1: "",
    t2: "#428B69",
    bg2: "#242629",
    rhighlight: "#428B69",
    stroke: "#9FE2BF",
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a",
    modalTitle: "#FFFFFF",
    modalContent: "#FFFFFF",
    modalbg2: "#242629",
    modalTitle2: "#9FE2BF",
    modalHighlight: "#428B69",
    modalHighlightText: "#FFFFFF",
  },
  silver: {
    bg: "#C0C0C0", // Silver bg color
    link:'#eaeaea',
    logo: "#CCD2D7", // Silver logo color
    t1: "#C6C9CC", // Light gray t1 color
    secondary: "#C9CED2", // Lighter gray secondary color
    tertiary: "#CCD2D7", // Lightest gray tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#C6C9CC", // Button color (common)
    buttonText: "#010101", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#CCD2D7", // Dark card1 bg color
    ct2: "#242629", // Dark card1 title color
    card2: "#CCD2D7", // Button color or secondary card1 color
    ct1: "#242629", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section

    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#CCD2D7", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "#383232",

    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  taupe: {
    bg: "#725C4A", // Taupe bg color
    link:'#eaeaea',
    logo: "#725C4A", // Taupe logo color
    t1: "#725C4A", // Taupe t1 color
    secondary: "#eaeaea", // Taupe secondary color
    tertiary: "#725C4A", // Taupe tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#483C32", // Button color (common)
    buttonText: "#eaeaea", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#eaeaea", // Dark card1 title color
    card2: "#483C32", // Button color or secondary card1 color
    ct1: "#fffffe", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629",
    icon2: "#eaeaea", // Dark tertiary color for another section
    rhighlight: "#483C32", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    cardParentTitle: "",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  teal: {
    bg: "#008080", // Teal bg color
    link:'#eaeaea',
    logo: "#159393", // Teal logo color
    t1: "#2AA6A6", // Teal t1 color
    secondary: "#3FB9B9", // Teal secondary color
    tertiary: "#54CCCC", // Teal tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#008080", // Button color (common)
    buttonText: "#54CCCC", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#16161a", // Dark card1 title color
    card2: "#00AB9A", // Button color or secondary card1 color
    ct1: "#fffffe", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#00AB9A", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  turquoise: {
    bg: "#40E0D0", // Turquoise bg color
    link:'#eaeaea',
    logo: "#30D3C3", // Turquoise logo color
    t1: "#20C6B5", // Turquoise t1 color
    secondary: "#10B9A8", // Turquoise secondary color
    tertiary: "#00AB9A", // Turquoise tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#00AB9A", // Button color (common)
    buttonText: "#383232", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#16161a", // Dark card1 title color
    card2: "#00AB9A", // Button color or secondary card1 color
    ct1: "#fffffe", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#00AB9A", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  violet: {
    bg: "#6E1EC0", // Violet bg color
    link:'#eaeaea',
    logo: "#eaeaea", // Violet logo color
    t1: "#6E1EC0", // Violet t1 color
    secondary: "#662DA0", // Violet secondary color
    tertiary: "#5D3B80", // Violet tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#5D3B80", // Button color (common)
    buttonText: "#eaeaea", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#eaeaea", // Dark card1 title color
    card2: "#5D3B80", // Button color or secondary card1 color
    ct1: "#fffffe", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#5D3B80", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "#eaeaea",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  white: {
    bg: "#FFFFFF", // White bg color
    link:'#eaeaea',
    logo: "#FAFAFA", // White logo color
    t1: "#F5F5F5", // Light gray t1 color
    secondary: "#F0F0F0", // Lighter gray secondary color
    tertiary: "#EBEBEB", // Lightest gray tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#EBEBEB", // Button color (common)
    buttonText: "#010101", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#16161a", // Dark card1 title color
    card2: "#EBEBEB", // Button color or secondary card1 color
    ct1: "#fffffe", // Light card1 title color (common)
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#EBEBEB", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
  yellow: {
    bg: "#FFFF00", // Yellow bg color
    link:'#eaeaea',
    logo: "#FFF700", // Yellow logo color
    t1: "#FFEF00", // Yellow t1 color
    secondary: "#FFE700", // Yellow secondary color
    tertiary: "#FFDF00", // Yellow tertiary color
    highlight: "#7f5af0", // Highlight color (common)
    button: "#FFDF00", // Button color (common)
    buttonText: "#383232", // Text color for buttons and UI elements
    input: "#fffffe", // White input bg color
    inputText: "#010101", // Text color for inputs and UI elements
    card1: "#242629", // Dark card1 bg color
    ct2: "#383232", // Dark card1 title color
    card2: "#FFDF00", // Button color or secondary card1 color
    ct1: "#16161a", // Dark card1 title color
    t2: "#2cb67d", // Button or t1 color for another section
    bg2: "#242629", // Dark tertiary color for another section
    rhighlight: "#FFDF00", // Highlight color for another section
    stroke: "#16161a", // Dark stroke color (common)
    cardParent: "",
    icon1: "",
    icon2: "#383232",
    modalbg: "#16161a", // Dark modal bg color
    modalTitle: "#fffffe", // Light modal title color (common)
    modalContent: "#fffffe", // Light modal content color (common)
    modalbg2: "#242629", // Dark secondary modal bg color
    modalTitle2: "#16161a", // Dark secondary modal title color
    modalHighlight: "#2cb67d", // Button or modal highlight color
    modalHighlightText: "#00214d", // Text color for modal highlight
  },
};
