import React, { useState, useContext, useRef, useEffect } from "react";
// style
import s from "./style.module.css";
// contexts
// components
import Popup from "../../../../components/Popup/Popup";
import { getDatas, putDatas } from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { randomCode } from "../../../../../../utilities/methods";

function Edit({ closeModal, datas }) {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [values, setValues] = useState({
    title: datas.title,
    description: datas.description,
    color: datas.color,
    code: datas.code,
    class_id: datas.class_id, 
  });
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error)
    }

   
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const updateClass = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    const { title, description ,code, color } = values;
    if (!title || !description || !code || !color) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }

    try {
        const url = `/teacher-api/classes/update`;
        const newClass = {
          ...values,
          updated_by: `${user.fname} ${user.lname}`,
        };
        const res = await putDatas(url, newClass, setError);

        if (res && res.response) {
          setActive(1);
          setMessage(res.response);
        } else{
          setActive(3);
          setMessage(res.error);
        }
    } catch (error) {
      setActive(3);
      setMessage("Updating Class Failed");
    }
  };

  const setCode = () => {
    const code = randomCode();
    if (code) {
      setValues({ ...values, code });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={s.container}>
      <Popup message={message} active={active} />

      <div className={s.header}>UPDATE CLASS
        <button className = {s.closeButton} onClick={() => closeModal()}>Close</button>
      </div>
      <form className={s.form} onSubmit={updateClass}>
        <input
          type="text"
          name="title"
          placeholder="Subject Title"
          value={values.title} 

          className={s.input}
          onChange={handleChange}
          required
        ></input>
        <input
          type="text"
          name="description"
          placeholder="Section"
          value={values.description} 
          className={s.textarea}
          onChange={handleChange}
          required
        ></input>
        <span>
          <input
            type="text"
            name="code"
            placeholder="Code"
            value={values.code} 
            className={s.textarea}
            onChange={handleChange}
            required
          ></input>
          <button type="button" className={s.randomCodeButton} onClick={setCode}>
            Generate
          </button>
        </span>
        <span>

        <select name="color" onChange={handleChange}>
          <option value="rgb(137, 4, 4)">red</option>
          <option value="#242629">dark</option>
          <option value="rgb(168, 168, 5)">yellow</option>
          <option value="rgb(15, 15, 96)">blue</option>
          <option value="rgb(9, 90, 9)">green</option>
          <option value="rgb(145, 94, 1)">orange</option>
          <option value="rgb(90, 12, 90)">violet</option>
        </select>
        <div className = {s.colorPreview} style={{backgroundColor:values.color}}></div>
          </span>


        <div className={s.buttons}>
          <button type="submit" className={s.submitButton}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default Edit;
