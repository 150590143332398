// A.js
export function setLetterL(fp) {
  const letterL = new fp.GestureDescription("L");

  // Gesture L: Thumb up, other fingers curled
  letterL.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  letterL.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterL.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterL.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterL.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);


  letterL.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalRight, 0.5);
  letterL.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.5);
  letterL.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.5);
  letterL.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 0.5);

  return letterL;
}
