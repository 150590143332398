import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminLogin from "../pages/Admin/pages/Authentication/Login";
import AdminDashboard from "../pages/Admin/pages/Dashboard/Dashboard";
import AdminAccounts from "../pages/Admin/pages/Dashboard/modals/accounts/Accounts";
import AdminRequests from "../pages/Admin/pages/Dashboard/modals/requests/StrandHandling";
import AdminStrands from "../pages/Admin/pages/Dashboard/modals/strands/Strands";
import AdminStudents from "../pages/Admin/pages/Dashboard/modals/students/Students";
import AdminTeachers from "../pages/Admin/pages/Dashboard/modals/teachers/Teachers";
import ProtectedRoute from "../contexts/ProtectedRoute";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute role="admin" >
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts"
        element={
          <ProtectedRoute role="admin" >
            <AdminAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <ProtectedRoute role="admin" >
            <AdminRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/strands"
        element={
          <ProtectedRoute role="admin" >
            <AdminStrands />
          </ProtectedRoute>
        }
      />
      <Route
        path="/students"
        element={
          <ProtectedRoute role="admin" >
            <AdminStudents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/teachers"
        element={
          <ProtectedRoute role="admin" >
            <AdminTeachers />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
