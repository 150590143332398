// A.js
export function setLetterQ(fp) {
    const letterQ = new fp.GestureDescription("Q");
  
    letterQ.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    letterQ.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
    letterQ.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 0.5);
    letterQ.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
    letterQ.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
    letterQ.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);



    // letterQ.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalDown, 0.8);
    letterQ.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalDownLeft, 0.3);
    // letterQ.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalDown, 0.4);

    letterQ.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalDownLeft, 0.3);
    // letterQ.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalDown, 0.4);

    // letterQ.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalDownLeft, 0.5);
    // letterQ.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalDown, 0.6);
    // letterQ.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalDown, 0.6);
    // letterQ.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalDownLeft, 0.5);
    // letterQ.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalDownLeft, 0.5);
    // letterQ.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalDownLeft, 0.5);

  
    return letterQ;
  }
  