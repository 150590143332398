import React, { useEffect, useRef, useState } from "react";

import s from "./css/main.module.css";
import aslhand from "../../../../assets/aslhan.gif";
import bg2 from "../../../../assets/bg10.jpg";
import learn from "../../../../assets/whity.jpg";
import { icons } from "../../../../utilities/icons";
import { handleAssetPreview } from "../../../../utilities/methods";
import { getDatas, postDatas, uploadToS3 } from "../../../../services/api";
import Button from "../../../../components/custom/button";

function Main() {
  const [isChoicesOpen, setIsChoicesOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isNotifVisible, setIsNotifVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [notification, setNotification] = useState("");
  const [error, setError] = useState("");
  const refProfile = useRef(null);
  const [datas, setDatas] = useState({
    fname: "",
    mname: "",
    lname: "",
    image: "",
    year: "",
    strand: "",
    contact: "",
    email: "",
    password: "",
    confirm: "",
    role: "",
  });

  const toggleChoices = () => {
    setIsChoicesOpen(!isChoicesOpen);
    setIsSignupOpen(false);
  };

  const toggleSignup = () => {
    setIsSignupOpen(!isSignupOpen);
    setIsChoicesOpen(false);
  };

  const handleSubmit = async (e) => {
    setIsSuccess(false);
    setIsNotifVisible(false);
    e.preventDefault();
    const { role, email, password, image, confirm } = datas;

    if (role.length <= 0 || !email || !password) {
      return;
    }
    if (password !== confirm) {
      setIsNotifVisible(true);
      setNotification("Password did not match");
      return;
    }

    // Check if email already exists
    const res =
      role === "teacher"
        ? await postDatas(
            "/teacher-api/authentication/check-email",
            { email },
            setError
          )
        : await postDatas(
            "/student-api/authentication/check-email",
            { email },
            setError
          );

    // Early return if the email already exists
    if (res && res.response && res.response.email) {
      setIsNotifVisible(true);
      setNotification("Email Already Exists");
      return;
    }

    // Proceed with S3 upload if email does not exist
    const { url } = await getDatas(
      `/getS3Access/profiles/${role}/${image.name}`,
      setError
    );

    if (url) {
      await uploadToS3(url, image);

      const imageUrl = url.split("?")[0];

      if (imageUrl) {
        // Update image URL in datas object
        datas.image = imageUrl;

        // Proceed with signup
        const res =
          role === "teacher"
            ? await postDatas(
                "/teacher-api/authentication/signup",
                datas,
                setError
              )
            : await postDatas(
                "/student-api/authentication/signup",
                datas,
                setError
              );

        if (res && res.response) {
          setIsSuccess(true);
          setNotification("Registration complete");
          const {email} = datas;
          const newDatas = {email,subject:`Trackademy Account Registration`,text:`${email} registered an account and waiting for your validation`}
          
          await postDatas(`/mailer/admin`,newDatas,setError)
        }
      }
    }
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    const image = refProfile.current;

    if (file && image) {
      handleAssetPreview(file, image);
      datas.image = file;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDatas({ ...datas, [name]: value });
  };

  const navigateTo = (url, role) => {
    localStorage.setItem("role", role);

    setTimeout(() => {
      window.open(url, "_parent");
    }, 100);
  };

  useEffect(() => {
    // localStorage.removeItem("role");
  }, []);

  return (
    <main className={s.main}>
      <img src={learn} className = {s.absoluteBackground} alt="bacground"></img>
      <section>
        <div className={s.leftInformation}>
          <img src={bg2} alt="" className={s.welcomeBackgroundImage} />
          <div className={s.leftContent}>
            <div className={s.leftAuthor}>Trackademy</div>
            <div className={s.leftTitle}>EASIER EDUCATION</div>
            <div className={s.leftTopic}>Keep Learning</div>
            <div className={s.leftDescription}>
              Unlock seamless learning with our streamlined LMS designed to
              deliver content, track progress, and keep you updated with the
              latest announcements. Focus on learning while we manage your
              progress and provide the resources you need, all in one
              easy-to-use platform. Start today and stay on top of your
              education journey!
            </div>
            <div className={s.leftButtons}>
              <Button type="button" action={toggleChoices}>
                {icons.gradFill} Access Trackademy
              </Button>
              <Button type="button" action={toggleSignup}>
                Create account &rarr;
              </Button>
            </div>
          </div>
        </div>

        <img src={bg2} alt="template" loading="lazy"></img>
      </section>
      {/* =========================================================================== */}
      <section>
        {isChoicesOpen || isSignupOpen ? (
          ""
        ) : (
          <div className={s.imageContainer}>
            <h2>Powered with Signify</h2>
            <img src={aslhand} alt="template" loading="lazy"></img>
          </div>
        )}
        {isSignupOpen && (
          <div className={`${s.signup} ${s.slideDown}`}>
            <h4>Account Registration</h4>
            <form onSubmit={handleSubmit}>
              <h4>Personal Information</h4>
              <div className={s.firstSection}>
                <div>
                  <img
                    src=""
                    alt="template"
                    loading="lazy"
                    ref={refProfile}
                  ></img>
                  <input
                    type="file"
                    name="profileImage"
                    id=""
                    onChange={handleProfileChange}
                    required
                  />
                </div>
                <div>
                  <h4>Role</h4>
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="teacher"
                      onChange={handleChange}
                      required
                    />
                    teacher
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="role"
                      value="student"
                      onChange={handleChange}
                      required
                    />
                    student
                  </label>
                </div>
              </div>
              <div className={s.secondSection}>
                <input
                  type="text"
                  value={datas.fname}
                  name="fname"
                  onChange={handleChange}
                  placeholder="First name"
                  autoComplete="false"
                  required
                />
                <input
                  type="text"
                  value={datas.mname}
                  name="mname"
                  onChange={handleChange}
                  placeholder="Middle name"
                  autoComplete="false"
                />
                <input
                  type="text"
                  value={datas.lname}
                  name="lname"
                  onChange={handleChange}
                  placeholder="Last name"
                  autoComplete="false"
                  required
                />
                <input
                  type="text"
                  value={datas.contact}
                  name="contact"
                  onChange={handleChange}
                  placeholder="Contact"
                  autoComplete="false"
                  required
                />
                {datas.role === "student" && (
                  <>
                    <select
                      name="strand"
                      value={datas.strand}
                      onChange={handleChange}
                      required
                    >
                      <option value="ICT">ICT</option>
                      <option value="HUMSS">HUMSS</option>
                      <option value="STEM">STEM</option>
                      <option value="TVL">TVL</option>
                      <option value="HM">HM</option>
                    </select>
                    <select
                      name="year"
                      value={datas.year}
                      onChange={handleChange}
                      required
                    >
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </>
                )}
              </div>
              <h4>Account Details</h4>
              <div className={s.thirdSection}>
                <input
                  type="email"
                  name="email"
                  value={datas.email}
                  onChange={handleChange}
                  placeholder="email"
                  required
                />
                <input
                  type="password"
                  name="password"
                  value={datas.password}
                  onChange={handleChange}
                  placeholder="password"
                  autoComplete="false"
                  required
                />
                <input
                  type="password"
                  value={datas.confirm}
                  name="confirm"
                  onChange={handleChange}
                  placeholder="Confirm password"
                  autoComplete="false"
                  required
                />
                {isNotifVisible && (
                  <p>
                    {icons.warnLine} {notification}
                  </p>
                )}
                {isSuccess && (
                  <p className={s.success}>
                    {icons.checkLine} {notification}
                  </p>
                )}
              </div>
              <div className={s.fourthSection}>
                <Button type="button" action={toggleSignup}>
                  {" "}
                  &larr;Back
                </Button>
                <Button type="submit">Register</Button>
              </div>
            </form>
          </div>
        )}
        {isChoicesOpen && (
          <div className={`${s.choices} ${s.slideDown}`}>
            <div>What is your role in LMS?</div>
            <div className = {s.choicesButtons}>
              <button
                type="button"
                onClick={() => navigateTo("/teacher/login", "teacher")}
              >
                {icons.classLine} Teacher
              </button>
              <button
                type="button"
                onClick={() => navigateTo("/student/login", "student")}
              >
                {icons.gradLine} Student
              </button>
            </div>
          </div>
        )}
      </section>
    </main>
  );
}

export default Main;
