import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//style
import style from "../../../../components/style_configs/buttons.module.css";
import s from "./css/progress.module.css";
//methods
import {
  fetchData,
  navigateTo,
} from "../../../../../../utilities/methods";
// contexts
import { UserDataContext } from "../../../../../../contexts/UserDataProvider";
// components
import Modal from "../../../../components/Modal_layout/Modal";
import Default from "../../../../../defaults/Default/Default";
import Header from "../../../../components/Header/Header";
import Sidebar from "../../../../components/Sidebar/Sidebar";

function Progress() {
  const navigate = useNavigate();
  const { userData } = useContext(UserDataContext);
  const [strand, setStrand] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  //  get strands specific to teacher
  const getStrands = async (teacher_id) => {
    setError(null);
    const url = `/teacher-api/strand/${teacher_id}`;
    const datas = await fetchData(url, setError);
    if (datas) {
      setStrand(datas);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData && userData.teacher_id) {
      getStrands(userData.teacher_id);
    }
  }, [userData]); // re run on user data change

  return (
    <>
      <Header title={"Strands"} />

      <Sidebar />

      <Modal>
        <section className={s.parent}>
          <button
            className={style.modalButton}
            onClick={() => navigateTo(navigate, `/teacher/dashboard`)}
          >
            Dashboard
          </button>
          <div className={style.header}>STRANDS - STUDENT PROGRESS</div>
          <p>
            Select a strand to view classes to check student assessment
            progresses
          </p>
          {/* main body */}
          {error ? (
            <Default
              text={`No Strand Records Found`}
              active={3}
            />
          ) : loading ? (
            <Default
              text={`Loading Strand Records, Please Wait...`}
              active={1}
            />
          ) : (
            <>
              {strand.length === 0 ? (
                <Default
                  text={`No Strand Records Found, Please Create one or be a Strand Collaborator for your Co Instructors`}
                  active={2}
                />
              ) : (
                <>
                  {strand.map((str) => (
                    <div
                      key={str.strand_id}
                      className={s.card}
                      onClick={() =>
                        navigateTo(
                          navigate,
                          `/teacher/dashboard/progress/strand/${
                            str.strand_id
                          }/${`${str.year} - ${str.strand}`}/class`
                        )
                      }
                    >
                      {str.title}
                      <br />
                      {str.year} - {str.strand}
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </section>
      </Modal>
    </>
  );
}

export default Progress;
