import React, { useState } from "react";
// styles
import style from "../../../../../components/css/buttons.module.css";
import s from "./style.module.css";
// icons
import { icons } from "../../../../../../../utilities/icons";
// component
import Announce from "./Announce";
import Email from "./Email";
import Modal from "../../../../../components/Modal_layout/Modal";

function View({ datas, closeStudentInfo }) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (Component, datas) => {
    setModalContent(
      <Component
        closeModal={closeModal}
        id={datas.teacher_id}
        datas={datas}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <>

      {isModalOpen ? (
        <Modal>{modalContent}</Modal>
      ) : (
        <Modal>
          <button className={style.modalButton} onClick={closeStudentInfo}>
            Back
          </button>
          <div className={s.infoContainer}>
            <div className={style.header}>TEACHER INFORMATION</div>

            <div className={s.studInfo}>
              <span className={s.studImage}>
                <img src={datas.image} className={s.image} loading="lazy"></img>
              </span>
              <b className={s.label}>Name: </b>
              {`${datas.fname} ${
                datas.mname != null ? datas.mname + "." : " "
              } ${datas.lname}`}
              <br />
              <b className={s.label}>Contact: </b>
              {datas.contact}
              <br />
              <b className={s.label}>Email: </b>
              {datas.email}
              <br />
            </div>
            <div className={s.buttons}>
              <button
                type="button"
                className={style.button}
                onClick={() => openModal(Email, datas)}
              >
                <span className={style.icon}>{icons.emailFill}</span>
              </button>
              <button
                type="button"
                className={style.button}
                onClick={() => openModal(Announce, datas)}
              >
                <span className={style.icon}>{icons.notifFill}</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default View;
