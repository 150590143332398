import React, { useEffect, useState } from "react";
import { icons } from "../../../../../../utilities/icons";
import s from "./css/students.module.css";
import View from "./modal/View";
import Default from "../../../../../defaults/Default/Default";
import Top from "./Top";
import UpdateUser from "./modal/UpdateUser";
import Prompt from "../../../../components/Prompt/Prompt";
import Popup from "../../../../components/Popup/Popup";
import { deleteDatas, getDatas } from "../../../../../../services/api";
import Mail from "../../../../components/Mail_layout/Mail";

function Students() {
  const [students, setStudents] = useState([]);
  const [studentsCopy, setStudentsCopy] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [message, setMessage] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // ***************************************************
  // delete student
  // ***************************************************
  const goConfirm = async (
    confirmation,
    id,
    data,
    imageUrl
  ) => {
    setError(null);
    setActive(null);

    if (confirmation) {

      if (imageUrl) {
        const imageFilename = imageUrl.split("/").pop(); 
        const folder = "profiles";
        const role = "student"; 

        // Call the API to delete the image from S3
        const deleteImageUrl = `/deleteS3Asset/${folder}/${role}/${imageFilename}`;
        const deleteImageRes = await deleteDatas(deleteImageUrl, null, setError);

        if (!deleteImageRes) {
          setTimeout(() => {
            setActive(3);
            setMessage("Deleting Image Failed");
            throw new Error("Failed to delete image from S3");
          },0)
        }
      }
      const url = `/admin-api/delete/account`;
      const datas = { role:"Student",id };
      console.log(datas);
      const res = await deleteDatas(url, datas, setError);

      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        // update ui
        getStudents((prev) => prev.filter((us) => us.student_id !== id));
      } else {
        setActive(3);
        setMessage("Deleting Failed");
      }
    }
    setIsModalOpen(false);
  };

  const openModal = (Component, stud) => {
    setModalContent(
      <Component
        datas={stud}
        closeStudentInfo={closeStudentInfo}
        onConfirm={goConfirm}
        setStudents={setStudents}
        closeModal={() => setIsModalOpen(false)}
        getStudents={getStudents}
        id={stud.student_id}
        imageUrl={stud.image}
      />
    );
    setIsModalOpen(true);
  };

  const closeStudentInfo = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const getStudents = async () => {
    setError(null);
    const url = `/admin-api/get/students`;
    try {
      const datas = await getDatas(url, setError);
      if (datas && datas.response) {
        setStudents(datas.response);
        setStudentsCopy(datas.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
   
  };

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        studentsCopy={studentsCopy}
        setStudents={setStudents}
      />

      {isModalOpen ? (
        <Mail>{modalContent}</Mail>
      ) : (
        <section className={s.parent}>
          {error ? (
            <Default active={2} />
          ) : loading ? (
            <Default active={1} />
          ) : (
            <>
              {students.length === 0 ? (
                <Default active={2} />
              ) : (
                <div className={s.container}>
                  {students.map((stud) => (
                    <div key={stud.student_id} className={s.card}>
                      <span>
                        <p className={s.name}>
                          {stud.fname} {stud.lname}
                        </p>
                        <p className={s.strand}>
                          {stud.year} - {stud.strand}
                        </p>
                      </span>
                      <div className={s.buttons}>
                        <button onClick={() => openModal(View, stud)}>
                          info
                        </button>
                        <button onClick={() => openModal(UpdateUser, stud)}>
                          {icons.updateFill}
                        </button>
                        <button onClick={() => openModal(Prompt, stud)}>
                          {icons.deleteFill}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

export default Students;
