import React, { useEffect, useState } from "react";
// styles
import s from "./css/request.module.css";
// icons
import { icons } from "../../../../../../utilities/icons";
// methods
// component
import Default from "../../../../../defaults/Default/Default";
import Prompt from "../../../../components/Prompt/Prompt";
import Top from "./TopAccountVerification"; 
import View from "./modal/View";
import Popup from "../../../../components/Popup/Popup";
import { deleteDatas, getDatas,postDatas, putDatas } from "../../../../../../services/api";
 
function Requests({ closeModal, reCount }) {
  const [requests, setRequests] = useState([]);
  const [requestsCopy, setRequestsCopy] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(true);

  // *******************************************************
  // get all strand handling requests
  // *******************************************************
  const getRequests = async () => {
    setError(null);
    const url = `/admin-api/get/accounts/pending`;
    const res = await getDatas(url, setError);
    try {
      if (res && res.response) {
        setRequests(res.response);
        setRequestsCopy(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
   
  };

  // *******************************************************
  // update account status
  // *******************************************************
  const goAccept = async (req) => {
    const email = req?.email;
    setError(null)
    setMessage("")
    setActive(null) 

    const url = `/admin-api/put/account/status`

    try {
      const accepted = await putDatas(url, req, setError);

      if (accepted.response) {
        setActive(1);
        setMessage("Account Verified!");
        getRequests();
        // email notification
        const emailDatas = {
          email: email,
          subject: `Account Status - Approved`,
          text: `Your lms account has been approved by the administrator, you can now proceed in using LMS. Have fun and enjoy!`,
        }

        setError(null);
        const sent = await postDatas("/admin-api/mailer", emailDatas, setError)
        if (sent) {
          console.log("Email Sent")
        } else {
          console.log("Faile dot Email")
        }
      }
    } catch (error) {
      setActive(3);
      setMessage("Failed to Accept teacher strand handling request");
    }
  }

  // ***************************************************
  // reject user 
  // ***************************************************
  const goReject = async (confirmation, request_id, datas) => {
    const email = datas?.email;

    setError(null);
    setActive(null);

    if (confirmation) {
      const url = `/admin-api/delete/account`;
      const res = await deleteDatas(url, datas, setError);

      if (res.response) {
        setActive(1);
        setMessage("Successfully Rejected strand handling requests");
        setRequests(prev => prev.filter((req) => req.request_id !== request_id))
        // email notification
        const emailDatas = {
          email: email,
          subject: `LMS Account Verification Status - Rejected`,
          text: `Your Request to verify your LMS account has been Rejected by the administrator, for more details please contact the administrators`,
        }

        const sent = await postDatas("/admin-api/mailer", emailDatas, setError)
        if (sent) {
          console.log("Email Sent")
        } else {
          console.log("Faile dot Email")
        }

      }
    }
    setIsModalOpen(false);
  };

  // *******************************************************
  // modal toggler and use Effect
  // *******************************************************
  const openModal = (Component, req) => {
    setModalContent(<Component datas={req} id={req.request_id}
      closeStudentInfo={() => setIsModalOpen(false)}
      onConfirm={goReject} />);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        requestsCopy={requestsCopy}
        setRequests={setRequests}
      />

      {isModalOpen ? (
        <>{modalContent}</>
      ) : (
        <section className={s.parent}>
          {error ? (
            <Default active={2} />
          ) : loading ? (
            <Default
              active={1}
            />
          ) : (
            <>
              {requests.length === 0 ? (
                <Default active={2} />
              ) : (
                <div className={s.container}>
                  {requests.map((req) => (
                    <div key={req.request_id} className={s.card}>
                      {/* ************ main ************  */}
                      <div className={s.main}>
                        {req.role === "Teacher" ? ("Teacher ") : ("Student ")} <b>{req.fname} {req.lname}</b>{" "}
                        Wishes to access the LMS <b>{req.year} - {req.strand}</b>
                      </div>
                      {/* ************ buttons ************ */}
                      <div className={s.buttons}>
                        <button onClick={() => openModal(View, req)}>
                          <span className={s.button}>View info</span>
                        </button>
                        <button onClick={() => goAccept(req)}>
                          <span className={s.button}>{icons.checkFill}</span>
                        </button>
                        <button onClick={() => openModal(Prompt, req)}>
                          <span className={s.button}>{icons.deleteFill}</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

export default Requests;
