export function setLetterB(fp) {
    const letterB = new fp.GestureDescription("B");
   // Gesture B: Palm flat (index finger extended)
   letterB.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
   letterB.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
   letterB.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
   letterB.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
   letterB.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);
 
   letterB.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpRight, 1.0);
   letterB.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 1.0);
   letterB.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);
   letterB.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 1.0);
   letterB.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 1.0);



    return letterB;
  }
  