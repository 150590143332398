import React, { useEffect, useState } from "react";
// styles
import s from "../../../components/style_configs/top_side_main.module.css";
import style from "./Teaching.module.css";
// component
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Center from "../../../components/Center_layout/Center";
import Main from "../parts/strands/main_content/Main";
import Default from "../../../../defaults/Default/Default";
// contexts
import { getDatas } from "../../../../../services/api";
import { useNavigate } from "react-router-dom";

const Teaching = () => {
  const [strand, setStrand] = useState([]);
  const [copy, setCopy] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const fetchTeachers = async (teacher_id) => {
    setError(null);
    const url = `/teacher-api/strand/` + teacher_id;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setStrand(res.response);
        setCopy(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeachers(user.teacher_id);
  }, [user]);

  return (
    <>
      {user ? (
        <div className={style.TeachingContainer}>
          <header className={s.header}>
            <Header title="STRANDS" user={user} />
          </header>
          <aside className={s.sidebar}>
            <Sidebar />
          </aside>
          <section className={s.main}>
            <Center>
              {error ? (
                <Default active={3} />
              ) : loading ? (
                <Default active={1} />
              ) : (
                <Main
                  copy={copy}
                  strand={strand}
                  teacher_id={user.teacher_id}
                  setStrand={setStrand}
                  user={user}
                />
              )}
            </Center>
          </section>
        </div>
      ) : (
        <Default active={1} />
      )}
    </>
  );
};

export default Teaching;
