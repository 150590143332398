import React, { useState } from "react";
import s from "../css/modal.module.css";
import { postDatas } from "../../../../../services/api";
import Popup from "../../../components/Popup/Popup";

function JoinRoom(props) {
  const { datas, student_id, setIsModalOpen, fetchStudentClasses } = props;
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(0);
  const [error, setError] = useState("");

  const goJoinClass = async () => {
    setError("");
    setMessage("");
    setActive(0);

    const class_id = datas?.class_id;
    const class_code = datas?.code;
    const values = { class_id, student_id };

    if (class_code !== code) {
      setActive(0);
      setTimeout(() => {
        setActive(2);
        setMessage("Invalid Class Code");
      }, 0); 
      return;
    }

    const res = await postDatas(
      `/student-api/learn/join-class`,
      values,
      setError
    );

    if (res && res.response) {
      setActive(1); // Success
      setMessage(res.response);
      setTimeout(() => {
        setIsModalOpen(false);
        setMessage(""); 
        setActive(0); 
      }, 3000);
      fetchStudentClasses();
    } else {
      setActive(3); 
      setMessage(res.error);
    }
  };

  return (
    <section className={`${s.joinRoomContainer} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.joinRoomHeader}>
        <span>Join Room</span>
        <button onClick={() => setIsModalOpen(false)} className={s.closeButton}>
          Close
        </button>
      </div>
      <span className={s.joinRoomSubHeader}>
        <p className={s.subject}>
          <b style={{ color: datas.color }}>Subject:</b> {datas?.title}
        </p>
        <p className={s.section}>
          <b style={{ color: datas.color }}>Section: </b>
          {datas?.description}
        </p>
      </span>
      <div className={s.joinRoomMain}>
        <input
          type="text"
          name="code"
          value={code}
          placeholder="Enter Classroom Code"
          onChange={(e) => setCode(e.target.value)}
        />
        <button
          onClick={goJoinClass}
          className={s.joinRoomSubmitButton}
          style={{ backgroundColor: datas?.color }}
        >
          Join
        </button>
      </div>
    </section>
  );
}

export default JoinRoom;
