import React, { useEffect, useState } from "react";
// styles
import s from "./announce.module.css";
// components
import { useNavigate } from "react-router-dom";
import {
  getDatas,
  postDatas,
  uploadToS3,
} from "../../../../../../../services/api";
import Popup from "../../../../../components/Popup/Popup";
import { icons } from "../../../../../../../utilities/icons";

function Announce({ closeModal, str }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [studentEmails, setStudentEmails] = useState([]);

  const [values, setValues] = useState({
    title: "",
    content: "",
    file: "",
    expires_at: "",
    teacher_id: "",
    student_id: null,
    created_by: "",
    updated_by: "",
    class_id: null,
    strand_id: str.strand_id,
  });

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStrandStudents = async () => {
    try {
      const res = await getDatas(
        `/teacher-api/strand/students/${str.strand_id}`,
        setError
      );
      if (res && res.response) {
        const datas = res.response;
        const arrEmails = datas.map((dat) => dat.emails);

        setStudentEmails(arrEmails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
    if (str && str.strand_id) {
      fetchStrandStudents();
    }
  }, [user]);

  const createAnnouncement = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    const { title, content, expires_at, file } = values;
    if (!title || !content || !expires_at) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }
    const newAnnouncement = {
      ...values,
      teacher_id: user.teacher_id,
      created_by: `${user.fname} ${user.lname}`,
      updated_by: `${user.fname} ${user.lname}`,
    };
    if (file) {
      try {
        const { url } = await getDatas(
          `/getS3Access/announcements/strands/${file.name}`,
          setError
        );
        if (url) {
          await uploadToS3(url, file);
          newAnnouncement.file = url.split("?")[0];
        }
      } catch (error) {
        console.log(error);
      }
    }

    const res = await postDatas(
      `/teacher-api/announcements/create`,
      newAnnouncement,
      setError
    );
    if (res && res.response) {
      setActive(1);
      setMessage(res.response);

      const newDatas = {
        from: user?.email,
        email: studentEmails,
        subject: `${str?.year + " " + str?.strand} Strand Announcement Posted`,
        text: `Instructor ${user?.email} posted an announcement for your strand, please check your Trackademy Archive`,
      };
      await postDatas(`/teacher-api/mailer`, newDatas, setError);
    } else {
      setActive(3);
      setMessage(res.error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setValues({ ...values, file });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        <span>Strand Announcement</span>
        <button onClick={closeModal}>Close</button>
      </div>

      <form className={s.form} onSubmit={createAnnouncement}>
        <input
          type="text"
          name="to"
          placeholder="to "
          className={s.input}
          value={`To: ${str.year} - ${str.strand}`}
          required
          readOnly
        />
        <input
          type="text"
          name="title"
          placeholder="Subject "
          className={s.input}
          onChange={handleChange}
          required
        />
        <textarea
          name="content"
          placeholder="Message"
          className={s.textarea}
          onChange={handleChange}
          required
        />
        <span className={s.lastSection}>
          <div className={s.fileInputWrapper}>
            <input
              type="file"
              name="file"
              className={s.hiddenInput}
              onChange={handleFileChange}
              id="fileInput"
            />
            <label htmlFor="fileInput" className={s.clipButton}>
              {icons.clipFill}
            </label>
          </div>
          <input
            type="datetime-local"
            name="expires_at"
            className={s.expires}
            onChange={handleChange}
            id="dateInput"
            required
          />

          <div className={s.buttons}>
            <button type="submit" className={s.submitButton}>
              Create {icons.sendFill}
            </button>
          </div>
        </span>
      </form>
    </div>
  );
}

export default Announce;
