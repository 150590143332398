// A.js
export function setLetterH(fp) {
    const letterH = new fp.GestureDescription("H");
    // letterH.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    // letterH.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 0.4);

    letterH.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
    
    letterH.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);

    // letterH.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);
    // letterH.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

    // letterH.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
    // letterH.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);/

  
    // letterH.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalLeft, 0.4);
    letterH.addDirection(fp.Finger.Index, fp.FingerDirection.HorizontalLeft, 0.6);
    letterH.addDirection(fp.Finger.Middle, fp.FingerDirection.HorizontalLeft, 0.6);
    // letterH.addDirection(fp.Finger.Ring, fp.FingerDirection.HorizontalLeft, 0.4);
    // letterH.addDirection(fp.Finger.Pinky, fp.FingerDirection.HorizontalLeft, 0.4);
    return letterH;
  }
  