import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// icons
import { icons } from "../../../../utilities/icons";
// contexts
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { RoleContext } from "../../../../contexts/RoleContext";
// styles
import s from "./css/login.module.css";
import sample from "../../../../assets/wait1.gif";
// components
import { postDatas } from "../../../../services/api";

function Login() {
  const navigate = useNavigate();
  const { globalStyles } = useContext(GlobalStyleContext);
  const [isDatasWrong, setIsDatasWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { status, setStatus } = useContext(RoleContext);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    setError(null);
    e.preventDefault();
    setIsLoading(true);

    const url = "/student-api/authentication/login";
    const datas = await postDatas(url, values, setError);

    if (datas?.response && datas?.token) {
      const user = datas.response;
      console.log(datas);

      localStorage.setItem("token", datas.token);

      setStatus(true);
      localStorage.setItem("isLoggedIn", true);

      if (user?.status === "Pending") {
        const logoutUrl = "/student-api/authentication/logout";
        const res = await postDatas(logoutUrl, {}, setError);
        if (res) {
            navigate("/pending");
        }
      } else {
          navigate("/student/main");
      }
    } else {
      setIsDatasWrong(true);
      console.log(error);
    }

    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <div
        className={`${s.container}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div className={s.loginCard} style={{ backgroundColor: globalStyles }}>
          {/* left */}
          <div
            className={s.formSection}
            style={{ backgroundColor: globalStyles.rtertiary }}
          >
            <div className={s.header}>
              <span className={s.title} style={{ color: globalStyles.primary }}>
                Student Login Page
              </span>
              <span
                className={s.subtitle}
                style={{ color: globalStyles.secondary }}
              >
                Please login to access your account
              </span>
            </div>
            <form onSubmit={handleSubmit} className={s.loginForm}>
              <div className={s.inputGroup}>
                <input
                  className={s.input}
                  type="text"
                  name="email"
                  placeholder="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
                <input
                  className={s.input}
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  required
                />
                {isDatasWrong && (
                  <p>{icons.warnLine}incorrect email or password</p>
                )}
              </div>

              {/* remember me */}
              <div className={s.rememberMe}>
                <span className={s.checkRemember}></span>
                <span className={s.forgot}>
                  <Link
                    to="/student/forgot"
                    className={s.forgot}
                    style={{ marginLeft: "auto" }}
                  >
                    Forgot password?
                  </Link>
                </span>
              </div>
              {/* buttons */}
              <div className={s.buttons}>
                <button
                  type="submit"
                  className={s.button}
                  style={{
                    backgroundColor: globalStyles.button,
                    color: globalStyles.buttonText,
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Log In"}
                </button>
              </div>
            </form>
          </div>

          {/* right  */}
          <div className={s.illustrationSection}>
            <div className={s.illustration}>
              <img src={sample} alt="Illustration" className={s.image} />
            </div>
          </div>
        </div>
        <Link
          to="/"
          className={s.backButton}
          style={{
            backgroundColor: globalStyles.button,
            color: globalStyles.buttonText,
          }}
        >
          <span className={s.backButtonIcon}>{icons.backFill}</span>
          Back
        </Link>
      </div>
    </>
  );
}

export default Login;
