const words = [
    'approved',
    'clap',
    'click',
    'coffee',
    'correct',
    'disapproved',
    'eyyy', 
    "good",
    "hi",
    'love',
    'okay',
    'peace',
    'praise',
    'pray',
    'right',
    'save',
    'thanks',
    'up',
    'write',
    'wrong',
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'joke',
    'kidding',
    'yehey',
    'no',
    'dont',
    'hello'

]

const phrase = [
    'i love you',
    'thank you',
    'nice to meet you',
    'do not',
    'wash hands',
    'wash hand',
    'call me',
    'fuck you',
    'get your pencils',
    'get your phones',
    'lend me a hand',
    'lets be friends',
    'more love',
    'take a picture',
    'thank you',
] 
export {words, phrase};
