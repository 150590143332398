import { right } from "./letters-right";
import { left } from "./letters-left";
import * as fp from "fingerpose";

export class GestureAllocator {
  constructor(dominantHand) {
    const gestures = dominantHand === "Right" ? right : left;

    this.letterA = gestures.A(fp);
    this.letterB = gestures.B(fp);
    this.letterC = gestures.C(fp);
    this.letterD = gestures.D(fp);
    this.letterE = gestures.E(fp);
    this.letterF = gestures.F(fp);
    this.letterG = gestures.G(fp);
    this.letterH = gestures.H(fp);
    this.letterI = gestures.I(fp);
    this.letterJ = gestures.J(fp);
    this.letterK = gestures.K(fp);
    this.letterL = gestures.L(fp);
    this.letterM = gestures.M(fp);
    this.letterN = gestures.N(fp);
    this.letterO = gestures.O(fp);
    this.letterP = gestures.P(fp);
    this.letterQ = gestures.Q(fp);
    this.letterR = gestures.R(fp);
    this.letterS = gestures.S(fp);
    this.letterT = gestures.T(fp);
    this.letterU = gestures.U(fp);
    this.letterV = gestures.V(fp);
    this.letterW = gestures.W(fp);
    this.letterX = gestures.X(fp);
    this.letterY = gestures.Y(fp);
    this.letterZ = gestures.Z(fp);
    this.Iloveyou = gestures.Iloveyou(fp);
    this.space = gestures.Space(fp);
    this.deleteLetter = gestures.Delete(fp);
  }

  // Method to return all allocated gestures as an array
  getAllGestures() {
    return [
      this.letterA,
      this.letterB,
      this.letterC,
      this.letterD,
      this.letterE,
      this.letterF,
      this.letterG,
      this.letterH,
      this.letterI,
      this.letterJ,
      this.letterK,
      this.letterL,
      this.letterM,
      this.letterN,
      this.letterO,
      this.letterP,
      this.letterQ,
      this.letterR,
      this.letterS,
      this.letterT,
      this.letterU,
      this.letterV,
      this.letterW,
      this.letterX,
      this.letterY,
      this.letterZ,
      this.space,
      this.Iloveyou,
      this.deleteLetter
    ];
  }
}
