// A.js
export function setLetterU(fp) {
  const letterU = new fp.GestureDescription("U");

  // U

  letterU.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterU.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterU.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  letterU.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterU.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  letterU.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 0.8);

  letterU.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 0.8);
  letterU.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpLeft, 0.3);

  // letterU.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpRight, 1.0);
  // letterU.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 0.8);
  return letterU;
}
