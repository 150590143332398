import React, {  useRef, useState } from "react";
import { icons } from "../../../../../../utilities/icons";
import style from "../../../../components/css/buttons.module.css";
import s from "./css/top.module.css";
import { cleanString } from "../../../../../../utilities/methods";

function Top({ studentsCopy, setStudents }) {
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(""); 

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    if (value.trim() === "") {
      setStudents(studentsCopy);
    } else {
      setStudents(studentsCopy.filter((stud) =>
        cleanString(stud.fname + stud.lname).includes(cleanString(value))
      ));
    }
  };

  return (
    <div className={s.parent}>
      <div className={s.right}>
        <input
          type="search"
          placeholder="Search..."
          className={style.search}
          ref={searchRef}
          value={searchQuery}
          onChange={handleSearch}
        ></input>
   
        <button className={s.button}>
          {icons.searchFill}
        </button> 
      </div>
    </div>
  );
}

export default Top;
