export function setLetterX(fp) {
  const letterX = new fp.GestureDescription("X");

  letterX.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl,1.0);
  letterX.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl,0.8);
  letterX.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl,0.4);
  letterX.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterX.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterX.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  // letterX.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp, 0.5);
  letterX.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpRight, 0.5);
  letterX.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpRight, 0.5);
  letterX.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpRight, 0.5);
  letterX.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpRight, 0.5);

  return letterX;
}
