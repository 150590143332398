import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
// icons
import { FaSearch } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import s from "./top.module.css";
// methods
import {
  handleToggler,
} from "../../../../../../../utilities/methods";
import UploadContent from "../../../modules/modals/UploadContent";
import UploadMaterial from "../../../modules/modals/UploadMaterial";

function Top({ openModal, contentSearchQuery, setContentSearchQuery }) {
  const searchRef = useRef(null);
  const { strand_id, strand_title } = useParams();
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false);
  const [isButtonMenuOpen, setIsButtonMenuOpen] = useState(false);

  const toggleSearch = () => {
    setIsButtonMenuOpen(false);
    handleToggler(isSearchbarVisible, setIsSearchbarVisible);
  };

  const toggleMenu = () => {
    setIsSearchbarVisible(false);
    handleToggler(isButtonMenuOpen, setIsButtonMenuOpen);
  };

  return (
    <div className={s.parent}>
      <Link
        to={`/teacher/strand/${strand_id}/${strand_title}/classes`}
        className={s.backButton}
      >
        Classes
      </Link>
      <div className={s.right}>
        {isSearchbarVisible && (
          <input
            type="search"
            placeholder="Search..."
            className={s.search}
            ref={searchRef}
            value={contentSearchQuery}
            onChange={(e) => setContentSearchQuery(e.target.value)}
          ></input>
        )}

        <button className={`${s.button} `} onClick={() => toggleSearch()}>
          <FaSearch />
        </button>
        <button className={s.add} onClick={() => toggleMenu()}>
          <IoMdAdd />
        </button>
        {isButtonMenuOpen && (
          <span className={s.menuButtons}>
            <button onClick={(e) => openModal(UploadContent, "", e)}>
              Upload Contents
            </button>
            <button onClick={(e) => openModal(UploadMaterial, "", e)}>
              Upload Materials
            </button>
          </span>
        )}
      </div>
    </div>
  );
}

export default Top;
