export function setLetterG(fp) {
  const letterG = new fp.GestureDescription("G");

  // Thumb - Extended, but allow a slight curl for flexibility
  letterG.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);

  // Index - Mostly extended, slight curl allowed
  letterG.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  // letterG.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 0.4);

  // Middle - Fully curled or half curled 
  letterG.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  // letterG.addCurl(fp.Finger.Middle, fp.FingerCurl.HalfCurl, 0.4); 

  // Ring and Pinky - Fully curled
  letterG.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterG.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  // Thumb Direction - More flexible directions allowed
  // letterG.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalRight, 0.4);
  letterG.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalRight, 1.0);

  // // Index Direction - Same flexibility
  letterG.addDirection(fp.Finger.Index, fp.FingerDirection.HorizontalRight, 1.0);
  letterG.addDirection(fp.Finger.Middle, fp.FingerDirection.HorizontalRight, 0.5);



  return letterG;
}
