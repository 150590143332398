import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { RoleContext } from "./RoleContext";
import { getDatas } from "../services/api";

const ProtectedRoute = ({ children, role }) => {
  const { userRole, loading: contextLoading } = useContext(RoleContext); 
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
        console.log("Got user data from backend:", res.user);
      } else {
        console.error("No user data received.");
        navigate("/unauthorized");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    // console.log("Required role:", role, "User role from context:", userRole);
    requestUserData();
  }, []);

  if (loading || contextLoading) {
    return <div>Loading...</div>;
  }

  if (userRole !== role) {
    console.log(`Role mismatch: userRole=${userRole}, requiredRole=${role}`);
    return <Navigate to="/unauthorized" />;
  }

  if (user?.enrollment_status === "Pending") {
    console.log("Redirecting due to pending enrollment status");
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
