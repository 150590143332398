import React, { useState } from "react";
// style
import style from "../css/update.module.css";
import s from '../../../components/style_configs/buttons.module.css'
//methods
import { updateData } from "../../../../../utilities/methods";
// componentsks
import Popup from "../../../components/Popup/Popup";
import { putDatas } from "../../../../../services/api";
function ChangePassword({ user, closeModal, }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    password: "",
    confirm: "",
    teacher_id: user.teacher_id
  });

  // ***************************************************
  // handle Student Information Editing
  // ***************************************************
  const handleSubmit = async (e) => {
    const { password, confirm } = values;

    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    if (!password || !confirm) {
      setActive(2);
      setMessage("Please Fill up empty fields");
      return;
    }

    if (password !== confirm) {
      setActive(2);
      setMessage("Password did not match");
      return;
    }

    const url = `/teacher-api/authentication/forgot`;
    const res = await putDatas(url, values, setError);

    if (res.response) {
      setActive(1);
      setMessage(res.response);
    } else {
      setActive(3);
      setMessage(res.error);
    }
  };

  // ***************************************************
  // handle preview image || input change || page reload
  // ***************************************************
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Popup message={message} active={active} />

      <div className={`${style.container}`}>
        <div className={style.loginCard}>
          <div className={style.formSection}>
            <div className={style.firstSection}>
              Change Password
              <button className={s.button} onClick={closeModal}>
                Back
              </button>
            </div>
            <form onSubmit={handleSubmit} className={style.form}>
              <div className={style.name}>
                <input
                  className={style.input}
                  type="text"
                  name="password"
                  placeholder="Enter New Password"
                  value={values.description}
                  onChange={handleChange}
                  required
                />
                <input
                  className={style.input}
                  type="text"
                  name="confirm"
                  placeholder="Confirm Password"
                  value={values.contact}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className={style.button}>
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
