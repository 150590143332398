import React, { useState, useEffect } from "react";
import s from "../css/parts.module.css";
import { icons } from "../../../../../utilities/icons";
import Mail from "../../../components/Mail_layout/Mail";
import JoinRoom from "../modals/JoinRoom"; 
import Default from "../../../../defaults/Default/Default";

function AvailableClasses(props) {
  const { classes, user, fetchStudentClasses } = props;
  const [available, setAvailable] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const filteredClasses = classes.filter((cl) => cl.participant_id === null);
    setAvailable(filteredClasses);
  }, [classes]);

  const filteredClasses = available.filter((cl) =>
    cl.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (cl) => {
    setModalContent(null);
    setModalContent(
      <JoinRoom
        datas={cl}
        student_id={user?.student_id}
        setIsModalOpen={setIsModalOpen}
        fetchStudentClasses={fetchStudentClasses}
      />
    );
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={s.availableClassContainer}>
        {available.length === 0 ? (
          <Default active={2} />
        ) : (
          <>
            <div className={s.avalableClassHeader}>
              <span className={s.avalableClassTitle}>
                {icons.classLine} Available Classes
              </span>
              <span className={s.avalableClassButtons}>
                <input
                  type="search"
                  placeholder="Title Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <button>{icons.filterFill}Filter</button> */}
              </span>
            </div>
            {filteredClasses.length > 0 ? (
              <div className={s.availableClassWrapper}>
                {filteredClasses.map((cl, i) => (
                  <div className={`${s.classCard} ${s.fadeIn}`} key={i}>
                    <span
                      className={s.classCardBanner}
                      style={{ backgroundColor: cl.color }}
                    >
                      {icons.gradFill}
                    </span>
                    <div
                      className={s.classCardFirstSection}
                      style={{ backgroundColor: cl.color }}
                    >
                      <p className={s.classCardTitle}>{cl.title}</p>
                      <p className={s.classCardDesc}>
                        Section: <b>{cl.description}</b>
                      </p>
                      <div className={s.classCardTeacherInfo}>
                        <img
                          src={cl?.image}
                          className={s.classCardTeacherImage}
                          alt="profile"
                        />
                        <p>
                          <b>Teacher: </b>
                          {cl?.name}
                        </p>
                      </div>
                    </div>
                    <span className={s.classCardButtons}>
                      <button
                        className={s.button}
                        style={{ backgroundColor: cl.color }}
                        onClick={() => openModal(cl)}
                      >
                        {icons.classLine} Join
                      </button>
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <Default active={2}/>
            )}
          </>
        )}
      </div>

      {isModalOpen && <Mail>{modalContent}</Mail>}
    </>
  );
}

export default AvailableClasses;
