import React, { useState, useContext } from "react";
// styles
import style from "../../../../../components/css/buttons.module.css";
import s from "./style.module.css";
import f from "../../../../../components/css/forms.module.css";
// methods 
import { postData } from "../../../../../../../utilities/methods";
// user data provvider context
import { UserDataContext } from "../../../../../../../contexts/UserDataProvider";

function Email( { closeModal }) {
  const { userData } = useContext(UserDataContext);
  const [error, setError] = useState([]);
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({
    from: userData.email,
    to: "",
    subject: "",
    content: "",
  });

  const doSubmit = async () => {
    const url = `/admin-api/mailer`;
    const res = await postData(url, values, setError);
    if (res) {
      setMessage(res.message);
      alert(res.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <button className={style.modalButton} onClick={closeModal}>
        Back
      </button>
      <div className={style.header}>SEND EMAIL</div>
      <div className={s.emailParent}>
        <form onSubmit={doSubmit}>
          <span className={f.biInputGroup}>
            <label>To:</label>
            <input
              className={s.input}
              type="email"
              onChange={handleChange}
              name="to"
              placeholder="Enter Recipient..."
            ></input>
          </span>
          <span>
            <label>Subject:</label>'
            <textarea
              className={f.textarea}
              type="text"
              onChange={handleChange}
              name="subject"
              placeholder="Enter email subject..."
            ></textarea>
          </span>
          <span>
            <label>Content:</label>
            <input
              className={s.input}
              type="text"
              onChange={handleChange}
              name="content"
              placeholder="Enter email content..."
            ></input>
          </span>
          <button type="submit" className={style.button}>Send Email</button>
        </form>
      </div>
    </>
  );
}

export default Email;
