import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// icons
import { icons } from "../../../../utilities/icons";
// contexts
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { RoleContext } from "../../../../contexts/RoleContext";
// styles
import styles from "./css/login.module.css";
import sample from "../../../../assets/lms.gif";
// components
import { postDatas } from "../../../../services/api";

function Login() {
  const navigate = useNavigate();
  const { globalStyles } = useContext(GlobalStyleContext);
  const { status, setStatus } = useContext(RoleContext);

  const [values, setValues] = useState({ email: "", password: "", secret_key: "" });
  const [isDatasWrong, setIsDatasWrong] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setIsDatasWrong(false);

    try {
      const url = "/admin-api/post/login";
      const datas = await postDatas(url, values, setError);

      if (datas?.response && datas.token) {
        localStorage.setItem("token", datas.token);
        localStorage.setItem("isLoggedIn", true);
        setStatus(true);
        navigate("/admin/dashboard");
      } else {
        setIsDatasWrong(true);
      }
    } catch (err) {
      console.error("Login Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <div className={styles.container} style={{ backgroundColor: globalStyles.bg2 }}>
      <div className={styles.loginCard} style={{ backgroundColor: globalStyles.bg1 }}>
        {/* Left Section */}
        <div className={styles.formSection} style={{ backgroundColor: globalStyles.rtertiary }}>
          <h1 className={styles.title} style={{ color: globalStyles.t1 }}>
            Welcome back Admin!
          </h1>
          <p className={styles.subtitle} style={{ color: globalStyles.secondary }}>
            Please login to access your admin account
          </p>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              {["email", "password", "secret_key"].map((field) => (
                <input
                  key={field}
                  className={styles.input}
                  type={field === "email" ? "text" : "password"}
                  name={field}
                  placeholder={field.replace("_", " ")}
                  value={values[field]}
                  onChange={handleChange}
                  required
                />
              ))}
              {isDatasWrong && (
                <p className={styles.error}>
                  {icons.warnLine} Incorrect password or key
                </p>
              )}
            </div>
            {/* Remember Me & Forgot Password */}
            <div className={styles.rememberMe}>
              <label className={styles.checkRemember}>
                <input type="checkbox" id="rememberMe" className={styles.checkbox} />
                <span style={{ color: globalStyles.secondary }}>Remember me</span>
              </label>
              <Link to="/forgot" className={styles.link} style={{ color: globalStyles.t1 }}>
                Forgot password?
              </Link>
            </div>
            {/* Submit Button */}
            <button
              type="submit"
              className={styles.button}
              style={{ backgroundColor: globalStyles.button, color: globalStyles.buttonText }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Log In"}
            </button>
          </form>
        </div>
        {/* Right Section */}
        <div className={styles.illustrationSection}>
          <img src={sample} alt="Illustration" className={styles.image} />
        </div>
      </div>
      {/* Back Button */}
      <Link
        to="/"
        className={styles.backButton}
        style={{
          backgroundColor: globalStyles.button,
          color: globalStyles.buttonText,
        }}
      >
        <span className={styles.backButtonIcon}>{icons.backFill}</span>
        Back
      </Link>
    </div>
  );
}

export default Login;
