const apiService = async (url, method = "GET", data = null, setError) => {
  setError("");
  const apiURL = `${process.env.REACT_APP_API_URL + url}`;
  console.log("api url: ", apiURL);

  try {
    const token = localStorage.getItem("token");  

    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": token ? `Bearer ${token}` : "", 
      },
      credentials: "include",
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    const res = await fetch(apiURL, options);

    if (!res.ok) {
      const errorData = await res.json();
      setError(errorData.error || "An error occurred");
      return null;
    }

    if (res.status === 204) {
      setError("Data not Found");
      return null;
    }

    const transformedData = await res.json();
    return transformedData;
  } catch (error) {
    setError(error.message || "An unexpected error occurred");
    console.log(error);
    return null;
  }
};


// CRUD functions
export const getDatas = (url, setError) =>
  apiService(url, "GET", null, setError);
export const postDatas = (url, data, setError) =>
  apiService(url, "POST", data, setError);
export const putDatas = (url, data, setError) =>
  apiService(url, "PUT", data, setError);
export const deleteDatas = (url, data, setError) =>
  apiService(url, "DELETE", data, setError);

export const uploadToS3 = async (s3URL, file) => {
  await fetch(s3URL, {
    method: "PUT",
    header: {
      "Content-Type": "multipart/form-data",
    },
    body: file,
  });
};
