import React, { useContext } from "react";
// context
import { GlobalStyleContext } from "../../../../../components/other/styles/globalcss";
// styles
import styles from "../css/items.module.css";

function Item({ info, filterTools }) {
  const { globalStyles } = useContext(GlobalStyleContext);

  const handleClick = (id) => {
    filterTools(id);
  };

  return (
    <li className={styles.sidebarList}>
      <button
        style={{
          background: globalStyles.button,
          color: globalStyles.buttonText,
        }}
        className={styles.sidebarButton}
        onClick={() => handleClick(info.id)}
      >
        {info.name}
      </button>
    </li>
  );
}

export default Item;
