// A.js
export function setLetterF(fp) {
  const letterF = new fp.GestureDescription("F");

  // Gesture F: 2 Nocurl 3 fullCurl
  letterF.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterF.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterF.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
  letterF.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
  letterF.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);
 
  // letterF.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpLeft, 1.0);
  letterF.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp, 1.0);
  // letterF.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 1.0);
  letterF.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 1.0);
  // letterF.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);
  // letterF.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 1.0);
  // letterF.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 1.0);

  return letterF;
}
