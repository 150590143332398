import React from "react";
import s from "./privacy.module.css";
import Footer from "../../../components/Footer/Footer";

import bg from "../../../assets/whity.jpg";

function Privacy() {
  return (
    <>
      {/* Background Container */}
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>

      <section className={s.privacyContainer}>
        <h1>Privacy Policy</h1>
        <p>Last updated: [Date]</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to [Your Company Name]. We are committed to protecting your
          personal data and respecting your privacy. This Privacy Policy
          explains how we collect, use, and disclose your information.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect information that you provide to us directly and
          automatically as you use our services. This may include:
        </p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, and
            contact information.
          </li>
          <li>
            <strong>Usage Data:</strong> Information on how you use our website,
            including IP address, browser type, and pages visited.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use the collected information for purposes including:</p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Communicating with you about updates or support</li>
          <li>Personalizing your experience on our website</li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We may share your information with third-party service providers who
          assist us in operating our website and conducting business. We ensure
          these parties adhere to strict confidentiality obligations.
        </p>

        <h2>5. Security of Your Information</h2>
        <p>
          We take appropriate security measures to protect your personal data.
          However, no transmission over the internet or method of storage is
          entirely secure, so we cannot guarantee absolute security.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal
          information. If you would like to exercise any of these rights, please
          contact us at [Your Contact Information].
        </p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Changes will be
          posted on this page with an updated date at the top.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please{" "}
          <a href="/contact" className={s.contactLink}>
            contact us
          </a>
          .
        </p>
      </section>
      <Footer />

    </>
  );
}

export default Privacy;
