import { recognizeASL } from "./recognizeGestures";
export const detectGestures = async (
  model,
  gestureEstimator,
  videoRef,
  canvasRef,
  setDetectedLetter
) => {
  try {
    const hands = await model.estimateHands(videoRef.current);
    const context = canvasRef.current.getContext("2d");

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    if (hands.length > 0) {
      const topLeft = hands[0].boundingBox.topLeft;
      const bottomRight = hands[0].boundingBox.bottomRight;

      // Scale the bounding box coordinates to fit the canvas
      const scaleFactorX =
        canvasRef.current.width / videoRef.current.videoWidth;
      const scaleFactorY =
        canvasRef.current.height / videoRef.current.videoHeight;
      const scaledTopLeft = [
        topLeft[0] * scaleFactorX,
        topLeft[1] * scaleFactorY,
      ];
      const scaledBottomRight = [
        bottomRight[0] * scaleFactorX,
        bottomRight[1] * scaleFactorY,
      ];

      // Draw bounding box around the detected hand
      context.strokeStyle = "yellow";
      context.lineWidth = 2;
      context.strokeRect(
        scaledBottomRight[0],
        scaledBottomRight[1],
        scaledTopLeft[0] - scaledBottomRight[0],
        scaledTopLeft[1] - scaledBottomRight[1]
      );

      // Draw lines connecting landmarks of each finger
      hands.forEach((prediction) => {
        const landmarks = prediction.landmarks.map(([x, y]) => [
          x * scaleFactorX,
          y * scaleFactorY,
        ]);

        const connections = [
          [0, 1],
          [1, 2],
          [2, 3],
          [3, 4], // Thumb
          [0, 5],
          [5, 6],
          [6, 7],
          [7, 8], // Index Finger
          [0, 9],
          [9, 10],
          [10, 11],
          [11, 12], // Middle Finger
          [0, 13],
          [13, 14],
          [14, 15],
          [15, 16], // Ring Finger
          [0, 17],
          [17, 18],
          [18, 19],
          [19, 20], // Pinky
          [5, 9],
          [9, 13],
          [13, 17], // boundary
        ];

        connections.forEach(([startIdx, endIdx]) => {
          const startPoint = landmarks[startIdx];
          const endPoint = landmarks[endIdx];

          context.strokeStyle = "yellow";
          context.lineWidth = 2;
          context.beginPath();
          context.moveTo(startPoint[0], startPoint[1]);
          context.lineTo(endPoint[0], endPoint[1]);
          context.stroke();
        });

        // Draw hand dot keypoints
        landmarks.forEach(([x, y]) => {
          context.beginPath();
          context.arc(x, y, 3, 0, Math.PI * 2, false);
          context.fillStyle = "green";
          context.fill();
        });
      });

      recognizeASL(hands, gestureEstimator, setDetectedLetter);
    } else {
      setDetectedLetter("");
    }
    requestAnimationFrame(() =>
      detectGestures(
        model,
        gestureEstimator,
        videoRef,
        canvasRef,
        setDetectedLetter
      )
    );
  } catch (error) {
    console.log(error);
  }
};
