import React from "react";
import { Routes, Route } from "react-router-dom";

import TeacherLogin from "../pages/Teacher/pages/Authentication/Login";
import TeacherSignup from "../pages/Teacher/pages/Authentication/Signup";
import TeacherForgot from "../pages/Teacher/pages/Authentication/Forgot";
import TeacherDashboard from "../pages/Teacher/pages/Dashboard/Dashboard";
import TeacherTeaching from "../pages/Teacher/pages/Teaching/strands/Teaching";
import TeacherScoreboard from "../pages/Teacher/pages/Classworks/Classwork";
import TeacherArchive from "../pages/Teacher/pages/Announcements/Announcements";
import TeacherSettings from "../pages/Teacher/pages/Settings/Settings";
import TeacherClass from "../pages/Teacher/pages/Teaching/classes/Classes";
import TeacherModule from "../pages/Teacher/pages/Teaching/modules/Modules";
import TeacherStrandProgress from "../pages/Teacher/pages/Dashboard/modals/progress/Progress";
import ProtectedRoute from "../contexts/ProtectedRoute";

export default function TeacherRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<TeacherLogin />} />
      <Route path="/forgot" element={<TeacherForgot />} />
      <Route path="/signup" element={<TeacherSignup />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/strand"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherTeaching />
          </ProtectedRoute>
        }
      />
      <Route
        path="/archive"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherArchive />
          </ProtectedRoute>
        }
      />
      <Route
        path="/scoreboard"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherScoreboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherSettings />
          </ProtectedRoute>
        }
      />

      {/* ************** asssessment progress navigations ************** s */}
      <Route
        pat
        h="/dashboard/progress"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherStrandProgress />
          </ProtectedRoute>
        }
      />

      {/* ************** teaching navigations ************** */}

      <Route
        path="/strand/:strand_id/:strand_title/classes"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherClass />
          </ProtectedRoute>
        }
      />
      <Route
        path="/strand/:strand_id/:strand_title/classes/:class_id/:subject/:section/modules"
        element={
          <ProtectedRoute role="teacher" access="public">
            <TeacherModule />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
