// A.js
export function setLetterD(fp) {
  const letterD = new fp.GestureDescription("D");

  // Gesture D: Index finger extended, other fingers curled
  letterD.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);

  letterD.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  letterD.addCurl(fp.Finger.Middle, fp.FingerCurl.HalfCurl, 1.0);
  letterD.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 0.4);
  letterD.addCurl(fp.Finger.Ring, fp.FingerCurl.HalfCurl, 1.0);
  letterD.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 0.4);
  letterD.addCurl(fp.Finger.Pinky, fp.FingerCurl.HalfCurl, 1.0);


  letterD.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.4);
  letterD.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 1.0);
  letterD.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpLeft, 1.0);


  return letterD;
}
