import React, {useRef, useState } from "react";
// icons
import { icons } from "../../../../../../utilities/icons";
// style
import s from "./css/top.module.css";
// methods
import { cleanString } from "../../../../../../utilities/methods";
function Top({ strandsCopy, setStrands}) {
  const searchRef = useRef(null); 
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    if (value.trim() === "") {
      setStrands(strandsCopy);
    } else {
      setStrands(strandsCopy.filter((str) =>
        cleanString(str.title).includes(cleanString(value))
      ));
    }
  };


  return (
    <div className={s.parent}>
      <span className = {s.left}>Account Verification</span>
      <div className={s.right}>
     
        <input
          type="search"
          placeholder="Search..."
          className={s.search}
          ref={searchRef}
          value={searchQuery}
          onChange={handleSearch}
        ></input>
        <span className={s.icon} >
          {icons.searchFill}
        </span> 
      </div>
    </div>
  );
}

export default Top;
