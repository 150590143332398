import React, { useEffect, useState } from "react";
// styles
import s from "./style.module.css";
// components
import Popup from "../../../../components/Popup/Popup";
import { getDatas, postDatas } from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { randomCode } from "./../../../../../../utilities/methods";
import { icons } from "../../../../../../utilities/icons";

function Add({ teacher_id, strand_id, closeModal }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [values, setValues] = useState({
    title: "", // subject
    description: "", // for section
    color: "", // design
    code: "", // class password
    created_by: "",
    updated_by: "",
    strand_id: strand_id,
    teacher_id: teacher_id,
  });

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const createClass = async (e) => {
    const { title, description, code, color } = values;
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    values.title = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
    values.description =
      description.charAt(0).toUpperCase() + description.slice(1).toLowerCase();

    if (!title || !description || !code || !color) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }

    const url = `/teacher-api/classes/create`;
    const newClass = {
      ...values,
      created_by: `${user.fname} ${user.lname}`,
      updated_by: `${user.fname} ${user.lname}`,
    };
    const res = await postDatas(url, newClass, setError);

    if (res && res.response) {
      setActive(1);
      setMessage(res.response);
    } else {
      setActive(3);
      setMessage(res.error);
    }
  };

  const setCode = () => {
    const code = randomCode();
    if (code) {
      setValues({ ...values, code });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        Create Class<button onClick={closeModal}>Close</button>
      </div>
      <form className={s.form} onSubmit={createClass}>
        <input
          type="text"
          name="title"
          placeholder="Subject Title"
          className={s.input}
          onChange={handleChange}
          required
        ></input>
        <input
          type="text"
          name="description"
          placeholder="Section"
          className={s.textarea}
          onChange={handleChange}
          required
        ></input>
        <span>
          <input
            type="text"
            name="code"
            placeholder="Code"
            value={values.code}
            className={s.textarea}
            onChange={handleChange}
            required
          ></input>
          <button
            type="button"
            className={s.randomCodeButton}
            onClick={setCode}
          >
            Generate
          </button>
        </span>
        <span>
          <select name="color" onChange={handleChange} required>
            <option value="rgb(137, 4, 4)">red</option>
            <option value="#242629">dark</option>
            <option value="rgb(168, 168, 5)">yellow</option>
            <option value="rgb(15, 15, 96)">blue</option>
            <option value="rgb(9, 90, 9)">green</option>
            <option value="rgb(145, 94, 1)">orange</option>
            <option value="rgb(90, 12, 90)">violet</option>
          </select>
          <div
            className={s.colorPreview}
            style={{ backgroundColor: values.color }}
          ></div>
        </span>

        <div className={s.buttons}>
          <button type="submit" className={s.submitButton}>
            Create
            {icons.sendFill}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Add;
