import React, { useState } from "react";
import s from "./css/header.module.css";
import Button from "../../../../components/custom/button";
import { handleToggler } from "../../../../utilities/methods";
import logo from '../../../../assets/logo.jpg'
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [isThemeDark, setIsThemeDark] = useState(true);

  return (
    <header className={s.header}>
      <div className={s.logoInfo}>
        <img src = {logo} className = {s.logo} alt = "logo"></img>
        <span className = {s.title}>Trackademy</span>
      </div>
     
      <div className={s.buttonContainer}>
        <Button
          type="button"
          action={() => window.location.href = "/signify"}
        >
          Access Signify &rarr;
        </Button>
      </div>
    </header>
  );
}

export default Header;
