import { calculateDistance, calculateGap, normalizeDistance } from "./methods";
let arrComplicated_COD = ["C", "O", "D"];
let arrComplicated_MNSA = ["M", "N", "S", "E", "A"];
let arrComplicated_RUVW = ["R", "U", "V", "W"];
let arrComplicated_IYJ = ["I", "Y", "J"];
let arrComplicated_BF = ["B", "F"];
let arrComplicated_HK = ["H", "K"];
let arrComplicated_QGXT = ["Q", "G", "X", "T"];
let arrComplicated_L = ["L"];
//hands, gestureEstimator,detectedLetter,setDetectedLetter
export const recognizeASL = (hands, gestureEstimator,setDetectedLetter) => {
    const est = gestureEstimator.estimate(hands[0].landmarks, 7.5);
    if (est.gestures.length > 0) {
      const maxConfidence = est.gestures.reduce((prev, curr) =>
        prev.confidence > curr.confidence ? prev : curr
      );

      let detected = maxConfidence.name.trim().toUpperCase();

      let thumbTip = hands[0].landmarks[4];
      let indexTip = hands[0].landmarks[8];
      let middleTip = hands[0].landmarks[12];
      let indexMid = hands[0].landmarks[7];
      let middleMid = hands[0].landmarks[11];
      let palm = hands[0].landmarks[0];
      let ringTip = hands[0].landmarks[16];
      let pinkyTip = hands[0].landmarks[20];

      // bondingbox
      const topLeft = hands[0].boundingBox.topLeft;
      const bottomRight = hands[0].boundingBox.bottomRight;
      const topRight = { 0: bottomRight[0], 1: topLeft[1], 2: 0 };
      const bottomLeft = { 0: topLeft[0], 1: bottomRight[1], 2: 0 };

      const topLeftX = topLeft[0];
      const topLeftY = topLeft[1];
      const botRightX = bottomRight[0];
      const botRightY = bottomRight[1];
      const boundingBoxWidth = botRightX - topLeftX;
      const boundingBoxHeight = botRightY - topLeftY;
      const boxAreaSize = boundingBoxHeight * boundingBoxWidth;

      /**
       * This Conditions is to aid the system recognition accuracy, it filters recognized gestures and
       * make sure to check distances of specific fingers for better recognition accuracy.
       */
      if (arrComplicated_COD.includes(detected)) { // this exist to avoid conflict with letters C O D
        const distanceThumbIndex = calculateDistance(thumbTip, indexTip);
        const boxPositionPinky = calculateGap(pinkyTip, bottomLeft);
        const normalizedboxPositionPinky = normalizeDistance(
          boxPositionPinky,
          boxAreaSize
        );

        if (normalizedboxPositionPinky > 0.1 && boxPositionPinky > 0) {
          if (distanceThumbIndex >= 70) {
            detected = "D";
          } else if (distanceThumbIndex < 40) {
            if(boxPositionPinky > 0){
              detected = "O";
            }else{
              detected = "";
            }
          } else if(distanceThumbIndex > 40 && distanceThumbIndex < 70){
            detected = "C";
          }else{
            detected = "";
          }
        }
      } else if (arrComplicated_RUVW.includes(detected)) { // this exist to avoid conflict with letters R U V W
        const TipDistance = calculateDistance(indexTip, middleTip);
        const MidDistance = calculateDistance(indexMid, middleMid);
        const palmDistance = calculateDistance(palm, ringTip);

        

        if (TipDistance > 30 && MidDistance > 30 && palmDistance < 100) {
          detected = "V";
        } else if (
          TipDistance > 16 &&
          TipDistance < 30 &&
          MidDistance > 16 &&
          MidDistance < 30 &&
          palmDistance < 100
        ) {
          detected = "U";
        } else if (
         ( TipDistance < 16 ||
          MidDistance < 16) &&
          palmDistance < 100
        ) {
          detected = "R";
        } else if (TipDistance > 30 && palmDistance > 100) {
          detected = "W";
        } else {
          detected = "";
        }
      } else if (arrComplicated_IYJ.includes(detected)) { // this exist to avoid conflict with letters I J Y
        const pinkyBottomLeftDistance = calculateGap(pinkyTip, bottomLeft);
        const TipDistance = calculateDistance(thumbTip, pinkyTip);


        if (pinkyBottomLeftDistance < 0) {
          if (TipDistance > 100) {
            detected = "Y";
          } else if (TipDistance <= 100) {
            detected = "I";
          } else {
            detected = "";
          }
        } else {
          detected = "J";
        }
      } else if (arrComplicated_BF.includes(detected)) { // this exist to avoid conflict with letters B F
        const TipDistance = calculateDistance(thumbTip, indexTip);

    

        if (TipDistance >= 80) {
          detected = "B";
        } else if (TipDistance < 80) {
          detected = "F";
        } else {
          detected = "";
        }
      } else if (arrComplicated_MNSA.includes(detected)) { // this exist to avoid conflict with letters M N S A
        let distance_pinky_palm = calculateDistance(pinkyTip, palm);
        let gap_ring_palm = calculateGap(ringTip, palm);
        let distance_pinky_thumb = calculateDistance(pinkyTip, thumbTip);
        let gap_middle_palm = calculateGap(middleTip, palm);
        const PinkyBoxPosition = calculateGap(pinkyTip, topRight);

        // Normalize distances
        const normalizedDistancePinkyPalm = normalizeDistance(
          distance_pinky_palm,
          boxAreaSize
        );
        const normalizedDistancePinkyThumb = normalizeDistance(
          distance_pinky_thumb,
          boxAreaSize
        );
        const normalizedDistanceRingPalm = normalizeDistance(
          gap_ring_palm,
          boxAreaSize
        );
        const normalizedDistanceMiddlePalm = normalizeDistance(
          gap_middle_palm,
          boxAreaSize
        );

        let threshold_pinky_thumb = 0.49; // higher than this = A
        let threshold_pinky_palm = 0.45; // higher than this = E
        let threshold_ring_palm = -0.29; // higher than this = N else = M
        let threshold_mid_palm = -0.24 ; // higher than this = N or M else = S 

        // console.log("normalizedDistanceMiddlePalm:",normalizedDistanceMiddlePalm)

        // make sure pinky points to bbox topright
        if (PinkyBoxPosition < 20) { // this exists to avoid conflict with letters T and X
          if (normalizedDistancePinkyPalm > threshold_pinky_palm) { 
            detected = "E";
          } else if (normalizedDistancePinkyThumb > threshold_pinky_thumb && normalizedDistancePinkyPalm < threshold_pinky_palm) {
            detected = "A";
          } else if(normalizedDistancePinkyThumb < threshold_pinky_thumb && normalizedDistancePinkyPalm < threshold_pinky_palm) {
            if (normalizedDistanceMiddlePalm > threshold_mid_palm ) { //  this exist to aid the accuracy of S N M
              detected = "S";
              console.log("S")
            } else if (normalizedDistanceRingPalm < threshold_ring_palm  && normalizedDistanceMiddlePalm < threshold_mid_palm) {
              detected = "N";
              console.log("N")
            } else if(normalizedDistanceRingPalm > threshold_ring_palm  && normalizedDistanceMiddlePalm < threshold_mid_palm) {
              detected = "M";
              console.log("M")
            }else{
              detected = "";
            }
          }
        }
        
      } else if (arrComplicated_HK.includes(detected)) { // this exist to avoid conflict with letters H K
        const index_middle_tip_distance_XY = calculateGap(middleTip, indexTip);

    

        if (index_middle_tip_distance_XY > 30) {
          detected = "K";
        } else {
          detected = "H";
        }
      } else if (arrComplicated_QGXT.includes(detected)) { // this exist to avoid conflict with letters Q G X T
        let middleMid = hands[0].landmarks[10];
        const indexBottomLeftPosition = calculateGap(indexTip, bottomLeft);
        const ThumbTipDistance = calculateDistance(middleMid, indexTip);
        const thumbTipBoxPosition = calculateGap(thumbTip, bottomLeft);
        const indexTopRightPosition = calculateGap(indexTip, topRight);
        const boxPositionPinky = calculateGap(pinkyTip, topRight);

        const normalizedthumbTipBoxPosition = normalizeDistance(
          thumbTipBoxPosition,
          boxAreaSize
        );
        const normalizedindexTipPosition = normalizeDistance(
          indexBottomLeftPosition,
          boxAreaSize
        );
        const normalizedIndexTipGap = normalizeDistance(
          ThumbTipDistance,
          boxAreaSize
        );



        detected = "";
        if (normalizedthumbTipBoxPosition > 0.15) {
          if (normalizedindexTipPosition > 0) {
            // this exist to avoid L letter conflict
            if (normalizedindexTipPosition < 0.25) {
              detected = "G";
            } else {
              detected = "Q";
            }
          }
        } else if (indexTopRightPosition < -15) {
          if (thumbTipBoxPosition < 100) {
            if (boxPositionPinky > 40) {
              // this exist to avoid conflict with letter A E M N S and T X
              if (normalizedIndexTipGap < 0.2) {
                detected = "T";
              } else {
                detected = "X";
              }
            }
          }
        } else {
          detected = "";
        }
      } else if (arrComplicated_L.includes(detected)) { // this exist to avoid conflict with other letters
        const thumbTipBoxPosition = calculateGap(thumbTip, bottomLeft);

        if (thumbTipBoxPosition > 50) {
          detected = "L";
        } else {
          detected = "";
        }
      }

      /**
       * set the UI letter to final letter
       */
      setDetectedLetter(detected);
    } else {
      setDetectedLetter("");
    }
  };
