import React, { useEffect, useState } from "react";
import s from "../css/classes.module.css";
import { icons } from "../../../../../utilities/icons";
import ViewClass from "../modals/ViewClass";
import { deleteDatas } from "../../../../../services/api";
import Popup from "../../../components/Popup/Popup";
import Prompt from "../../../components/Prompt/Prompt";
import Mail from "../../../components/Mail_layout/Mail";
import Default from "../../../../defaults/Default/Default";

function Classes({ classes, user, fetchStudentClasses }) {
  const [available, setAvailable] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [error, setError] = useState("");
  const [active, setActive] = useState("");
  const [message, setMessage] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [mailContent, setMailContent] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    const filteredClasses = classes.filter((cl) => cl.participant_id !== null);
    setAvailable(filteredClasses);
  }, [classes]);

  const filteredClasses = available.filter((cl) =>
    cl.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (cl) => {
    setModalContent(null);
    setModalContent(
      <ViewClass
        datas={cl}
        onConfirm={unjoinClass}
        student_id={user?.student_id}
        setIsModalOpen={setIsModalOpen}
        fetchStudentClasses={fetchStudentClasses}
      />
    );
    setIsModalOpen(true);
  };

  const unjoinClass = async (confirmation, participant_id) => {
    if (confirmation) {
      const res = await deleteDatas(
        `/student-api/learn/unjoin`,
        { participant_id },
        setError
      );
      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        fetchStudentClasses();
        setIsMailOpen(false);
      } else {
        setActive(3);
        setMessage(res?.error);
      }
    }
    setIsMailOpen(false);
  };

  const openMail = (Component, id, e) => {
    setMailContent(null);
    setMailContent(<Component onConfirm={unjoinClass} id={id} />);
    setActiveMenu(null);
    setIsMailOpen(true);
  };

  const toggleMenu = (index, e) => {
    e.stopPropagation();
    setActiveMenu(activeMenu === index ? null : index);
  };

  return (
    <div className={s.studentClassesContainer}>
      <Popup message={message} active={active} />

      {isMailOpen && <Mail>{mailContent}</Mail>}

      {available.length === 0 ? (
        <Default active={2} />
      ) : (
        <>
          {isModalOpen ? (
            modalContent
          ) : (
            <>
              <div className={s.avalableClassHeader}>
                <span className={s.avalableClassTitle}>
                  {icons.classLine} Your Classes
                </span>
                <span className={s.avalableClassButtons}>
                  <input
                    type="search"
                    placeholder="Title Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* <button>{icons.filterFill}Filter</button> */}
                </span>
              </div>
              {filteredClasses.length > 0 ? (
                <div className={s.availableClassWrapper}>
                  {filteredClasses.map((cl, i) => (
                    <div className={`${s.classCard} ${s.fadeIn}`} key={i}>
                      <span
                        className={s.classCardBanner}
                        style={{ backgroundColor: cl.color }}
                      >
                        {icons.gradFill}
                      </span>
                      <span
                        className={s.menuToggler}
                        onClick={(e) => toggleMenu(i, e)}
                      >
                        {icons.kebabFill}
                      </span>
                      {activeMenu === i && (
                        <div className={s.menuOptions}>
                          <button
                            onClick={(e) =>
                              openMail(Prompt, cl.participant_id, e)
                            }
                          >
                            Unjoin Class
                          </button>
                        </div>
                      )}
                      <div
                        className={s.classCardFirstSection}
                        style={{ backgroundColor: cl.color }}
                      >
                        <p className={s.classCardTitle}>{cl.title}</p>
                        <p className={s.classCardDesc}>
                          Section: <b>{cl.description}</b>
                        </p>
                        <div className={s.classCardTeacherInfo}>
                          <img
                            src={cl?.image}
                            className={s.classCardTeacherImage}
                            alt="profile"
                          />
                          <p>
                            <b>Teacher: </b>
                            {cl?.name}
                          </p>
                        </div>
                      </div>
                      <span className={s.classCardButtons}>
                        <button
                          className={s.button}
                          style={{ backgroundColor: cl.color }}
                          onClick={() => openModal(cl)}
                        >
                          {icons.classLine} View
                        </button>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <Default active={2} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Classes;
