import React from 'react'
import s from './center.module.css';
function Center({children}) {
  return (
    <section className={s.container}>{
      children
    }</section>  
  )
} 

export default Center 