// A.js
export function setLetterO(fp) {
    const letterO = new fp.GestureDescription("O");
  
    letterO.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    letterO.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 1.0);
    letterO.addCurl(fp.Finger.Middle, fp.FingerCurl.HalfCurl, 1.0);
    letterO.addCurl(fp.Finger.Ring, fp.FingerCurl.HalfCurl, 1.0);
    letterO.addCurl(fp.Finger.Pinky, fp.FingerCurl.HalfCurl, 1.0);
  
    letterO.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpLeft,1.0);
    // letterO.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft,0.4);
    // letterO.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpLeft, 0.4);
    // letterO.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpLeft, 0.4);
    // letterO.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpLeft, 0.4);

    return letterO;
  }
   