import React, { useState } from "react";
import { Link } from "react-router-dom";
import s from "./footer.module.css";
import { handleToggler } from "../../utilities/methods";
import banner from "../../assets/banner.png";
  
function Footer() {
  const [isFooterVisible, setIsFooterVisible] = useState(false);
 
  return (
    <footer className={s.footer}>
      <div className={s.footerBanner} 
          onClick={() => handleToggler(isFooterVisible, setIsFooterVisible)}

      >
        <img
          className={s.footerBannerImg}
          src={banner}
          alt="banner"
        />
        <p className={s.footerBannerTitle}>Menus</p>
      </div>

      {/* Slide-in and slide-out effect based on visibility */}
      <div
        className={`${s.footerMenuContainer} ${
          isFooterVisible ? s.slideIn : s.slideOut
        }`}
      >
        <Link to={"/"}>Home</Link>
        <Link to={"/contact"}>Contact</Link>
        <Link to={"/about"}>About us</Link>
        <Link to={"/privacy"}>Privacy and policy</Link>
        <Link to={"/terms"}>Terms and conditions</Link>
      </div>

      <div className = {s.footerRight}>&copy; 2024 TechT All rights reserved</div>
    </footer>
  );
}

export default Footer;
