// A.js
export function setLetterM(fp) {
  const letterM = new fp.GestureDescription("M");

  letterM.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterM.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterM.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterM.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterM.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  letterM.addDirection(
    fp.Finger.Thumb,
    fp.FingerDirection.DiagonalUpLeft,
    1.0
  );
  return letterM;
}
