// A.js
export function setIloveyou(fp) {
    const Iloveyou = new fp.GestureDescription("I Love You");
  
  // Gesture I: 2 Nocurl 3 fullCurl
  Iloveyou.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  Iloveyou.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
  Iloveyou.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  Iloveyou.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  Iloveyou.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);

  Iloveyou.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalLeft, 0.5);
  Iloveyou.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 1.0);
  // Iloveyou.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 1.0);

    return Iloveyou;
  }
  