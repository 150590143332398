// A.js
export function setLetterE(fp) {
  const letterE = new fp.GestureDescription("E");
 
  // Gesture E: All fingers halfCurled
  letterE.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1);
  letterE.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 0.7);
  letterE.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 0.4);
  letterE.addCurl(fp.Finger.Middle, fp.FingerCurl.HalfCurl, 0.7);
  letterE.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 0.4);
  letterE.addCurl(fp.Finger.Ring, fp.FingerCurl.HalfCurl, 0.7);
  letterE.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 0.4);
  letterE.addCurl(fp.Finger.Pinky, fp.FingerCurl.HalfCurl, 0.7);
  letterE.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 0.4);

  return letterE;
}
