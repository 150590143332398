// A.js
export function setLetterS(fp) {
    const letterS = new fp.GestureDescription("S");

    letterS.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
    letterS.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
    letterS.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
    letterS.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
    letterS.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);
  
    letterS.addDirection(
      fp.Finger.Thumb,
      fp.FingerDirection.DiagonalUpLeft,
      1.0
    );
    letterS.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 1.0);
  
    return letterS;
  }
  