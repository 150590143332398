import React from "react";
import { icons } from "../../../../../../utilities/icons";
import s from "./css/top.module.css";
import Add from "./modal/Add";

function Top({ openModal}) {
  return (
    <div className={s.parent}>
      <div className={s.left}>STRANDS</div>
      <div className={s.right}>
        <span className={s.icon} onClick={() => openModal(Add, "")}>
          {icons.addFill}
        </span>
      </div>
    </div>
  );
}

export default Top;
