import React, { useEffect, useState } from "react";
import s from "../css/parts.module.css";
import { icons } from "./../../../../../utilities/icons";
import ViewClass from "../../E-Learning/modals/ViewClass";
import Default from "../../../../defaults/Default/Default";
function StudentClasses(props) {
  const { classes, openModal } = props; 
  const [classJoined, setClassJoined] = useState([]);
  
  useEffect(() => {
    const filteredClasses = classes.filter((cl) => cl.participant_id !== null);
    setClassJoined(filteredClasses);
  }, [classes]); 

  return (
    <div className={s.studentClassesContainer}>
      {classJoined.length === 0 ? (
        <Default active={2}/>
      ) : (
        <div className={s.classWrapper}>
          {classes.map((cl, i) => {
            if (cl.participant_id) {
              return (
                <span
                  className={`${s.classBox} ${s.slideIn}`}
                  key={i}
                  style={{ backgroundColor: cl.color }}
                  onClick={() => openModal(ViewClass, cl)}
                >
                  <span className={`${s.banner} ${s.topBanner}`}>
                    {icons.classLine}
                  </span>
                  <p className={s.subject}>{cl.title}</p>

                  <span className={`${s.banner} ${s.botBanner}`}>
                    <p className={s.section}> {cl.description}</p>
                  </span>
                </span>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default StudentClasses;
