import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./style.module.css";
import Default from "../../../../../../defaults/Default/Default";
import Announce from "./Announce";
import Mail from "../../../../../components/Mail_layout/Mail";
import { icons } from "../../../../../../../utilities/icons";

function Main({ strand,user }) {
  const navigate = useNavigate();
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const viewClasses = (strand_id, title) => {
    navigate(`/teacher/strand/${strand_id}/${title}/classes`);
  };

  const openModal = (e, Component, str) => {
    e.stopPropagation();
    setModalContent(
      <Component closeModal={closeModal} str={str} user={user} />
    );
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className = {`${s.container} ${s.fadeIn}`}>
     {isModalOpen && (
        <Mail title="Modal Title" isClose={closeModal} isOpen={isModalOpen}>
          {modalContent}
        </Mail>
      ) }
        <>
          {strand.length === 0 ? (
            <Default
              active={2}
            />
          ) : (
            <div className={s.parent}>
              {strand.map((str, i) => (
                <div
                  className={s.cardContainer}
                  key={i}
                  onClick={() => viewClasses(str.strand_id, str.title)}
                >
                  <div className={s.card}>
                    <img src={str.image} className={s.cardImg} alt="..." />
                    <div className={s.cardBody} >
                      <span
                        className={s.icon}
                        onClick={(e) => openModal(e, Announce, str)}
                      >
                        {" "}
                        {icons.hornFill} Announce
                      </span>
                      <h5 className={s.cardTitle}>{str.strand}</h5>
                      <p className={s.cardText}>{str.year}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
       
    </div>
  );
}

export default Main;
