import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import s from "./style.module.css";
import Top from "../modules_top/Top";
import Popup from "../../../../../components/Popup/Popup";
import Mail from "../../../../../components/Mail_layout/Mail";
import { icons } from "../../../../../../../utilities/icons";
import { deleteDatas, getDatas } from "../../../../../../../services/api";
import Default from "../../../../../../defaults/Default/Default";

function Main({ user }) {
  const { class_id } = useParams();
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [contents, setContents] = useState([]);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [activeInfo, setActiveInfo] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [contentSearchQuery, setContentSearchQuery] = useState("");
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [loadingContents, setLoadingContents] = useState(false);
  const [materialsOpen, setMaterialsOpen] = useState(false);

  const fetchMaterials = async () => {
    setLoadingMaterials(true);
    try {
      const res = await getDatas(
        `/teacher-api/materials/get/${class_id}`,
        setError
      );
      if (res && res.response) {
        setMaterials(res.response);
        setLoadingMaterials(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContents = async () => {
    setLoadingContents(true);
    try {
      const res = await getDatas(
        `/teacher-api/contents/get/${class_id}`,
        setError
      );
      if (res && res.response) {
        setContents(res.response);
        setLoadingContents(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMaterials();
    fetchContents();
  }, []);

  const deleteContent = async (id, video, image, e) => {
    e.stopPropagation();
    setActive("");
    setMessage("");

    if (video) {
      const videoFileName = video.split("/").pop();
      const folder = "contents";
      const role = "videos";

      const deleteVideoUrl = `/deleteS3Asset/${folder}/${role}/${videoFileName}`;
      const deleteVideoRes = await deleteDatas(deleteVideoUrl, null, setError);

      if (!deleteVideoRes) {
        setMessage("Failed to delete content video");
        setActive(2);
      }
    }

    if (image) {
      const imageFileName = image.split("/").pop();
      const folder = "contents";
      const role = "images";

      const deleteImageUrl = `/deleteS3Asset/${folder}/${role}/${imageFileName}`;
      const deleteImageRes = await deleteDatas(deleteImageUrl, null, setError);

      if (!deleteImageRes) {
        setMessage("Failed to delete content image");
        setActive(2);
      }
    }

    const res = await deleteDatas(
      `/teacher-api/contents/delete/${id}`,
      {},
      setError
    );

    if (res && res.response) {
      setContents((prevContents) =>
        prevContents.filter((content) => content.content_id !== id)
      );
      setMessage(res.response);
      setActive(1);
      fetchContents();
    } else {
      setMessage(res.error || "An error occurred while deleting.");
      setActive(3);
    }
  };

  const deleteMaterial = async (id, file, e) => {
    setActive("");
    setMessage("");
    e.stopPropagation();

    if (file) {
      const fileName = file.split("/").pop();
      const folder = "materials";
      const role = "files";

      const deleteFileUrl = `/deleteS3Asset/${folder}/${role}/${fileName}`;
      const deleteFileRes = await deleteDatas(deleteFileUrl, null, setError);

      if (!deleteFileRes) {
        setMessage("Failed to delete material");
        setActive(2);
      }
    }

    const res = await deleteDatas(
      `/teacher-api/materials/delete/${id}`,
      {},
      setError
    );

    if (res && res.response) {
      setMaterials((prevMaterials) =>
        prevMaterials.filter((material) => material.material_id !== id)
      );
      setMessage(res.response);
      setActive(1);
      fetchMaterials();
    } else {
      setMessage(res.error || "An error occurred while deleting.");
      setActive(3);
    }
  };

  const openModal = (Component, datas, e) => {
    e.stopPropagation();
    setModalContent(
      <Component
        closeModal={closeModal}
        datas={datas}
        class_id={class_id}
        user={user}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    fetchMaterials();
    fetchContents();
    setIsModalOpen(false);
    setModalContent(null);
  };

  const viewFile = (url) => {
    window.open(url, "_blank");
  };

  const showInfo = (id) => {
    setActiveInfo((prev) => (prev === id ? null : id));
  };

  const filteredMaterials = materials.filter((mat) =>
    mat.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredContents = contents.filter((con) =>
    con.title.toLowerCase().includes(contentSearchQuery.toLowerCase())
  );

  const openMaterials = () => {
    setMaterialsOpen(true);
  };
  const hideMaterials = () => {
    setMaterialsOpen(false);
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        contentSearchQuery={contentSearchQuery}
        setContentSearchQuery={setContentSearchQuery}
      />

      {isModalOpen ? (
        <Mail title="Modal Title" isClose={closeModal} isOpen={isModalOpen}>
          {modalContent}
        </Mail>
      ) : (
        <div className={s.modulesContainer}>
          {materialsOpen && (
            <section className={s.leftPanel}>
              <div className={s.leftHeader}>
                <span>
                  Materials
                  <button onClick={hideMaterials}>Hide</button>
                </span>
              </div>
              <div className={`${s.leftMain} ${s.fadeIn}`}>
                <input
                  type="search"
                  className={s.searchMaterials}
                  placeholder="Title Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {loadingMaterials ? (
                  <Default active={1} />
                ) : filteredMaterials === 0 || materials.length === 0 ? (
                  <Default active={2} />
                ) : (
                  filteredMaterials.map((mat) => (
                    <span
                      key={mat.material_id}
                      className={`${s.leftMat} ${s.fadeIn}`}
                      onClick={() => viewFile(mat.file)}
                      style={{ backgroundColor: mat.color }}
                    >
                      {mat.title}
                      <button
                        className={s.leftDelete}
                        onClick={(e) =>
                          deleteMaterial(mat.material_id, mat.file, e)
                        }
                      >
                        {icons.deleteFill}
                      </button>
                    </span>
                  ))
                )}
              </div>
            </section>
          )}

          <section className={s.rightPanel}>
            <div className={s.rightHeader}>
              Contents
              <button onClick={openMaterials}>Materials</button>
            </div>
            <div className={`${s.rightMain} ${s.fadeIn}`}>
              {loadingMaterials ? (
                <Default active={1} />
              ) : filteredContents === 0 || contents.length === 0 ? (
                <Default active={2} />
              ) : (
                filteredContents.map((con) => (
                  <div className={`${s.card} ${s.fadeIn}`} key={con.content_id}>
                    {activeInfo === con.content_id ? (
                      <div className={s.infoContainer}>
                        <img
                          className={s.image}
                          src={con.image}
                          alt={con.title}
                        />
                        <div className={s.rightDescription}>
                          {con.description}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={s.video_container}>
                          <video src={con.video} controls />
                          <div className={s.content}></div>
                        </div>
                        <span className={s.rightTitle}>{con.title}</span>
                      </>
                    )}

                    {con.image && (
                      <span
                        className={s.rightImage}
                        onClick={() => showInfo(con.content_id)}
                      >
                        {icons.infoFill}
                      </span>
                    )}
                    <span
                      className={s.banner}
                      style={{ backgroundColor: con.color }}
                    ></span>
                    {!activeInfo ? (
                      <span className={s.rightButtons}>
                        <button
                          onClick={(e) =>
                            deleteContent(
                              con.content_id,
                              con.video,
                              con.image,
                              e
                            )
                          }
                        >
                          {icons.deleteFill}
                        </button>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Main;
