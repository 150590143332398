import React, { useState } from 'react';
import s from '../css/modal.module.css';
import socket from '../Utilities/socket';
import { useNavigate } from 'react-router-dom';

function JoinRoom({ roomDetails }) {
    const navigate = useNavigate();
    const [wrongPasswordOpen, setWrongPasswordOpen] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [values, setValues] = useState({
        room_password: "",
        user_name: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
        if (name === "room_password") {
            setWrongPasswordOpen(false);
        }
        if (name === "user_name") {
            setUsernameError(false);
        }
    };

    const handleJoinRoom = (e) => {
        e.preventDefault();
        const { room_name, host_name, room_pass, room_state, participants } = roomDetails;
        const { room_password, user_name } = values;

        // Check if the username is the same as the host name or any participant's name
        if (user_name === host_name || (participants && participants.includes(user_name))) {
            setUsernameError(true);
            return;
        }

        if (room_state === "secured") {
            if (room_pass === room_password) {
                goJoinMethod(room_name, user_name);
            } else {
                setWrongPasswordOpen(true);
            }
        } else {
            goJoinMethod(room_name, user_name);
        }
    };

    const goJoinMethod = (room_name, user_name) => {
        socket.emit("joinRoom", { room: room_name, user: user_name });
        navigate(`/signify/participantRoom/${room_name}/${user_name}`);
        setValues({ ...values, room_password: "", user_name: "" });
    };

    return (
        <div className={s.modalContainer}>
            <div className={s.headerContainer}>
                <h3 className={s.title}>Join Room</h3>
            </div>
            <form onSubmit={handleJoinRoom}>
                <label htmlFor="user_name">User Name:</label>
                <input
                    type="text"
                    name="user_name"
                    placeholder="Enter Username"
                    onChange={handleChange}
                    value={values.user_name}
                    required
                />
                {usernameError && (
                    <p className={s.usernameError}>Username is already taken. Please choose another name.</p>
                )}
                {roomDetails.room_state === "secured" && (
                    <>
                        <label htmlFor="room_password">Room Password:</label>
                        <input
                            type="password"
                            name="room_password"
                            placeholder="Enter room password"
                            onChange={handleChange}
                            value={values.room_password}
                        />
                        {wrongPasswordOpen && (
                            <p className={s.wrongPassword}>Wrong Password. Please try again.</p>
                        )}
                    </>
                )}
                <div className={s.buttonsContainer}>
                    <button type="submit" className={s.button}>Join Room</button>
                </div>
            </form>
        </div>
    );
}

export default JoinRoom;
