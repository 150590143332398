import A from "../../assets/images/letters/A.png";
import B from "../../assets/images/letters/B.png";
import C from "../../assets/images/letters/C.png";
import D from "../../assets/images/letters/D.png";
import E from "../../assets/images/letters/E.png";
import F from "../../assets/images/letters/F.png";
import G from "../../assets/images/letters/G.png";
import H from "../../assets/images/letters/H.png"; 
import I from "../../assets/images/letters/I.png";
import J from "../../assets/images/letters/J.png";
import K from "../../assets/images/letters/K.png";
import L from "../../assets/images/letters/L.png"; 
import M from "../../assets/images/letters/M.png";
import N from "../../assets/images/letters/N.png";
import O from "../../assets/images/letters/O.png"; 
import P from "../../assets/images/letters/P.png";
import Q from "../../assets/images/letters/Q.png";
import R from "../../assets/images/letters/R.png";
import S from "../../assets/images/letters/S.png";
import T from "../../assets/images/letters/T.png";
import U from "../../assets/images/letters/U.png";
import V from "../../assets/images/letters/V.png";
import W from "../../assets/images/letters/W.png";
import X from "../../assets/images/letters/X.png";
import Y from "../../assets/images/letters/Y.png";
import Z from "../../assets/images/letters/Z.png";
import space from "../../assets/images/letters/space.png";

const letterImages = {
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  " ": space,
};

export default letterImages;