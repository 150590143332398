import React, { useEffect, useState } from "react";
// styles
import styles from "./css/webtools.module.css";
// components
import Tool from "./tool/Tool";
import Sidebar from "./Side/Sidebar";
// data
import { toolsData } from "./datas/tools-data";

function WebTools() {
  const [toolData, setToolData] = useState([]);
  const [filteredTools, setFilteredTools] = useState([]);

  const fetchTools = () => {
    setToolData(toolsData);
    setFilteredTools(toolsData);
  };

  const filterTools = (sidebar_id) => {
    const filtered = toolsData.filter((item) => item.categoryId === sidebar_id);
    setFilteredTools(filtered);
  };

  const handleSearch = (query) => {
    const filtered = toolData.filter((item) =>
      item.resourceName && item.resourceName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredTools(filtered);
  };

  useEffect(() => {
    fetchTools();
  }, []);

  return (
    <div className={styles.toolsParent}>
      <div className={styles.toolsBody}>
        <Sidebar filterTools={filterTools} handleSearch={handleSearch} />
        <div className={styles.toolsContainer}>
          {filteredTools.map((item) => (
            <Tool key={item.id} items={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default WebTools;
