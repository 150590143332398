import React, { useContext, useState } from "react";
import s from "../css/main.module.css";
import style from "../../../components/style_configs/buttons.module.css";
import { GlobalStyleContext } from "../../../../../components/other/styles/globalcss";
import { icons } from "../../../../../utilities/icons";
import Top from "./Top";
// components
import Popup from "../../../components/Popup/Popup";
import Edit from "../modals/Edit";
import Default from "../../../../defaults/Default/Default";
import { deleteDatas } from "../../../../../services/api";
import { cleanDate } from "../../../../../utilities/methods";
import Mail from "../../../components/Mail_layout/Mail";
import View from "../modals/View";
import Modal from "./../../../../Teacher/components/Modal_layout/Modal";

function Main({
  announcements,
  setAnnouncements,
  fetchAnnouncements,
  copyAnnouncements,
  user,
}) {
  const { globalStyles } = useContext(GlobalStyleContext);
  const [modalContent, setModalContent] = useState(null);
  const [mailContent, setMailContent] = useState(null);
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState("");
  const [message, setMessage] = useState("");
  const [selectedAnnouncements, setSelectedAnnouncements] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [error, setError] = useState("");
  let type = "";

  const openMail = (Component, datas, e) => {
    setModalContent(null);
    showType(datas);
    setMailContent(
      <Component user={user} type={type} datas={datas} closeMail={closeMail} />
    );
    setIsMailOpen(true);
  };

  const openModal = (Component, datas, e) => {
    setModalContent(null);
    showType(datas);
    e.stopPropagation();
    setModalContent(
      <Component user={user} type={type} datas={datas} closeMail={closeMail} />
    );
    setIsModalOpen(true);
    setIsMailOpen(false);
  };

  const closeMail = () => {
    fetchAnnouncements(user.teacher_id);
    setIsMailOpen(false);
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    if (isChecked) {
      const allAnnouncementIds = announcements.map(
        (ann) => ann.announcement_id
      );
      setSelectedAnnouncements(allAnnouncementIds);

      const allFileNames = announcements.map((ann) => ann.file);
      setSelectedFiles(allFileNames);
    } else {
      setSelectedAnnouncements([]);
      setSelectedFiles([]);
    }
  };

  const handleSelectAnnouncement = (announcement_id, file, e) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    setSelectedAnnouncements((prev) => {
      if (isChecked) {
        return [...prev, announcement_id];
      } else {
        return prev.filter((id) => id !== announcement_id);
      }
    });

    const fileName = file.split("/").pop();
    setSelectedFiles((prev) => {
      if (isChecked) {
        return [...prev, fileName];
      } else {
        return prev.filter((prev) => prev !== fileName);
      }
    });
  };

  const deleteAnnouncement = async () => {
    if (selectedFiles.length > 0) {
      const folder = "announcements";
      const role = "files";

      const deleteFiles = selectedFiles.map((fileName, i) => {
        return new Promise(async (resolve, reject) => {
          const deleteFileUrl = `/deleteS3Asset/${folder}/${role}/${fileName}`;
          const deleteFileRes = await deleteDatas(
            deleteFileUrl,
            null,
            setError
          );

          if (!deleteFileRes) {
            reject("Failed to delete image from S3: ", i);
          }
          resolve("File Deleted: ", i);
        });
      });

      Promise.all(deleteFiles)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const url = `/teacher-api/announcements/delete`;

    try {
      const res = await deleteDatas(
        url,
        { announcement_ids: selectedAnnouncements },
        setError
      );

      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        fetchAnnouncements(user.teacher_id);
      } else {
        setActive(3);
        setError(res.error);
      }
    } catch (error) {
      setActive(3);
      setError("Error deleting announcements");
    }
  };

  const showType = (announce) => {
    if (announce.strand_id && announce.class_id) {
      type = "Class";
      return "Class";
    } else if (!announce.class_id && announce.strand_id) {
      type = "Strand";
      return "Strand";
    } else {
      type = "Others";
      return "Others";
    }
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <Top
        copyAnnouncements={copyAnnouncements}
        openMail={(Component, datas, e) => openMail(Component, datas, e)}
        setAnnouncements={setAnnouncements}
        announcements={announcements}
      />
      {isModalOpen ? (
        <Modal>{modalContent}</Modal>
      ) : (
        <>
          {announcements.length === 0 ? (
            <Default active={2} />
          ) : (
            <div className={s.annParent}>
              <div className={s.mainHeader}>
                <input
                  type="checkbox"
                  className={s.selectAll}
                  id="selectAll"
                  checked={selectAllChecked}
                  onChange={handleSelectAll}
                />
                <label htmlFor="selectAll">Select all</label>
                <button onClick={deleteAnnouncement} className={s.deleteButton}>
                  {icons.deleteFill}
                </button>
              </div>

              {announcements.map((announce, i) => (
                <div className={s.card} key={i}>
                  <div
                    className={s.cardBody}
                    onClick={(e) => {
                      if (e.target.tagName !== "INPUT") {
                        openModal(View, announce, e);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      className={s.annCheck}
                      checked={selectedAnnouncements.includes(
                        announce.announcement_id
                      )}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectAnnouncement(
                          announce.announcement_id,
                          announce.file,
                          e
                        );
                      }}
                    />
                    <p className={s.annType}>{`⭐ ${showType(announce)}`}</p>
                    <p className={s.annTitle}>{announce.title}</p>
                    <p className={s.annContent}>{announce.content}</p>
                    <p className={s.annExpiry}>
                      Expiring: {cleanDate(announce.expires_at)}
                    </p>
                  </div>

                  <div className={s.buttonContainer}>
                    <button
                      className={style.button}
                      style={{
                        backgroundColor: globalStyles.button,
                        color: globalStyles.buttonText,
                      }}
                      onClick={(e) => openMail(Edit, announce, e)}
                    >
                      {icons.updateFill}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {isMailOpen && (
        <Mail title="Modal Title" isClose={closeMail} isOpen={isMailOpen}>
          {mailContent}
        </Mail>
      )}
    </div>
  );
}

export default Main;
