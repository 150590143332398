import React, { useEffect, useState } from "react";
// components
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Center from "../../components/Center_layout/Center";
import Main from "./components/Main";
// style
import style from "./Dashboard.module.css";
import s from "../../components/style_configs/top_side_main.module.css";
import { getDatas } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import Default from "./../../../defaults/Default/Default";

const Dashboard = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  return (
    <>
      {user ? (
        <div className={style.dashboardContainer}>
          <header className={s.header}>
            <Header title="DASHBOARD" user={user} />
          </header>
          <aside className={s.sidebar}>
            <Sidebar />
          </aside>
          <section className={s.main}>
            <Center>
              <Main user={user} />
            </Center>
          </section>
        </div>
      ) : (
        <Default active={1} />
      )}
    </>
  );
};

export default Dashboard;
