 import React, { useState } from 'react'
 import s from './css/header.module.css'
import StrandRequests from './modals/requests/StrandHandling';
import AccountRequests from './modals/requests/AccountVerification';

function Header({setModalContent}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (Component, content = "", reCount = () => {}) => {
      setModalContent(
        <Component
          content={content}
          closeModal={() => {
            setIsModalOpen(false);
          }}
          reCount={reCount}
        />
      );
      setIsModalOpen(true);
    };
   return (
     <div className = {s.container}>
        <button  onClick={() => openModal(StrandRequests)}>Strand Handling Requests</button>
        <button  onClick={() => openModal(AccountRequests)}>Account Requests</button>
     </div>
   )
 }
 
 export default Header