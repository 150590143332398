// GlobalStyleContext.js
import React, { createContext, useEffect, useState } from "react";
// import { dark, light } from "./colors";
import { colors } from "./mono/monoDatas";
const GlobalStyleContext = createContext();
   
const GlobalStyleProvider = ({ children }) => {
  // const [color, setColor] = useState(false); 

  // //light and dark mode
  const [globalStyles, setGlobalStyles] = useState(colors["beige"]);

  //monochromatic themes
  const [theme, setTheme] = useState({});
 
  useEffect(() => {
    function checkTheme() {
      const theme = JSON.parse(localStorage.getItem("theme"));
      setGlobalStyles({...colors[theme]});
    }
    checkTheme();
  }, [theme]);


  return (
    <GlobalStyleContext.Provider value={{ globalStyles, theme, setTheme }}>
      {children}
    </GlobalStyleContext.Provider>
  );
};

export { GlobalStyleProvider, GlobalStyleContext };
