import React from "react";
import s from "./Main/css/main.module.css";
import Main from "./Main/Main";
import bg from "./assets/images/whity.jpg";
import Footer from "../../components/Footer/Footer";
/**
 *
 * make this the most 3d animated page
 */
function Welcome() {
  return (
    <>
      {/* Background Container */}
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>
      
      <Main />
      <Footer></Footer>
    </>
  );
}

export default Welcome;
