import React, { useEffect, useState } from "react";
// style
import s from "./css/request.module.css";
// components
import Students from "./modal/Students";
import Default from "../../../../../defaults/Default/Default";
import { getDatas } from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import Mail from "../../../../components/Mail_layout/Mail";

function Request() {
  const [strand, setStrand] = useState([]);
  const [requestCounts, setRequestCounts] = useState({});
  const [isEnrolleeOpen, setIsEnrolleeOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  // *****************************************************
  // Fetch the length of requests per strand
  // *****************************************************
  const fetchRequestsCount = async (strand_id) => {
    try {
      const url = `/teacher-api/enrollees/requests/${strand_id}`;
      try {
        const res = await getDatas(url, setError);
        if (res.response) {
          return res.response.length;
        }
        return 0;
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  const openModal = (Component, str, reCount) => {
    setModalContent(
      <Component
        str={str}
        closeModal={closeEnrollee}
        reCount={reCount}
        user={user}
      />
    );
    setIsEnrolleeOpen(true);
  };

  const closeEnrollee = () => {
    setIsEnrolleeOpen(false);
  };

  const getStrands = async () => {
    try {
      const url = `/teacher-api/strand/${user.teacher_id}`;
      try {
        const datas = await getDatas(url, setError);
        if (datas && datas.response) {
          setStrand(datas.response);
          // Fetch requests count for each strand
          const counts = {};
          for (const str of datas.response) {
            const count = await fetchRequestsCount(str.strand_id);
            counts[str.strand_id] = count;
          }
          setRequestCounts(counts);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.teacher_id) {
      getStrands();
    }
  }, [user]);

  return (
    <>
      {isEnrolleeOpen ? (
        <Mail>{modalContent}</Mail>
      ) : (
        <>
          <section className={`${s.parent} ${s.fadeIn}`}>
            <div className={s.header}>ENROLLMENT REQUESTS</div>
            {error ? (
              <Default active={3} />
            ) : loading ? (
              <Default active={1} />
            ) : (
              <>
                {strand.length === 0 ? (
                  <Default active={2} />
                ) : (
                  <div className={s.strandContainer}>
                    {strand.map((str, i) => (
                      <div
                        className={s.col}
                        key={i}
                        onClick={() => openModal(Students, str, getStrands)}
                      >
                        <div className={s.card}>
                          <img
                            src={str.image}
                            className={s.cardImg}
                            alt="..."
                          />
                          <div className={s.cardBody}>
                            <span className={s.icon}>{`${
                              requestCounts[str.strand_id] || 0
                            } Requests`}</span>
                            <h5 className={s.cardTitle}>{str.strand}</h5>
                            <p className={s.cardText}>{str.year}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </section>
        </>
      )}
    </>
  );
}

export default Request;
