import React, { useEffect, useState } from "react";
import s from "../css/top.module.css";
import { icons } from "../../../../../utilities/icons";
import { cleanString } from "../../../../../utilities/methods";

function Top(props) {
  const { openMail, setAnnouncements, announcements,copyAnnouncements } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [originalAnnouncements, setOriginalAnnouncements] = useState([]);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (value.trim() === "") { 
      setAnnouncements(originalAnnouncements);
    } else {
      setAnnouncements((prev) =>
        prev.filter((ann) =>
          cleanString(ann.title).includes(cleanString(value))
        )
      );
    }
  };

  const toggleSearch = () => {
    setIsSearchExpanded((prev) => !prev);
  };

  useEffect(() => {
    setOriginalAnnouncements(copyAnnouncements);
  }, [announcements]);

  return (
    <div className={s.parent}>
      <span>
    
      </span>

      {/* Search Icon and Input */}
      <div className={s.searchContainer}>
     
        <input
          type="search"
          placeholder="Title Search..."
          className={`${s.search} ${isSearchExpanded ? s.expanded : ""}`}
          value={searchQuery}
          onChange={handleSearchChange}
        />
       
      </div>
      <span className={s.searchIcon} onClick={toggleSearch}>
          {icons.searchFill}
        </span>
    </div>
  );
}

export default Top;
