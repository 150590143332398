import React, { useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { colors } from "./monoDatas";
import { GlobalStyleContext } from "../globalcss";
import s from "./mono.module.css";

function Monochromatic({ toggleMono }) {
  const { globalStyles, setTheme } = useContext(GlobalStyleContext);
  const handleClick = (th) => {
    setTheme({ ...colors[th] });
    const value = JSON.stringify(th);
    localStorage.setItem("theme", value);
  };

  return (
    <section className={s.grandParent}>
      <div
        className={s.headerContainer}
        style={{ backgroundColor: globalStyles.rhighlight }}
      >
        <h2 className={s.header} style={{ color: globalStyles.icon2 }}>
          Color Themes
        </h2>
        <button onClick={toggleMono}>Close</button>
      </div>
      <p className={s.subHeader}>Please Choose Color Theme Preference</p>
      <div
        className={s.monoParent}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        {Object.keys(colors).map((theme, i) => {
          const { background, primary, secondary, tertiary, highlight } =
            colors[theme];
          return (
            <div
              className={s.monoContainer}
              key={i}
              onClick={() => handleClick(theme)}
            >
              <div className={s.title} style={{ color: globalStyles.link }}>
                {theme}
              </div>
              <div className={s.spans}>
                <span
                  style={{ backgroundColor: background }}
                  className={s.span}
                ></span>
                <span
                  style={{ backgroundColor: primary }}
                  className={s.span}
                ></span>
                <span
                  style={{ backgroundColor: secondary }}
                  className={s.span}
                ></span>
                <span
                  style={{ backgroundColor: tertiary }}
                  className={s.span}
                ></span>
                <span
                  style={{ backgroundColor: highlight }}
                  className={s.span}
                ></span>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Monochromatic;
