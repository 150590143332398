// A.js
export function setLetterN(fp) {
  const letterN = new fp.GestureDescription("N");

  letterN.addCurl(fp.Finger.Thumb, fp.FingerCurl.HalfCurl, 1.0);
  letterN.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
  letterN.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
  letterN.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
  letterN.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);

  letterN.addDirection(
    fp.Finger.Thumb,
    fp.FingerDirection.DiagonalUpLeft,
    1.0
  );
  letterN.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 1.0);

  return letterN;
}
