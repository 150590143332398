import React, { useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import { RiVoiceprintLine } from "react-icons/ri";
import click from '../../assets/sounds/click2.MP3'
function Speech(props) {
  const { handleSpeechRecognition, setError } = props;
  const [listen, setListening] = useState(false);
  const audioRef = useRef(new Audio(click))

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window)) {
      setError("Speech recognition is not supported in this browser.");
      window.alert("Speech recognition is not supported in this browser.");
      return;
    }
    setListening(!listen);
audioRef.current.play();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onresult = (event) => {
          const transcript = event.results[event.resultIndex][0].transcript;
          handleSpeechRecognition(transcript);
        };

        recognition.onerror = (event) => {
          window.alert("Speech recognition error: " + event.error);
        };

        recognition.onend = () => {
        };

        if (listen) {
          recognition.stop();
        } else {
          recognition.start();
        }
      })
      .catch((err) => {
        setError("Microphone access denied: " + err.message);
        window.alert("Microphone access denied: " + err.message);
      });
  };

  return (
    <button
      onClick={startListening}
    >
      <span>{listen ? <RiVoiceprintLine /> : <FaMicrophone />}</span>
    </button>
  );
}

export default Speech;
