import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Center from "../../components/Center_layout/Center";
import style from "./Settings.module.css";
import Main from "./Main";
// global style
import s from "../../components/style_configs/top_side_main.module.css";
import { getDatas } from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  return (
    <div className={style.SettingsContainer}>
      <header className={s.header}>
        <Header title="Settings" user={user} />
      </header>
      <aside className={s.sidebar}>
        <Sidebar />
      </aside>
      <section className={s.main}>
        <Center>
          <Main user={user} />
        </Center>
      </section>
    </div>
  );
};

export default Settings;
