import React, { useState } from 'react'
// style
import s from './css/settings.module.css'
import style from '../../components/style_configs/buttons.module.css'
// components
import Center from '../../components/Center_layout/Center';
import Update from './modal/Update';
import Popup from '../../components/Popup/Popup';
import Monochromatic from '../../../../components/other/styles/mono/Monochromatic';
// conetxt
import ChangePassword from './modal/ChangePassword';
import { putDatas } from '../../../../services/api';
import { icons } from '../../../../utilities/icons';
 
function Main({user}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [error, setError] = useState(null)
  const [message, setMessage] = useState("")
  const [active, setActive] = useState(null);

  const openModal = (Component) => {
    if (user) {
      setModalComponent(<Component user={user} id={user.teacher_id} onConfirm={goConfirm} closeModal={() => setIsModalOpen(false)} toggleMono={() => setIsModalOpen(false)}/>)
      setIsModalOpen(true);
    }
  }

  const goConfirm = async (confirmation, user_id) => {
    setActive(null);
    setMessage("")
    setError(null);
    if (confirmation) {
      const query = `/teacher-api/enrollments/cancel`;
      const datas = { user_id }
      const res = await putDatas(query, datas, setError)
      if (res && res.response) {
        setActive(1);
        setMessage("Unenrolled Successfully");
      } else {
        setActive(3);
        setMessage("Failed to Unenroll")
      }
    }
    setIsModalOpen(false);
  }

  return (
    <Center>
      <Popup message={message} active={active} />

     
        <div className={`${s.buttons} ${s.fadeIn}`}>
        <button onClick={() => openModal(Monochromatic)} className={style.button}>{icons.colorLine} Color Theme</button>
        {/* <button onClick={() => openModal(Update)} className={style.button}>{icons.updateInfoFill} Update Information</button> */}
        <button onClick={() => openModal(ChangePassword)} className={style.button}>{icons.keyLine} Change Password</button>
      </div>
      {isModalOpen && (
        modalComponent
      ) }
    </Center>

  )
}

export default Main