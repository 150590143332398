  import React from "react";
  import s from "./terms.module.css";
  import bg from "../../../assets/whity.jpg";
  import Footer from "../../../components/Footer/Footer";


  function Terms() {
    return (
      <>
        {/* Background Container */}
        <div className={s.contactBackgroundContainer}>
          <img className={s.contactBackground} alt="blurred" src={bg}></img>
        </div>
        <section className={s.termsContainer}>
          <h1>Terms and Conditions</h1>
          <p>Last updated: [Date]</p>

          <h2>1. Introduction</h2>
          <p>
            Welcome to [Your Company Name]! By accessing our website, you agree to
            be bound by these Terms and Conditions. Please read them carefully.
          </p>

          <h2>2. Intellectual Property Rights</h2>
          <p>
            All content published on this site, including text, graphics, logos,
            and images, is the property of [Your Company Name] and/or its content
            creators and is protected by applicable copyright laws.
          </p>

          <h2>3. Restrictions</h2>
          <p>You are specifically restricted from the following:</p>
          <ul>
            <li>
              Publishing any website material in any other media without
              permission
            </li>
            <li>Selling or commercializing website material</li>
            <li>
              Using this website in any way that damages or interferes with user
              access
            </li>
            <li>Using this website in any unlawful manner</li>
          </ul>

          <h2>4. Limitation of Liability</h2>
          <p>
            In no event shall [Your Company Name] be held liable for any damages
            arising out of the use of this website. We provide the website "as is"
            without any warranties.
          </p>

          <h2>5. Changes to Terms</h2>
          <p>
            [Your Company Name] reserves the right to revise these Terms at any
            time. Users are encouraged to check this page periodically.
          </p>

          <h2>6. Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at [Your Contact Information].
          </p>
        </section>

        <Footer />

      </>
    );
  }

  export default Terms;
