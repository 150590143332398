import React, { useEffect, useState } from "react";
import s from "./css/enrollee.module.css";
import Default from "../../../../../defaults/Default/Default";
import { getDatas } from "../../../../../../services/api";
import StudInfo from "../../../../components/Modal_layout/StudInfo";
import { cleanString } from "../../../../../../utilities/methods";
import Mail from "../../../../components/Mail_layout/Mail";

function Enrollee({ user }) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalStudents, setOriginalStudents] = useState([]);
  const [loading, setLoading] = useState({
    enrollees: false,
    participants: false,
    strands: false,
  });
  const [error, setError] = useState("");
  const [strands, setStrands] = useState([]);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [strand_id, setStrand_id] = useState(null);

  const fetchStrandEnrollees = async (strand_id) => {
    setLoading({ enrollees: true });
    setError(null);
    try {
      const datas = await getDatas(
        `/teacher-api/enrollees/enrolled/${strand_id}`,
        setError
      );
      if (datas && datas.response) {
        setOriginalStudents(datas.response);
        setStudents(datas.response);
        setLoading({ enrollees: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClassParticipants = async (class_id, strand_id) => {
    setLoading({ participants: true });

    setError(null);
    try {
      const datas = await getDatas(
        `/teacher-api/enrollees/participants/${class_id}/${strand_id}`,
        setError
      );
      if (datas && datas.response) {
        setOriginalStudents(datas.response);
        setStudents(datas.response);
        setLoading({ participants: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStrands = async (teacher_id) => {
    setLoading({ strands: true });

    setError(null);
    const url = `/teacher-api/strand/${teacher_id}`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setStrands(res.response);
        setLoading({ strands: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = async (e) => {
    const { name, value } = e.target; // value is id
    if (name === "strand_id") {
      setStrand_id(value);
      fetchStrandEnrollees(value);
      try {
        const res = await getDatas(
          `/teacher-api/classes/${value}/${user.teacher_id}`,
          setError
        );
        if (res && res.response) {
          setClasses(res.response);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      fetchClassParticipants(value, strand_id);
    }
  };

  const openStudentInfo = (Component, content) => {
    setModalContent(
      <Component
        content={content}
        closeStudentInfo={closeStudentInfo}
        setEnrollees={setStudents}
      />
    );
    setIsModalOpen(true);
  };

  const closeStudentInfo = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    const cleanedValue = cleanString(value);
    setSearchQuery(value);

    if (cleanedValue.trim() === "") {
      setStudents(originalStudents);
    } else {
      const filteredStudents = originalStudents.filter((student) =>
        cleanString(`${student.email}`).includes(cleanedValue)
      );
      setStudents(filteredStudents);
    }
  };

  useEffect(() => {
    fetchStrands(user.teacher_id);
  }, [user]);

  return (
    <div className={`${s.checkStudentsContainer} ${s.fadeIn}`}>
      <div className={s.selectContainer}>
        <select name="strand_id" onChange={(e) => handleSelectChange(e)}>
          <option value={null}>Strand</option>
          {strands.map((str) => (
            <option value={str.strand_id} key={str.strand_id}>
              {str.year + " - " + str.strand}
            </option>
          ))}
        </select>
        <select name="class_id" onChange={(e) => handleSelectChange(e)}>
          <option value={null}>Class</option>

          {classes.map((cl) => (
            <option value={cl.class_id} key={cl.class_id}>
              {cl.title + " - " + cl.description}
            </option>
          ))}
        </select>
        <span>
          <input
            type="search"
            placeholder="Search by email..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </span>
      </div>

      {isModalOpen ? (
        <Mail>
          <div className={s.mailContainer}>{modalContent}</div>
        </Mail>
      ) : (
        <section className={`${s.parent} ${s.fadeIn}`}>
          {error ? (
            <Default active={3} />
          ) : (
            <>
              {students.length === 0 ? (
                <Default active={2} />
              ) : (
                <div className={s.cardWrapper}>
                  {students.map((stud, i) => (
                    <div key={i} className={s.card}>
                      <span className={s.name}>
                        <b>
                          {stud.fname} {stud.lname}
                        </b>
                      </span>
                      <span className={s.name}>{stud.email}</span>
                      <span
                        className={`${s.button}`}
                        onClick={() => openStudentInfo(StudInfo, stud)}
                      >
                        INFO
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </div>
  );
}

export default Enrollee;
