import React from "react";
// center layout
import Center from "../Center_layout/Center";
// styles
import s from "./prompt.module.css";
import style from '../css/buttons.module.css'
// icons
import { icons } from "../../../../utilities/icons";

function Prompt({ datas,id, onConfirm, imageUrl, videoUrl }) {
  const handleYes = () => {
    onConfirm(true, id,datas || "", imageUrl ||"", videoUrl||"");
  };

  const handleNo = () => {
    onConfirm(false, id); 
  };

  return (
    <Center>
      <div className={s.card}>
        <div className={s.header}>
          <h2 className={s.logo}>{icons.gradFill}</h2>
        </div>
        <div className={s.main}>
          Do you want to proceed?
        </div>
        <div className={s.footer}>
          <button className={style.button} onClick={handleYes}>Yes</button>
          <button className={style.button} onClick={handleNo}>No</button>
        </div>
      </div>
    </Center>
  );
}

export default Prompt;
