import React, { useEffect, useRef, useState } from "react";
import { startTranslation } from "../Utilities/Translate.js";
import space from "../../assets/images/space.png";
import "../css/sign2speech.css";
import { initialize } from "../Utilities/Initialize.js";
import { handleToggler } from "./../../../../utilities/methods";
import click from "../../assets/sounds/click2.MP3";
import { useNavigate } from "react-router-dom";

function Sign2Speech({ socket, participant_name, room_name }) {
  const audioRef = useRef(new Audio(click));
  const navigate = useNavigate();
  const [isTranslating, setIsTranslating] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isRecognitionActive, setIsRecognitionActive] = useState(false);
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState({
    checker: false,
    // 1 success, 2 error
    active: null,
  });
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);
  const [phrase, setPhrase] = useState([]);
  const [wordSuggestions, setWordSuggestions] = useState([]);
  const [word, setWord] = useState("");
  const [detectedLetter, setDetectedLetter] = useState("");
  const [dominantHand, setDominantHand] = useState(() => {
    const savedDominantHand = localStorage.getItem("dominantHand");
    return savedDominantHand ? JSON.parse(savedDominantHand) : "Right";
  });
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage ? JSON.parse(savedLanguage) : "English";
  });
  const [speed, setSpeed] = useState("moderate");
  const [text, setText] = useState({ phrase: "" });
  const [history, setHistory] = useState(() => {
    const savedMessages = localStorage.getItem("history");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const size = 224;

  /**
   * video and inference detection initialization
   */
  useEffect(() => {
    if (videoRef && canvasRef) {
      initialize(videoRef, canvasRef, setDetectedLetter, size, dominantHand);
    }
  }, [dominantHand]);

  /**
   * this exist to make sure the recognition is possible
   */
  useEffect(() => {
    if (isRecognitionActive) {
      const id = setInterval(() => {
        if (detectedLetter) {
          if (detectedLetter === "SPACE") {
            setPhrase((prev) => [...prev, word + " "]);
            setWord("");
          } else if (detectedLetter === "DELETE") {
            setWord("");
          } else {
            setWord((prev) => prev + detectedLetter);
          }
        }
      }, 500);

      setIntervalId(id);
      return () => clearInterval(id);
    } else {
      clearInterval(intervalId);
    }
  }, [isRecognitionActive, detectedLetter, word]);

  /**
   * worker communication
   */
  useEffect(() => {
    const worker = new Worker(`/workers/wordWorker.js`);

    worker.onmessage = (e) => {
      console.log("Suggestions:", e.data);
      setWordSuggestions(e.data);
    };
    if (word.length > 0) {
      worker.postMessage({ prefix: word, language: language });
    }
    return () => worker.terminate();
  }, [word, language]);

  /**
   * messages communcation
   */
  useEffect(() => {
    const handleMessageSent = (datas) => {
      const { message } = datas;
      console.log("Received message:", message);
      setText((prev) => ({
        ...prev,
        phrase: message,
      }));

      setMessages((prevMsg) => {
        const updatedMessages = [...prevMsg, message];
        localStorage.setItem("messages", JSON.stringify(updatedMessages));
        return updatedMessages;
      });

      setIsTranslating(true);
      startTranslation(message, speed, () => setIsTranslating(false));
    };

    const handleHostMessages = (datas) => {
      console.log(datas);
      setMessages(datas);
    };

    socket.on("messageSent", handleMessageSent);
    socket.on("hostMessages", handleHostMessages);

    return () => {
      socket.off("messageSent", handleMessageSent);
      socket.off("hostMessages", handleHostMessages);
    };
  }, [socket, speed]);

  /**
   * notification timer
   */
  useEffect(() => {
    setTimeout(() => {
      setAlertVisible({ checker: false, active: null });
    }, 5000);
  }, [alertVisible.checker]);

  /**
   * host message resetting
   */
  useEffect(() => {
    const handleHostMessagesReset = () => {
      setMessages([]);
      localStorage.removeItem("hostMessages");
    };

    socket.on("hostMessagesReset", handleHostMessagesReset);
    return () => {
      socket.off("hostMessagesReset", handleHostMessagesReset);
    };
  }, [socket]);

  const replay = (msg) => {
    setText({ phrase: "" });
    setText({ phrase: msg });
    setIsMessagesVisible(false);

    setIsTranslating(true);
    startTranslation(msg, speed, () => setIsTranslating(false));
  };

  const addToPhrase = (wrd) => {
    setWordSuggestions([]);
    setWord("");
    setPhrase((prev) => [...prev, wrd + " "]);
  };

  const startRecognition = () => {
    audioRef.current.play();
    setAlertVisible({ checker: false });
    setIsRecognitionActive((prev) => !prev);
  };

  const handleSpeedChange = (e) => {
    setSpeed(e.target.value);
  };

  const resetHistory = () => {
    localStorage.removeItem("history");
    setHistory([]);
    setIsHistoryVisible(false);
  };

  const setAsPhrase = (phrase) => {
    setPhrase(phrase);
    setIsHistoryVisible(false);
  };

  const sendMessage = (e) => {
    audioRef.current.play();
    setAlertVisible({ checker: false, active: null });
    console.log(phrase);
    e.preventDefault();
    if (!phrase || !participant_name || !room_name) {
      console.log("fill in fields");
      return;
    }

    // Join the phrase array into a single string
    const messageString = phrase.join(" ");
    if (phrase.length === 0) {
      setAlertVisible({ checker: true, active: 2 });
      return;
    }
    setHistory((prev) => {
      const updatedHistory = [...prev, phrase];
      localStorage.setItem("history", JSON.stringify(updatedHistory));
      return updatedHistory;
    });
    // Emit the message as a string
    socket.emit("sendReply", {
      user: participant_name,
      room: room_name,
      message: messageString,
    });
    setAlertVisible({ checker: true, active: 1 });

    setPhrase([]);
  };

  const resetPhrase = () => {
    setPhrase([]);
  };

  const handleDominantHandChange = (e) => {
    const { value } = e.target;
    localStorage.setItem("dominantHand", JSON.stringify(value));
    setDominantHand(value);
  };

  const handleLanguageChange = (e) => {
    const { value } = e.target;
    localStorage.setItem("language", JSON.stringify(value));
    setLanguage(value);
  };
  const toggleMessages = () => {
    setIsMessagesVisible(!isMessagesVisible);
  };

  return (
    <section className="sign2speechContainer">
      <section className="section1">
        <div className="speedContainer">
          <label>Speed:</label>

          <select
            className="speed-options"
            value={speed}
            onChange={handleSpeedChange}
          >
            <option value="slow">Slow</option>
            <option value="moderate">Moderate</option>
            <option value="fast">Fast</option>
          </select>
          <button onClick={toggleMessages} className="messagesTogglerBtn">
            Show Messages
          </button>
        </div>
        <section className="translate-parent">
          <div className="translate-hand-container">
            <img
              src={space}
              className="translate-default-hand"
              alt="Hand Gesture"
            />
          </div>
          {isMessagesVisible && (
            <div className="messages-container">
              <button onClick={toggleMessages}>Hide</button>
              <section className="previous-messages-container">
                <div className="previous-messages">
                  <span className="previous-messages-header">Messages</span>
                  {messages.length === 0 ? (
                    <span className="no-message-span">No Messages yet</span>
                  ) : (
                    messages.map((msg, i) => (
                      <span key={i} className="messages-span">
                        <p className="messages-title">Message #{i + 1}</p>
                        <button
                          className="message-replay"
                          onClick={() => replay(msg)}
                        >
                          repeat
                        </button>
                      </span>
                    ))
                  )}
                </div>
              </section>
            </div>
          )}
        </section>
        <span className="preview-message">{text.phrase}</span>
      </section>
      <section className="section2">
        {history.length > 0 && (
          <button
            className="btnOpenHistory"
            onClick={() => {
              handleToggler(isHistoryVisible, setIsHistoryVisible);
            }}
          >
            History
          </button>
        )}

        {isHistoryVisible ? (
          <div className="phrase_container">
            {history.length > 0 && (
              <button onClick={resetHistory} className="resetHistory">
                Reset
              </button>
            )}

            {history.map((phrase, i) => (
              <span
                key={i}
                className="phrase"
                onClick={() => setAsPhrase(phrase)}
              >
                {phrase}{" "}
              </span>
            ))}
          </div>
        ) : (
          <div className="info_container">
            {/* detected letter */}
            <div id="letter">{detectedLetter}</div>
            {/* word being built */}
            <div id="word">{word}</div>
            {/* phrase */}
            <div id="phrase">
              {phrase} <br />
              {phrase.length > 0 && (
                <button onClick={resetPhrase} className="btnResetPhrase">
                  Reset
                </button>
              )}
            </div>
            <div id="suggestionsContainer">
              {wordSuggestions.map((word, i) => (
                <span
                  key={i}
                  className="suggestion"
                  onClick={() => addToPhrase(word)}
                >
                  {word}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="video_container">
          <video
            id="video"
            autoPlay
            ref={videoRef}
            width={size}
            height={size}
          ></video>
          <canvas
            id="canvas"
            ref={canvasRef}
            width={size}
            height={size}
          ></canvas>
          <div className="configuration_container">
            <select
              id="select"
              name="dominantHand"
              onChange={(e) => handleDominantHandChange(e)}
            >
              <option>{dominantHand}</option>
              <option value="Right">Right </option>
              <option value="Left">Left </option>
            </select>
            <select
              id="select"
              name="dominantHand"
              onChange={(e) => handleLanguageChange(e)}
            >
              <option>{language}</option>
              <option value="English">English</option>
              <option value="Filipino">Filipino</option>
            </select>
          </div>
          <div className="recognition-buttons-container">
            <button onClick={startRecognition}>
              {isRecognitionActive ? "Stop Recognition" : "Start Recognition"}
            </button>
            <button
              type="button"
              onClick={sendMessage}
              className="send_message"
            >
              Send
            </button>
       
          </div>
          {alertVisible.checker && (
            <span
              className={`${
                alertVisible.active === 1
                  ? "send-phrase-success"
                  : "send-phrase-error"
              }`}
            >
              {alertVisible.active === 1
                ? "Message Sent"
                : "Please Start Recognition to build phrase"}
            </span>
          )}
        </div>
      </section>
    </section>
  );
}

export default Sign2Speech;
