export function setDelete(fp) {
    const Delete = new fp.GestureDescription("Delete");
  
    // Curl for each finger
    Delete.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    Delete.addCurl(fp.Finger.Index, fp.FingerCurl.NoCurl, 1.0);
    Delete.addCurl(fp.Finger.Middle, fp.FingerCurl.NoCurl, 1.0);
    Delete.addCurl(fp.Finger.Ring, fp.FingerCurl.NoCurl, 1.0);
    Delete.addCurl(fp.Finger.Pinky, fp.FingerCurl.NoCurl, 1.0);
  
    // Add direction for all fingers
    Delete.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp, 1.0);
    Delete.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.5);
    // Delete.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpLeft, 0.5);
    Delete.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpLeft, 0.5);
    // Delete.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpLeft, 0.5);
    // Delete.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.8);
    // Delete.addDirection(fp.Finger.Middle, fp.FingerDirection.HorizontalLeft, 0.8);
    // Delete.addDirection(fp.Finger.Ring, fp.FingerDirection.HorizontalLeft, 0.8);
    // Delete.addDirection(fp.Finger.Pinky, fp.FingerDirection.HorizontalLeft, 0.8);
  
    return Delete;
  }
  