import React, { useEffect, useState } from "react";
import s from "../css/style.module.css";
import Sign2Speech from "./Sign2Speech";
import socket from "../Utilities/socket";
import bg from "../../assets/images/whity.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { BsDoorOpenFill } from "react-icons/bs";
function ParticipantRoom() {
  const { participant_name, host_name, room_name } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    message: "",
    participant_name: participant_name || "",
    room_name: room_name || "",
    host_name: host_name || "",
  });

  useEffect(() => {
    const storedUserName = localStorage.getItem("participant_name");
    const storedRoomName = localStorage.getItem("room_name");

    if (storedUserName && storedRoomName) {
      setValues({
        ...values,
        participant_name: storedUserName,
        room_name: storedRoomName,
      });

      // Emit a join event to re-join the room
      socket.emit("joinRoom", { user: storedUserName, room: storedRoomName });
      localStorage.setItem("participant_name", values.participant_name);
      localStorage.setItem("room_name", values.room_name);
    } else {
      localStorage.setItem("participant_name", values.participant_name);
      localStorage.setItem("room_name", values.room_name);
      socket.emit("joinRoom", {
        user: values.participant_name,
        room: values.room_name,
      });
    }

    socket.emit("getParticipants", { room: values.room_name });

    socket.on("roomClosed", ({ room }) => {
      alert(`The room ${room} has been closed by the host.`);
      setTimeout(() => {
        navigate("/signify");
      },0)
    });

    // Cleanup listener on component unmount
    return () => {
      socket.off("participants");
      socket.off("userJoined");
      socket.off("userLeft");
    };
  }, [values.participant_name]);

  const leaveRoom = () => {
    socket.emit("leaveRoom", { participant_name, room_name });
    localStorage.removeItem("messages");
    localStorage.removeItem("history");
    localStorage.removeItem("participant_name");
    navigate("/signify");
  };

  return (
    <>
      {/* Background Container */}
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>
      <span className={s.btnSign2SpeechLeaveRoom}>
        <span onClick={leaveRoom}>
          <BsDoorOpenFill /> <p>Leave</p>
        </span>
      </span>
      <span className={s.header}>
        <Sign2Speech
          socket={socket}
          participant_name={values.participant_name}
          room_name={values.room_name}
        />
      </span>
    </>
  );
}

export default ParticipantRoom;
