export function setLetterC(fp) {
  const letterC = new fp.GestureDescription("C");

  // Curl for each finger
  letterC.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
  letterC.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 1.0);
  letterC.addCurl(fp.Finger.Middle, fp.FingerCurl.HalfCurl, 1.0);
  letterC.addCurl(fp.Finger.Ring, fp.FingerCurl.HalfCurl, 1.0);
  letterC.addCurl(fp.Finger.Pinky, fp.FingerCurl.HalfCurl, 1.0);

  // Add direction for all fingers
  letterC.addDirection(fp.Finger.Thumb, fp.FingerDirection.HorizontalLeft, 1.0);
  // letterC.addDirection(fp.Finger.Index, fp.FingerDirection.HorizontalLeft, 0.8);
  // letterC.addDirection(fp.Finger.Middle, fp.FingerDirection.HorizontalLeft, 0.8);
  // letterC.addDirection(fp.Finger.Ring, fp.FingerDirection.HorizontalLeft, 0.8);
  // letterC.addDirection(fp.Finger.Pinky, fp.FingerDirection.HorizontalLeft, 0.8);

  return letterC;
}
