import React, { useEffect, useState } from "react";
import s from "../css/view.module.css";
import Top from "./Top";
import { getDatas } from "../../../../../services/api";
import { cleanDate } from "./../../../../../utilities/methods";
import { icons } from "../../../../../utilities/icons";
import Mail from "../../../components/Mail_layout/Mail";
import ViewContent from "./ViewContent";

function ViewClass(props) {
  const { datas, setIsModalOpen } = props;
  const [error, setError] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [contents, setContents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isMaterialsVisible, setMaterialsVisible] = useState(false);

  const fetchMaterials = async () => {
    try {
      const res = await getDatas(
        `/teacher-api/materials/get/${datas?.class_id}`,
        setError
      );
      if (res && res.response) {
        setMaterials(res.response);
        setFilteredMaterials(res.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContents = async () => {
    try {
      const res = await getDatas(
        `/teacher-api/contents/get/${datas?.class_id}`,
        setError
      );
      if (res && res.response) {
        setContents(res.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMaterials();
    fetchContents();
  }, []);

  useEffect(() => {
    const filtered = materials.filter((mat) =>
      mat.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMaterials(filtered);
  }, [searchQuery, materials]);

  const handleDownload = (e, url) => {
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const openFile = (e, url) => {
    window.open(url, "_blank");
  };

  const openMail = (Component, cl) => {
    setModalContent(<Component datas={cl} setIsMailOpen={setIsMailOpen} />);
    setIsMailOpen(true);
  };

  const toggleMaterials = () => {
    setMaterialsVisible(!isMaterialsVisible);
  };

  return (
    <>
      {isMailOpen && <Mail>{modalContent}</Mail>}
      <Top datas={datas} setIsModalOpen={setIsModalOpen} />

      <section className={`${s.viewClassContainer} ${s.fadeIn}`}>
        <div className={s.main}>
          {isMaterialsVisible && (
            <div className={s.materialsContainer}>
              <div className={s.materialsHeader}>
                <input
                  type="search"
                  placeholder="Title Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={toggleMaterials}>Hide</button>
              </div>
              <span className={s.materialCardContainer}>
                {filteredMaterials.length > 0
                  ? filteredMaterials.map((mat, i) => (
                      <span
                        key={i}
                        className={s.materialCard}
                        onClick={(e) => openFile(e, mat.file)}
                      >
                        <span>
                          <p>{mat.title}</p>
                          <p>{cleanDate(mat.uploaded_at)}</p>
                        </span>
                        <div>
                          <button
                            className={s.downloadButton}
                            onClick={(e) => handleDownload(e, mat.file)}
                          >
                            {icons.downloadFill}
                          </button>
                        </div>
                      </span>
                    ))
                  : "Materials will be shown here"}
              </span>
            </div>
          )}

          <div className={s.contentsContainer}>
            <div className={s.banner} style={{ backgroundColor: datas?.color }}>
              <button
                className={s.btnTopMaterialsToggler}
                onClick={toggleMaterials}
              >
                Materials
              </button>
              <span className={s.topBanner}>{icons.gradFill}</span>
              <div className={s.classInfo}>
                <span className={s.classSubject}>{datas?.title}</span>
                <span className={s.classSection}>
                  Section: {datas?.description}
                </span>
              </div>
            </div>

            <div className={s.streamsContainer}>
              {contents.length === 0 ? (
                <p>Streams will be Shown Here.</p>
              ) : (
                contents.map((co, i) => (
                  <div key={i} className={s.contentCard}>
                    <span className={s.videoContainer}>
                      {co?.video && <video src={co.video} controls></video>}
                    </span>
                    <span className={s.info}>
                      <span className={s.title}>{co.title}</span>
                      <span className={s.date}>
                        <b>Uploaded: </b> {cleanDate(co.uploaded_at)}
                      </span>

                      <button
                        className={s.attachmentButton}
                        style={{ backgroundColor: datas?.color }}
                        onClick={() => openMail(ViewContent, co)}
                      >
                        Open
                      </button>
                    </span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewClass;
