import React, { useState } from "react";
// styles
import styles from "./css/create.module.css";
//methods
import { updateData } from "../../../../utilities/methods";
// componentsks
import Popup from "../../components/Popup/Popup";
function ChangePassword({ user, closeModal }) {
  console.log(user);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    password: "",
    confirm: "",
    user_id: user.user_id
  });

  // ***************************************************
  // handle Student Information Editing
  // ***************************************************
  const handleSubmit = async (e) => {
    const { password, confirm } = values;

    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    if (!password || !confirm) {
      setActive(2);
      setMessage("Please Fill up empty fields");
      return;
    }

    if (password !== confirm) {
      setActive(2);
      setMessage("Password Didn't match");
      return;
    }

    const url = `/student-api/authentication/forgot`;
    const response = await updateData(url, values, setError);

    if (response) {
      setActive(1);
      setMessage("Updating Student Password Successful!");
    } else {
      setActive(3);
      setMessage("Updating Student Password Failed!");
    }
  };

  // ***************************************************
  // handle preview image || input change || page reload
  // ***************************************************
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Popup message={message} active={active} />

      <div className={`${styles.container}`}>
        <div className={styles.loginCard}>
          {/* form on left */}
          <div className={styles.formSection}>
            <div className={styles.title}>
              Change Password
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputGroup}>

                {/* New Password */}
                <input
                  className={styles.input}
                  type="text"
                  name="password"
                  placeholder="Enter New Password"
                  value={values.description}
                  onChange={handleChange}
                  required
                />
                {/* Confirm Password */}
                <input
                  className={styles.input}
                  type="text"
                  name="confirm"
                  placeholder="Confirm Password"
                  value={values.contact}
                  onChange={handleChange}
                  required
                />
              </div>

              <button type="submit" className={styles.button}>
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
