import React, { useState, useRef } from "react";
// styles
import s from "./css/form.module.css";
// methods
import { previewFile } from "../../../../../../utilities/methods";
// components
import Popup from "../../../../components/Popup/Popup";
import {
  getDatas,
  postDatas,
  uploadToS3,
} from "../../../../../../services/api";
import { icons } from "../../../../../../utilities/icons";

function UploadContent({ closeModal, user, class_id }) {
  const previewFileRef = useRef(null);
  const previewVideoRef = useRef(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [values, setValues] = useState({
    title: "",
    description: "",
    video: "",
    image: "",
    color: "rgb(137, 4, 4)",
    uploaded_by: `${user.fname} ${user.lname}`,
    updated_by: `${user.fname} ${user.lname}`,
    class_id: class_id,
    teacher_id: user.teacher_id,
  });

  const clearForm = () => {
    setValues({
      title: "",
      description: "",
      video: "",
      image: "",
      color: "rgb(137, 4, 4)",
      uploaded_by: `${user.fname} ${user.lname}`,
      updated_by: `${user.fname} ${user.lname}`,
      class_id: class_id,
      teacher_id: user.teacher_id,
    });
    previewVideoRef.current.src = ""; // Clear video preview
    previewFileRef.current.src = ""; // Clear image preview
    setIsVideoUploaded(false);
    setIsImageUploaded(false);
  };

  const uploadContent = async (e) => {
    e.preventDefault();
    setActive(null);
    setError(null);
    setMessage("");

    const { title, video, image, color } = values;
    if (!title || !color) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }

    setIsProcessing(true);

    const updatedValues = {
      ...values,
      title: title.charAt(0).toUpperCase() + title.slice(1).toLowerCase(),
    };

    try {
      if (video) {
        try {
          const videoUrl = await getDatas(
            `/getS3Access/contents/videos/${video.name}`,
            setError
          );
          if (videoUrl && videoUrl.url) {
            await uploadToS3(videoUrl.url, video);
            updatedValues.video = videoUrl.url.split("?")[0];
            setIsVideoUploaded(true);
          }
        } catch (error) {
          console.log(error);
        }
      }

      if (image) {
        try {
          const imageUrl = await getDatas(
            `/getS3Access/contents/images/${image.name}`,
            setError
          );
          if (imageUrl && imageUrl.url) {
            await uploadToS3(imageUrl.url, image);
            updatedValues.image = imageUrl.url.split("?")[0];
            setIsImageUploaded(true);
          }
        } catch (error) {
          console.log(error);
        }
      }

      const res = await postDatas(
        `/teacher-api/contents/upload`,
        updatedValues,
        setError
      );

      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        clearForm(); // Clear form on success
      } else {
        setActive(3);
        setMessage(res.error);
      }
    } catch (error) {
      setError("An error occurred while uploading. Please try again.");
    }

    setIsProcessing(false);
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      if (name === "video") {
        previewFile(file, previewVideoRef.current);
        setValues({ ...values, video: file });
      } else if (name === "image") {
        previewFile(file, previewFileRef.current);
        setValues({ ...values, image: file });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.uploadContentContainer} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        Upload Content
        <button className={s.backButton} onClick={() => closeModal()}>
          Close
        </button>
      </div>

      <form className={s.form} onSubmit={uploadContent}>
        <input
          type="text"
          name="title"
          placeholder="Enter Title"
          className={s.input}
          value={values.title}
          onChange={handleChange}
        ></input>
        <textarea
          type="text"
          name="description"
          placeholder="Some Description..."
          className={s.textarea}
          value={values.description}
          onChange={handleChange}
        ></textarea>

        <span className={s.colorContainer}>
          <select
            name="color"
            value={values.color}
            onChange={handleChange}
            required
          >
            <option value="rgb(137, 4, 4)">red</option>
            <option value="#242629">dark</option>
            <option value="rgb(168, 168, 5)">yellow</option>
            <option value="rgb(15, 15, 96)">blue</option>
            <option value="rgb(9, 90, 9)">green</option>
            <option value="rgb(145, 94, 1)">orange</option>
            <option value="rgb(90, 12, 90)">violet</option>
          </select>
          <div
            className={s.colorPreview}
            style={{ backgroundColor: values.color }}
          ></div>
        </span>

        <div className={s.selectContainer}>
          <span>
            <label htmlFor="video" className={s.customVideo}>
              {icons.clipFill} Select Video
            </label>
            <input
              id="video"
              accept="video/*"
              type="file"
              name="video"
              className={s.video}
              onChange={handleFileChange}
            ></input>
            {isProcessing && !isVideoUploaded && (
              <p className={s.notif}>Video Uploading...</p>
            )}
          </span>
          <span>
            <label htmlFor="image" className={s.customImage}>
              {icons.clipFill} Select File
            </label>
            <input
              id="image"
              type="file"
              accept="image/*"
              name="image"
              className={s.image}
              onChange={handleFileChange}
            ></input>
            {isProcessing && !isImageUploaded && (
              <p className={s.notif}>Image Uploading...</p>
            )}
          </span>
        </div>
        <div className={s.inputFileContainer}></div>

        <div className={s.buttons}>
          <button type="submit" className={s.submitButton}>
            Create
          </button>
        </div>
      </form>

      <div className={s.previews}>
        <video
          className={s.videoPreview}
          ref={previewVideoRef}
          controls
        ></video>
        <img className={s.imgPreview} ref={previewFileRef}></img>
      </div>
    </div>
  );
}

export default UploadContent;
