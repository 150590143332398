import React, { useEffect,  Suspense } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";

import styles from "./App.module.css";
import RoleProvider from "./contexts/RoleContext";
import Default from "./pages/defaults/Default/Default";
import AdminRoutes from "./routes/Admin_Routes";
import StudentRoutes from "./routes/Student_Routes";
import TeacherRoutes from "./routes/Teacher_Routes";

import Unauthorized from "./pages/defaults/Unauthorized/Unauthorized";
import NotFound from "./pages/defaults/NotFound/NotFound";
import { Welcome } from "./pages/defaults/welcome";
import Signify from "./pages/Signify";
import Verification from "./pages/defaults/Verification/Verification";
import HostHome from "./pages/Signify/Main/pages/HostHome";
import ParticipantRoom from "./pages/Signify/Main/pages/ParticipantsHome";
import About from "./pages/defaults/About/About";
import Privacy from "./pages/defaults/Privacy/Privacy";
import Terms from "./pages/defaults/Terms/Terms";
import Contact from "./pages/defaults/Contact/Contact";

const App = () => {
  useEffect(() => {
    const themeChecker = localStorage.getItem("theme");
    if (!themeChecker) {
      localStorage.setItem("theme", JSON.stringify("silver"));
    }
  }, []);

  return (
    <div className={styles.App}>
      <RoleProvider>
        <BrowserRouter>
          <Suspense fallback={<Default active={1} />}>
            <Routes>
              <Route
                path="/admin/*"
                element={
                    <AdminRoutes />
                }
              />
              <Route
                path="/teacher/*"
                element={
                    <TeacherRoutes /> 
                }
              />
              <Route
                path="/student/*"
                element={
                    <StudentRoutes />
                }
              />

              <Route path="/" element={<Welcome />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/pending" element={<Verification />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/signify" element={<Signify />} />
              <Route path="/signify/hostRoom/:room_name/:host_name" element={<HostHome />} />
              <Route path="/signify/participantRoom/:room_name/:participant_name" element={<ParticipantRoom />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </RoleProvider>
    </div>
  );
};

export default App;
