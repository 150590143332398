import React, { useState } from "react";
// style
import s from "./style.module.css";
import style from "../../../components/style_configs/buttons.module.css";
// icons
import { icons } from "../../../../../utilities/icons";
// components
import Modal from "../../../components/Modal_layout/Modal";
import Enrollee from "../modals/enrollee/Enrollee";
import Request from "../modals/request/Request";
import Handles from "../modals/handles/Handles";
// methods
import { closeModal } from "../../../../../utilities/methods";

function Main({user}) {
  const [modalContent, setModalContent] = useState(<Request/>);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(null); 

  const openModal = (Component, content = "", reCount = () => {}) => {
    setModalContent(
      <Component
        content={content}
        user={user}
        closeModal={() => closeModal(setIsModalOpen)}
        reCount={reCount}
      />
    );
    setIsModalOpen(true);
  };

  const handleButtonClick = (buttonName, modalComponent) => {
    setActiveButton(buttonName); 
    openModal(modalComponent);
  };

  return (
    <>
      <section className={`${s.parent} ${s.fadeIn}`}>
        {!isModalOpen && (
          // <div className={s.cardsContainer}>
          //   <span className={s.card}>
          //     <p className={s.cardTitle}>COURSES CREATED</p>
          //     <p className={s.cardNumber}>5</p>
          //   </span>
          //   <span className={s.card}>
          //     <p className={s.cardTitle}>STUDENTS ONLINE</p>
          //     <p className={s.cardNumber}>5</p>
          //   </span>
          //   <span className={s.card}>
          //     <p className={s.cardTitle}>ENROLLMENT REQUESTS</p>
          //     <p className={s.cardNumber}>5</p>
          //   </span>
          //   <span className={s.card}>
          //     <p className={s.cardTitle}>STRANDS HANDLE</p>
          //     <p className={s.cardNumber}>5</p>
          //   </span> 
          // </div>
          <Request user={user}/>
        )}

        <div className={s.teachingContainer}>
          <div className={style.header}>TEACHING:</div>
          <div className={s.buttonContainer}>
            <span
              className={`${style.button} ${s.button} ${
                activeButton === "enrollees" ? s.activeButton : ""
              }`}
              onClick={() => handleButtonClick("enrollees", Enrollee)}
            >
              <b className={s.icon}>{icons.gradFill}</b> Check your
              Students
            </span>
            <span
              className={`${style.button} ${s.button} ${
                activeButton === "requests" ? s.activeButton : ""
              }`}
              onClick={() => handleButtonClick("requests", Request)}
            >
              <b className={s.icon}>{icons.notifFill}</b>Check Enrollment Requests
            </span>
            <span
              className={`${style.button} ${s.button} ${
                activeButton === "handles" ? s.activeButton : ""
              }`}
              onClick={() => handleButtonClick("handles", Handles)}
            >
              <b className={s.icon}>{icons.classLine}</b>Send Strand Handling Request
            </span>
          </div>
        </div>

       
      </section>
      {isModalOpen ? <Modal>{modalContent}</Modal> : ""}
    </>
  );
}

export default Main;
