import React, { useEffect, useState } from "react";
import s from "../css/modal.module.css";
import { getDatas, postDatas } from "../../../../../services/api";
import Popup from "../../../components/Popup/Popup";
import { icons } from "../../../../../utilities/icons";

function Add({ closeMail, user, strands }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [error, setError] = useState("");
  const [classes, setClasses] = useState([]);
  const [values, setValues] = useState({
    title: "",
    description: "",
    color: "rgb(137, 4, 4)",
    class_id: null,
    max_score: null,
    strand_id: null,
    participants: [],
  });

  const fetchParticipants = async () => {
    if (values.class_id) {
      try {
        const res = await getDatas(
          `/teacher-api/classworks/participants/${values.class_id}`,
          setError
        );
        if (res && res.response) {
          setValues({ ...values, participants: res.response });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchParticipants();
  }, [values.class_id]);

  const createClasswork = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    const { title, class_id, color } = values;

    if (!title || !color || !class_id) {
      setTimeout(() => {
        setActive(2);
        setMessage("Please check missing fields.");
      }, 0);
      return;
    }

    const newValues = {
      ...values,
      title: values.title.toUpperCase(),
    };

    const res = await postDatas(
      `/teacher-api/classworks/create`,
      newValues,
      setError
    );

    if (res && res.response) {
      setActive(1);
      setMessage(res.response);
    } else {
      setActive(3);
      setMessage(res?.error || "An error occurred while creating classwork.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelectChange = async (e) => {
    values.class_id = null;
    const { name, value } = e.target;
    if (name === "strand_id") {
      try {
        const res = await getDatas(
          `/teacher-api/classes/${value}/${user.teacher_id}`,
          setError
        );
        if (res && res.response) {
          setClasses(res.response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        <span>Create Classwork</span>
        <button onClick={closeMail}>Close</button>
      </div>

      <form className={s.addClassworkForm} onSubmit={createClasswork}>
        <div className={s.selectContainer}>
          <select name="strand_id" onChange={(e) => handleSelectChange(e)}>
            <option value={null}>Strand</option>
            {strands.map((str, i) => (
              <option value={str.strand_id} key={i}>
                {str.year + " - " + str.strand}
              </option>
            ))}
          </select>
          <select name="class_id" onChange={(e) => handleSelectChange(e)}>
            <option value={null}>Class</option>

            {classes.map((cl, i) => (
              <option value={cl.class_id} key={i}>
                {cl.title + " - " + cl.description}
              </option>
            ))}
          </select>
        </div>

        <input
          type="text"
          name="title"
          placeholder="Title "
          className={s.input}
          value={values.title}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Description"
          className={s.textarea}
          value={values.description}
          onChange={handleChange}
        />

        <span className={s.lastSection}>
          <span className={s.colorContainer}>
            <select name="color" onChange={handleChange} required>
              <option value="rgb(137, 4, 4)">red</option>
              <option value="#242629">dark</option>
              <option value="rgb(168, 168, 5)">yellow</option>
              <option value="rgb(15, 15, 96)">blue</option>
              <option value="rgb(9, 90, 9)">green</option>
              <option value="rgb(145, 94, 1)">orange</option>
              <option value="rgb(90, 12, 90)">violet</option>
            </select>
            <div
              className={s.colorPreview}
              style={{ backgroundColor: values.color }}
            ></div>

            <input
              type="number"
              name="max_score"
              placeholder="set max score "
              value={values.max_score}
              onChange={handleChange}
              required
            />
          </span>
          {values.class_id && (
            <div className={s.buttons}>
              <button type="submit" className={s.submitButton}>
                Create {icons.sendFill}
              </button>
            </div>
          )}
        </span>
      </form>
    </div>
  );
}

export default Add;
