import React, { useEffect, useState } from "react";
import Classes from "./Classes";
import { getDatas } from "../../../../../services/api";
import s from "../css/main.module.css";
import Default from "../../../../defaults/Default/Default";

function Main(props) {
  const { user } = props;
  const [error, setError] = useState("");
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStudentClasses = async () => {
    setLoading(true);
    try {
      const res = await getDatas(
        `/student-api/learn/classes/${user.student_id}`,
        setError
      );
      if (res && res.response) {
        setClasses(res.response);
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (user && user.strand_id && user.student_id) {
      fetchStudentClasses();
    }
  }, [user]);

  return (
    <section className={s.mainContainer}>
      {loading ? (
        <Default active={1} />
      ) : classes.length === 0 ? (
        <Default active={2} />
      ) :(
        <Classes
          user={user}
          classes={classes}
          fetchStudentClasses={fetchStudentClasses}
        />
      )}
    </section>
  );
}

export default Main;
