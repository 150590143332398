import React, { useEffect, useState } from "react";
import s from "../css/modals.module.css";
import { getDatas, postDatas, uploadToS3 } from "../../../../../services/api";
import Popup from "../../../components/Popup/Popup";
import { icons } from "../../../../../utilities/icons";

function Add({ closeMail, user }) {
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [error, setError] = useState("");
  const [newFile, setNewFile] = useState(null);
  const [values, setValues] = useState({
    title: "",
    content: "",
    file: "",
    expires_at: "",
    teacher_id: user.teacher_id,
    student_id: null,
    created_by: `${user.fname + " " + user.lname}`,
    updated_by: `${user.fname + " " + user.lname}`,
    class_id: null,
    strand_id: null,
  });
  const [strands, setStrands] = useState([]);
  const [classes, setClasses] = useState([]);

  const fetchDatas = async () => {
    try {
      const strResponse = await getDatas(
        `/teacher-api/strand/${user.teacher_id}`,
        setError
      );
      if (strResponse && strResponse.response) {
        setStrands(strResponse.response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, [user]);

  const createAnnouncment = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    const { title, content, expires_at, file } = values;
    if (!title || !content || !expires_at) {
      setActive(2);
      setMessage("Please fill out all required fields.");
      return;
    }
    if (newFile) {
      try {
        const { url } = await getDatas(
          `/getS3Access/announcements/files/${file.name}`,
          setError
        );
        if (url) {
          await uploadToS3(url, file);
          values.file = url.split("?")[0];
        }
      } catch (error) {
        console.log(error);
      }
    }

    const res = await postDatas(
      `/teacher-api/announcements/create`,
      values,
      setError
    );
    if (res && res.response) {
      setActive(1);
      setMessage(res.response);
    } else {
      setActive(3);
      setMessage(res.error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValues({ ...values, file });
      setNewFile(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelectChange = async (e) => {
    const { name, value } = e.target;
    if (name === "strand_id") {
      try {
        const res = await getDatas(
          `/teacher-api/classes/${value}/${user.teacher_id}`,
          setError
        );
        if (res && res.response) {
          setClasses(res.response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`${s.container} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        <span>Create Announcement</span>
        <button onClick={closeMail}>Close</button>
      </div>

      <form className={s.form} onSubmit={createAnnouncment}>
        <div className={s.selectContainer}>
          <select name="strand_id" onChange={(e) => handleSelectChange(e)}>
            <option value={null}>Strand</option>
            {strands.map((str) => (
              <option value={str.strand_id}>
                {str.year + " - " + str.strand}
              </option>
            ))}
          </select>
          <select name="class_id" onChange={(e) => handleSelectChange(e)}>
            <option value={null}>Class</option>

            {classes.map((cl) => (
              <option value={cl.class_id}>
                {cl.title + " - " + cl.description}
              </option>
            ))}
          </select>
        </div>

        <input
          type="text"
          name="title"
          placeholder="Subject "
          className={s.input}
          value={values.title}
          onChange={handleChange}
          required
        />
        <textarea
          name="content"
          placeholder="Message"
          className={s.textarea}
          value={values.content}
          onChange={handleChange}
          required
        />
        <span className={s.lastSection}>
          <div className={s.fileInputWrapper}>
            <input
              type="file"
              name="file"
              className={s.hiddenInput}
              onChange={handleFileChange}
              id="fileInput"
            />
            <label htmlFor="fileInput" className={s.clipButton}>
              {icons.clipFill}
            </label>
          </div>
          <input
            type="datetime-local"
            name="expires_at"
            className={s.expires}
            onChange={handleChange}
            id="dateInput"
          />

          <div className={s.buttons}>
            <button type="submit" className={s.submitButton}>
              Update {icons.sendFill}
            </button>
          </div>
        </span>
      </form>
    </div>
  );
}

export default Add;
