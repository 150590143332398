import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./css/login.module.css";
import sample from "../../../../assets/learn.gif";
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { RoleContext } from "../../../../contexts/RoleContext";
import { icons } from "../../../../utilities/icons";
import { postDatas } from "../../../../services/api";

function Login() {
  const navigate = useNavigate();
  const { globalStyles } = useContext(GlobalStyleContext);
  const { setStatus } = useContext(RoleContext);
  const [error, setError] = useState([]);
  const [isDatasWrong, setIsDatasWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    setError(null);
    e.preventDefault();
    setIsLoading(true); 

    const url = "/teacher-api/authentication/login";
    const datas = await postDatas(url, values, setError);

    if (datas && datas.response && datas.token) {
      const user = datas.response;
      console.log(datas);
      localStorage.setItem("token", datas.token);

      setStatus(true);
      localStorage.setItem("isLoggedIn", true);
      
      if (user?.status === "Pending") {
        const url = "/teacher-api/authentication/logout";
        const res = await postDatas(url, {}, setError);
        if (res) {
          navigate("/pending");
        }
      } else {
        navigate("/teacher/dashboard");
      }
    } else {
      setIsDatasWrong(true);
      console.log(error);
    }

    setIsLoading(false); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <div
        className={`${styles.container}`}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div
          className={styles.loginCard}
          style={{ backgroundColor: globalStyles.rtertiary }}
        >
          <div className={styles.formSection}>
            <div className={styles.header}>
              <span
                className={styles.title}
                style={{ color: globalStyles.primary }}
              >
                Teacher Login Page
              </span>
              <span
                className={styles.subtitle}
                style={{ color: globalStyles.secondary }}
              >
                Please login to access your account
              </span>
            </div>
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <div className={styles.inputGroup}>
                <input
                  className={styles.input}
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
                <input
                  className={styles.input}
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  required
                />
                {isDatasWrong && (
                  <p>{icons.warnLine}incorrect email or password</p>
                )}
              </div>
              <div className={styles.rememberMe}>
                <Link
                  to="/teacher/forgot"
                  className={styles.forgot}
                  style={{ marginLeft: "auto", color: globalStyles.primary }}
                >
                  Forgot password?
                </Link>
              </div>
              <div className={styles.buttons}>
                <button
                  type="submit"
                  className={styles.button}
                  style={{
                    backgroundColor: globalStyles.button,
                    color: globalStyles.buttonText,
                  }}
                  disabled={isLoading} 
                >
                  {isLoading ? "Logging In..." : "Log In"}
                </button>
              </div>
            </form>
          </div>
          <div className={styles.illustrationSection}>
            <div className={styles.illustration}>
              <img
                src={sample}
                alt="Illustration"
                loading="lazy"
                className={styles.image}
              />
            </div>
          </div>
        </div>
        <Link
          to="/"
          className={styles.backButton}
          style={{
            backgroundColor: globalStyles.button,
            color: globalStyles.buttonText,
          }}
        >
          <span className={styles.backButtonIcon}>{icons.backFill}</span>
          Back
        </Link>
      </div>
    </>
  );
}

export default Login;
