import React, {  useEffect, useState } from "react";
// style
import style from "../../../../components/css/buttons.module.css";
import s from "./css/accounts.module.css";
// components
import CreateTeacher from "./modals/CreateTeacher";
import CreateUser from "./modals/CreateUser";
import Center from "../../../../components/Center_layout/Center";
import { useNavigate } from "react-router-dom";
import { getDatas } from "../../../../../../services/api";

function Accounts({ closeModal }) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (Component) => {
    setModalContent(<Component closeModal={closeModal} />);
    setIsModalOpen(true);
  }; 
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      
      if (res && res.user) {
        setUser(res.user); 
      } else {
      console.log("redirecting")

        navigate("/unauthorized"); 

      }
    } catch (error) {
      console.error("Error fetching user data:", error); 
    }
  };
  

  useEffect(() => {
    requestUserData();
  }, []);

  return (
    <>
      {isModalOpen ? (
        <Center>{modalContent}</Center>
      ) : (
        <section className={s.parent}>
          <button className={style.modalButton} onClick={closeModal}>
            Dashboard
          </button>
          <div className={style.header}>CREATE ACCOUNT FOR</div>
          <div className={s.container}>
            <div className={s.card} onClick={() => openModal(CreateTeacher)}>
              Teacher
            </div>
            <div className={s.card} onClick={() => openModal(CreateUser)}>
              Student
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Accounts;
