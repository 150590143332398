import React from "react";
import s from "./about.module.css";
import Footer from "../../../components/Footer/Footer";
import bg from "../../../assets/whity.jpg";
import laud from "../../../assets/laud.png";
import max from "../../../assets/max.png";
import kyla from "../../../assets/kyla.png";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className={s.contactBackgroundContainer}>
        <img className={s.contactBackground} alt="blurred" src={bg}></img>
      </div>

      <section className={s.aboutContainer}>
        <div className={s.aboutHeader}>
          <img src={bg} alt="background" className={s.headerBackground}></img>
          <span className={s.aboutTitle}>About Us</span>
        </div>

        <h2>Our Mission</h2>
        <p>
          At [Your Company Name], our mission is to [describe your mission
          briefly]. We strive to provide the best services/products to help
          [describe what your company helps with or achieves].
        </p>

        <h2>Our Story</h2>
        <p>
          Founded in [founding year], [Your Company Name] started with a vision
          to [describe the initial vision or problem you aimed to solve]. Over
          the years, we have grown into [describe how the company has evolved,
          key milestones, or achievements].
        </p>

        <h2>Our Team</h2>
        <p>
          We are a diverse team of passionate professionals dedicated to
          delivering top-quality [products/services]. Our team members bring
          expertise in [mention relevant fields, e.g., software development,
          customer service, design].
        </p>

        <div className={s.ourTeamSection}>
          <h3 className={s.teamTitle}>Meet Our Team</h3>
          <div className={s.teamImages}>
            <div className={s.teamMemberContainer}>
              <img src={laud} alt="Laud Zion Cascalla" className={s.teamMember} />
              <span className={s.teamName}>Laud Zion Cascalla</span>
              <span className={s.teamRole}>Main Developer</span>
            </div>
            <div className={s.teamMemberContainer}>
              <img src={max} alt="Max Daniel De Silva" className={s.teamMember} />
              <span className={s.teamName}>Max Daniel De Silva</span>
              <span className={s.teamRole}>UI Designer</span>
            </div>
            <div className={s.teamMemberContainer}>
              <img src={kyla} alt="Kyla Andes" className={s.teamMember} />
              <span className={s.teamName}>Kyla Andes</span>
              <span className={s.teamRole}>Project Manager</span>
            </div>
          </div>
        </div>

        <h2>What We Do</h2>
        <p>
          We specialize in [describe your main services or products]. Whether
          it's [list some of the specific services or products], we aim to make
          a positive impact in [mention the field or audience you focus on].
        </p>

        <h2>Contact Us</h2>
        <p>
          Have questions? Feel free to{" "}
          <Link to="/contact" >
            reach out to us
          </Link>
          . We're here to help!
        </p>
      </section>
      <Footer />
    </>
  );
}

export default About;
