import React, { useState, useEffect } from "react";
// style
import style from "../../../../../components/style_configs/buttons.module.css";
import s from "../css/students.module.css";
// context
import { icons } from "../../../../../../../utilities/icons";
// components
import StudInfo from "../../../../../components/Modal_layout/StudInfo";
import Default from "../../../../../../defaults/Default/Default";
import {
  getDatas,
  postDatas,
  putDatas,
} from "../../../../../../../services/api";

function Students({ str, closeModal, user }) {
  const [enrollees, setEnrollees] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");

  const openModal = (Component, content) => {
    setModalContent(
      <Component
        content={content}
        closeStudentInfo={closeStudentInfo}
        onConfirm={acceptEnrollment}
        enrollees={enrollees}
        setEnrollees={setEnrollees}
        id={content.student_id}
      />
    );
    setIsModalOpen(true);
  };

  const closeStudentInfo = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const acceptEnrollment = async (enrollment_data) => {
    const { email, student_id, year, strand } = enrollment_data;
    setError(null);
    setActive(null);
    setMessage(null);

    const data = {
      strand: str.strand,
      year: str.year,
      student_id: student_id,
    };

    const emailData = {
      from: user.email,
      email: email,
      subject: "LMS Enrollment Status",
      text: `Your Enrollment Request to ${
        year + " " + strand
      } has been aproved by Instructor ${user.fname + " " + user.lname}. 
          You may now access the Learning Management System. Thank you and hope you learn more.`,
    };

    try {
      /**
       * update student strand and year
       */
      const update = await putDatas(
        "/teacher-api/enrollees/update-student-year-strand",
        data,
        setError
      );
      if (update && update.response) {
        /**
         * update student enrollment status
         */
        const accept = await putDatas(
          "/teacher-api/enrollees/accept",
          data,
          setError
        );
        if (accept && accept.response) {
          setActive(1);
          setMessage("Approval Complete");
          setEnrollees((prevEnrollees) =>
            prevEnrollees.filter(
              (enrollee) => enrollee.student_id !== student_id
            )
          );
        }
        /**
         * notify via email
         */
        await postDatas("/teacher-api/mailer", emailData, setError);
      }
    } catch (error) {
      setActive(3);
      setMessage("Approval Failed");
    }
  };

  const getEnrollmentRequests = async () => {
    const url = `/teacher-api/enrollees/requests/${str.strand_id}`;
    try {
      const datas = await getDatas(url, setError);
      if (datas && datas.response) {
        setEnrollees(datas.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEnrollmentRequests();
  }, []);

  return (
    <>
      {isModalOpen ? (
        <div className={`${s.modalContainer} ${s.fadeIn}`}>{modalContent}</div>
      ) : (
        <section className={`${s.parent} ${s.fadeIn}`}>
          <div className={s.header}>
            <span>
              <p>
                {str.year} - {str.strand}
              </p>
              ENROLLMENT REQUESTS
            </span>

            <button className={s.modalButton} onClick={closeModal}>
              Close
            </button>
          </div>
          {error ? (
            <Default text={"No Student Enrollment Requests Found"} active={3} />
          ) : loading ? (
            <Default
              text={"Loading Student Enrollment Requests, Please Wait..."}
              active={1}
            />
          ) : (
            <>
              {enrollees.length === 0 ? (
                <Default
                  text={"No Student Enrollment Requests Found"}
                  active={2}
                />
              ) : (
                <div className={s.cardContainer}>
                  {enrollees.map((en, i) => (
                    <div key={i} className={s.card}>
                      <div className={s.info}>
                        <span className={s.name}>
                          <b>
                            {en.fname} {en.lname}
                          </b>
                        </span>
                        <span className={s.name}>{en.email}</span>
                      </div>
                      <div className={s.buttons}>
                        <span
                          className={`${s.button} ${style.button}`}
                          onClick={() => openModal(StudInfo, en)}
                        >
                          INFO
                        </span>
                        <span
                          className={`${s.button} ${style.button}`}
                          onClick={() => acceptEnrollment(en)}
                        >
                          {icons.checkFill}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

export default Students;
