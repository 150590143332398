import React, { useEffect, useState } from "react";
import Center from "../../components/Center_layout/Center";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import { getDatas } from "../../../../services/api";
import style from "./Settings.module.css";
import s from "../../components/css/top_side_main.module.css";
import Main from "./Main";
import { useNavigate } from "react-router-dom";

function Settings() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  return (
    <>
      <div className={style.SettingsContainer}>
        <header className={s.header}>
          {user && <Header title="LMS" user={user} />}
        </header>
        <aside className={s.sidebar}>
          <Sidebar />
        </aside>
        <section className={s.main}>
          <Center>
            <Main user={user} />
          </Center>
        </section>
      </div>
    </>
  );
}

export default Settings;
