import React, { useEffect, useState } from "react";
// styles
import s from "./css/strands.module.css";
// icons
import { icons } from "../../../../../../utilities/icons";
// component
import Default from "../../../../../defaults/Default/Default";
import Prompt from "../../../../components/Prompt/Prompt";
import Top from "./Top";
import Edit from "./modal/Edit";
import Popup from "../../../../components/Popup/Popup";
import View from "./modal/View";
import {
  deleteDatas,
  getDatas,
  postDatas,
} from "../../../../../../services/api";
import { useNavigate } from "react-router-dom";
import Mail from "../../../../components/Mail_layout/Mail";

function Strands() {
  const [strands, setStrands] = useState([]);
  const [strandsCopy, setStrandsCopy] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [title, setTitle] = useState("Collaborators");
  const [loading, setLoading] = useState(true);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  // ***************************************************
  // toggles
  // ***************************************************
  const toggleCollaborators = (strand_id) => {
    setIsDropDownOpen(isDropdownOpen === strand_id ? false : strand_id);
    setTeachers([]);
    setTitle("Collaborators");
    setCollaborators([]);
  };
  // ***************************************************
  // delete strand
  // ***************************************************
  const goConfirm = async (confirmation, strand_id, imageUrl, videoUrl) => {
    setError(null);
    setActive(null);
  
    if (confirmation) {
      try {
        // Step 1: Delete the image from S3 if imageUrl is present
        if (imageUrl) {
          console.log(imageUrl)
          const imageFilename = imageUrl.image.split("/").pop(); 
          const folder = "strands";
          const role = "admin"; 
  
          // Call the API to delete the image from S3
          const deleteImageUrl = `/deleteS3Asset/${folder}/${role}/${imageFilename}`;
          const deleteImageRes = await deleteDatas(deleteImageUrl, null, setError);
  
          if (!deleteImageRes) {
            throw new Error("Failed to delete image from S3");
          }
        }
  
        // Step 2: Delete the strand record from your database
        const url = `/admin-api/delete/strand`;
        const datas = { strand_id };
        const res = await deleteDatas(url, datas, setError);
  
        if (res) {
          setActive(1);
          setMessage("Deleting Strand Successful");
  
          // Step 3: Update the UI to remove the deleted strand
          setStrands((prev) => prev.filter((str) => str.strand_id !== strand_id));
        } else {
          setActive(3);
          setMessage("Deleting Strand Failed");
        }
      } catch (error) {
        console.log(error);
        setError(error.message || "An error occurred");
        setActive(3);
      }
    }
  
    closePrompt();
  };
  
  // ***************************************************
  // Add or remove a teacher from the collaborators array
  // ***************************************************
  const handleCheck = (e, collab_id, strand_id, teacher_id) => {
    const checked = e.target.checked;

    setCollaborators((prev) => {
      if (checked) {
        const collaborator_id = collab_id ? collab_id : "";
        return [...prev, { collaborator_id, strand_id, teacher_id }];
      } else {
        return prev.filter(
          (collaborator) => collaborator.teacher_id !== teacher_id
        );
      }
    });
  };
  // ***************************************************
  // add multiple collaborators
  // ***************************************************
  const Submit = async () => {
    setActive(null);
    setError(null);
    setMessage(null);

    if (collaborators.length === 0) {
      setActive(2);
      setMessage("No Selected Teacher, Please Select teacher");
      return;
    }

    const addUrl = `/admin-api/post/collaborator`;
    const delUrl = `/admin-api/delete/collaborator`;
    const operation = title.trim().toLocaleLowerCase();

    for (const datas of collaborators) {
      setError(null);
      try {
        const res =
          operation === "add"
            ? await postDatas(addUrl, datas, setError)
            : await deleteDatas(delUrl, datas, setError);

        if (res && res.response) {
          setActive(1);
          setMessage("Operation Successfull");
          setCollaborators([]);
        } else {
          setActive(3);
          setMessage("Operation Failed");
        }
      } catch (error) {
        setActive(3);
        setMessage("Operation Failed");
        console.log(error);
      }
    }
  };
  // ***************************************************
  // Fetch strands from the API
  // ***************************************************
  const getStrands = async () => {
    setError(null);
    const url = `/admin-api/get/strands`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setStrands(res.response);
        setStrandsCopy(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  };
  // ***************************************************
  // Fetch collaborators of strand
  // ***************************************************
  const getCollaborators = async (strand_id) => {
    setTitle("Remove");
    setError(null);
    setTeachers([]);
    const url = `/admin-api/get/collaborators/${strand_id}`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setTeachers(res.response);
      }
    } catch (error) {
      console.log(error)
    }
  
  };
  // ***************************************************
  // Fetch not collaborators of strand
  // ***************************************************
  const getNotCollaborators = async (strand_id) => {
    setTitle("Add");
    setError(null);
    setTeachers([]);
    const url = `/admin-api/get/not-collaborators/${strand_id}`;
    try {
      const res = await getDatas(url, setError);
      if (res && res.response) {
        setTeachers(res.response);
      }
    } catch (error) {
      console.log(error)
    }
   
  };

  const openModal = (Component, str = "") => {
    setModalContent(
      <Component
        datas={str}
        closeModal={closePrompt}
        closeStudentInfo={() => setIsModalOpen(false)}
        onConfirm={goConfirm}
        id={str.strand_id}
        imageUrl={str.image || ""}
        videoUrl={str.video || ""}
      />
    );
    getStrands();
    setIsModalOpen(!isModalOpen);
  };

  const closePrompt = () => {
    getStrands();
    setIsModalOpen(false);
  };

  useEffect(() => {
    getStrands();
  }, []);

  return (
    <>
      <Popup message={message} active={active} />

      <Top
        openModal={openModal}
        strandsCopy={strandsCopy}
        setStrands={setStrands}
      />

      {isModalOpen ? (
        <Mail>{modalContent}</Mail>
      ) : (
        <section className={s.parent}>
          {strands.length === 0 ? (
            <Default  active={2} />
          ) : loading ? (
            <Default
              active={1}
            />
          ) : (
            <div className={s.container}>
              {strands.map((str) => (
                <div key={str.strand_id} className={s.card}>
                  <div className={s.header}>
                    <span
                      className={s.icon}
                      onClick={() => toggleCollaborators(str.strand_id)}
                    >
                      {icons.shareFill}
                    </span>
                  </div>

                  <div className={s.main}>
                    <span className={s.imageContainer}>
                      <img
                        loading="lazy"
                        src={str.image}
                        className={s.image}
                        alt="template"
                      />
                      {isDropdownOpen === str.strand_id && (
                        <div className={s.teachers}>
                          <div className={s.top}>
                            <span>{title}</span>
                            {/* top buttons */}
                            <span className={s.buttons}>
                              {collaborators.length > 0 ? (
                                <button
                                  className={s.save}
                                  onClick={Submit}
                                >
                                  Save Changes
                                </button>
                              ) : (
                                <>
                                  <button
                                    className={s.addCollab}
                                    onClick={() =>
                                      getNotCollaborators(str.strand_id)
                                    }
                                  >
                                    {icons.addFill}
                                  </button>
                                  <button
                                    className={s.removeCollab}
                                    onClick={() =>
                                      getCollaborators(str.strand_id)
                                    }
                                  >
                                    {icons.deleteFill}
                                  </button>
                                </>
                              )}
                            </span>
                          </div>

                          <div className={s.bot}>
                            <div className={s.collaborators}>
                              {teachers.length === 0 ? (
                                <Default
                                  active={2}
                                />
                              ) : (
                                teachers.map((tr) => (
                                  <div
                                    className={s.teacher}
                                    key={tr.teacher_id}
                                  >
                                    <span className={s.botLeft}>
                                      {tr.fname + tr.lname}
                                    </span>
                                    <button
                                      className={s.info}
                                      onClick={() => openModal(View, tr)}
                                    >
                                      info
                                    </button>
                                  </div>
                                ))
                              )}
                            </div>
                            {/* toggle this only if button add or delete is clicked */}
                            <div className={s.notCollaborators}>
                              {teachers.length === 0 ? (
                                <Default
                                  active={2}
                                />
                              ) : (
                                teachers.map((tr) => (
                                  <div
                                    className={s.teacher}
                                    key={tr.teacher_id}
                                  >
                                    <span className={s.botLeft}>
                                      {tr.fname + tr.lname}
                                    </span>
                                    <button
                                      className={s.botRight}
                                      onClick={() => openModal(View, tr)}
                                    >
                                      info
                                    </button>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleCheck(
                                          e,
                                          tr.collaborator_id,
                                          str.strand_id,
                                          tr.teacher_id
                                        )
                                      }
                                    />
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                    <span className={s.content}>
                      {str.year} - {str.strand}
                    </span>
                  </div>
                  <div className={s.buttons}>
                    <button onClick={() => openModal(Edit, str)}>
                      <span className={s.icon}>{icons.updateFill}</span>
                    </button>
                    <button onClick={() => openModal(Prompt, str)}>
                      <span className={s.icon}>{icons.deleteFill}</span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
}

export default Strands;
