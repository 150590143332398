import React, { useEffect, useState } from "react";
import s from "./style.module.css";
import { icons } from "../../../../utilities/icons";
import { updateData } from "../../../../utilities/methods";
import Modal from "./Modal";
import Email from "./modal/Email";
import Prompt from "../Prompt/Prompt";
import Popup from "../Popup/Popup";
import { useNavigate } from "react-router-dom";
import { getDatas } from "../../../../services/api";

function StudInfo({ content, closeStudentInfo, setEnrollees }) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  const openModal = (Component, content) => {
    setModalContent(
      <Component
        closeModal={closeModal}
        // prompt
        user={user}
        onConfirm={goConfirm}
        id={content.student_id}
        datas={content}
      />
    );
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const goConfirm = async (confirmation, student_id) => {
    setError(null);
    setMessage(null);
    setActive(null);

    if (confirmation) {
      try {
        const url = `/teacher-api/enrollees/drop`;
        const datas = {
          student_id: student_id,
        };

        const res = await updateData(url, datas, setError);

        if (res) {
          setActive(1);
          setMessage("Student Dropping Successfull");
          // !error update UI
          setEnrollees((prev) =>
            prev.filter((enrollee) => enrollee.student_id !== student_id)
          );
        }
      } catch (error) {
        setActive(3);
        setMessage("Failed to Drop Student");
        console.log(error);
      }
    }
    closeStudentInfo();
  };

  return (
    <>
      <Popup message={message} active={active} />

      {isModalOpen ? (
        <Modal>{modalContent}</Modal>
      ) : (
        <Modal>
          <div className={s.infoContainer}>
            <div className={s.header}>
              STUDENT INFORMATION
              <button className={s.modalButton} onClick={closeStudentInfo}>
                Close
              </button>
            </div>

            <div className={s.studInfo}>
              <span className={s.studImage}>
                <img
                  src={content.image}
                  className={s.image}
                  loading="lazy"
                  alt="profile"
                ></img>
              </span>
              <b className={s.label}>Name: </b>
              {`${content.fname} ${
                content.mname != null ? content.mname + "." : " "
              } ${content.lname}`}
              <br />
              <b className={s.label}>Contact: </b>
              {content.contact}
              <br />
              <b className={s.label}>Email: </b>
              {content.email}
              <br />
            </div>
            <div className={s.buttons}>
              <button
                type="button"
                className={s.emailButton}
                onClick={() => openModal(Email, content)}
              >
                <span className={s.icon}>{icons.emailFill}</span>
              </button>
            
              <button
                type="button"
                className={s.dropButton}
                onClick={() => openModal(Prompt, content)}
              >
                Drop
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default StudInfo;
