import { startVideo } from "./startVideo";
import { loadHandPoseModel } from "./loadHandposeModel";
import { detectGestures } from "./detectGestures";
import { GestureAllocator } from "./GestureAllocatorClass";
import * as fp from "fingerpose";

export async function initialize(videoRef, canvasRef, setDetectedLetter, size, dominantHand) {
  // start the video inference
  await startVideo(videoRef, size);
  // load the handpose model
  const model = await loadHandPoseModel();

  // set up gestures by dominantHand
  const allocator = new GestureAllocator(dominantHand);
  // get all gestures allocated
  const gestures = allocator.getAllGestures();
  // mount the gestures to the recognition model
  const gestureEstimator = new fp.GestureEstimator(gestures);

  detectGestures(model, gestureEstimator, videoRef, canvasRef, setDetectedLetter);
}
