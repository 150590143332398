// A.js
export function setLetterT(fp) {
    const letterT = new fp.GestureDescription("T");
  
    letterT.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    letterT.addCurl(fp.Finger.Index, fp.FingerCurl.HalfCurl, 1.0);
    letterT.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
    letterT.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0);
    letterT.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);
  

    // letterT.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp, 0.8);
    letterT.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpRight, 0.8);
    letterT.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpRight, 0.8);

    return letterT;
  }
  