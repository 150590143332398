import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// components
import Center from "../../../components/Center_layout/Center";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Main from "../parts/modules/modules_content/Main";
import Default from "../../../../defaults/Default/Default";
// style
import s from "../../../components/style_configs/top_side_main.module.css";
import style from "./style.module.css";
//methods
import { getDatas } from "../../../../../services/api";

function Modules() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
  }, []);

  return (
    <>
      {user ? (
        <div className={style.moduleContainer}>
          <header className={s.header}>
            <Header title="MODULES" user={user} />
          </header>
          <aside className={s.sidebar}>
            <Sidebar />
          </aside>
          <section className={s.main}>
            <Center>
              {error ? <Default active={3} /> : <Main user={user} />}
            </Center>
          </section>
        </div>
      ) : (
        <Default active={1} />
      )}
    </>
  );
}

export default Modules;
