import { setLetterA } from "./A.js";
import { setLetterB } from "./B.js";
import { setLetterC } from "./C.js";
import { setLetterD } from "./D.js";
import { setLetterE } from "./E.js";
import { setLetterF } from "./F.js";
import { setLetterG } from "./G.js";
import { setLetterH } from "./H.js";
import { setLetterI } from "./I.js";
import { setLetterJ } from "./J.js";
import { setLetterK } from "./K.js";
import { setLetterL } from "./L.js";
import { setLetterM } from "./M.js";
import { setLetterN } from "./N.js";
import { setLetterO } from "./O.js";
import { setLetterP } from "./P.js";
import { setLetterQ } from "./Q.js";
import { setLetterR } from "./R.js";
import { setLetterS } from "./S.js";
import { setLetterT } from "./T.js";
import { setLetterU } from "./U.js";
import { setLetterV } from "./V.js";
import { setLetterW } from "./W.js";
import { setLetterX } from "./X.js"; 
import { setLetterY } from "./Y.js";
import { setLetterZ } from "./Z.js";
import { setIloveyou } from "./Iloveyou.js";
import { setFuckyou } from "./Fuckyou.js";
import { setSpace } from "./Space.js";
import { setDelete } from "./Delete.js";

export const left = {
  A: setLetterA,
  B: setLetterB,
  C: setLetterC,
  D: setLetterD,
  E: setLetterE,
  F: setLetterF,
  G: setLetterG,
  H: setLetterH,
  I: setLetterI,
  J: setLetterJ,
  K: setLetterK,
  L: setLetterL,
  M: setLetterM,
  N: setLetterN,
  O: setLetterO,
  P: setLetterP,
  Q: setLetterQ,
  R: setLetterR,
  S: setLetterS,
  T: setLetterT,
  U: setLetterU,
  V: setLetterV,
  W: setLetterW,
  X: setLetterX,
  Y: setLetterY,
  Z: setLetterZ,
  Iloveyou: setIloveyou,
  Fuckyou: setFuckyou,
  Space: setSpace,
  Delete: setDelete
};
