export function setLetterA(fp) {
    const letterA = new fp.GestureDescription("A");
  
    letterA.addCurl(fp.Finger.Thumb, fp.FingerCurl.NoCurl, 1.0);
    letterA.addCurl(fp.Finger.Index, fp.FingerCurl.FullCurl, 1.0);
    letterA.addCurl(fp.Finger.Middle, fp.FingerCurl.FullCurl, 1.0);
    letterA.addCurl(fp.Finger.Ring, fp.FingerCurl.FullCurl, 1.0); 
    letterA.addCurl(fp.Finger.Pinky, fp.FingerCurl.FullCurl, 1.0);
 
    letterA.addDirection(fp.Finger.Thumb, fp.FingerDirection.VerticalUp,1.0);  
    letterA.addDirection(fp.Finger.Thumb, fp.FingerDirection.DiagonalUpRight, 0.4); 
    // letterA.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpRight, 0.8);
    // letterA.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpRight, 0.8);
 

    letterA.addDirection(fp.Finger.Index, fp.FingerDirection.VerticalUp, 1.0);  
    // letterA.addDirection(fp.Finger.Index, fp.FingerDirection.DiagonalUpLeft, 0.6);  

    letterA.addDirection(fp.Finger.Middle, fp.FingerDirection.VerticalUp, 1.0);  
    // letterA.addDirection(fp.Finger.Middle, fp.FingerDirection.DiagonalUpLeft, 0.6);  

    letterA.addDirection(fp.Finger.Ring, fp.FingerDirection.VerticalUp, 1.0);  
    // letterA.addDirection(fp.Finger.Ring, fp.FingerDirection.DiagonalUpLeft, 0.6);  
    
    // letterA.addDirection(fp.Finger.Pinky, fp.FingerDirection.VerticalUp, 0.8);  
    letterA.addDirection(fp.Finger.Pinky, fp.FingerDirection.DiagonalUpLeft, 1.0);  
  
    return letterA;
  }
  