import React, { useEffect, useState } from 'react';
import layout from '../css/layout.module.css';
import s from '../css/style.module.css';
import JoinRoom from './JoinRoom';

function RoomList({createRoom ,socket}) {
  const [rooms, setRooms] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roomDetails, setRoomDetails] = useState(null);

  const openModal = (r) => {
    setIsModalOpen(!isModalOpen);
    console.log(r);
    setRoomDetails(r)
  }

  useEffect(() => {
    socket.on("rooms", (datas) => {
      setRooms(datas);
    });

    return () => {
      socket.off("rooms");
    };
  }, []);

  return (
    <div className={layout.roomsContainer}>
      {isModalOpen && (
        <JoinRoom roomDetails={roomDetails} />
      )}
      <div className={s.card}>
        <table>
          <thead>
            <tr><th>Host</th><th>Room</th><th>
          <div className={s.buttonContainer}>
            <button type='button' onClick={createRoom} >
              Create Room
            </button>
          </div>
              </th></tr>
          </thead>
          <tbody>
            {rooms.map((r, i) => (
              <tr key={i}>
                <td>{r.host_name}</td>
                <td>{r.room_name}</td>
                <td>
                  <button
                    className={s.joinButton}
                    type='button'
                    onClick={() => openModal(r)}
                  >
                    Join Room
                  {r.room_state === "secured" && "🔒"}

                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RoomList;
