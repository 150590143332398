import React, { useEffect, useState } from "react";
import s from "./sendmail.module.css"; 
import { getDatas, postDatas } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup/Popup";

function SendMail({ setIsMailOpen }) {
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showRecipients, setShowRecipients] = useState(false);
  const [notification, setNotification] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState({});
  const [recipientList, setRecipientList] = useState([]);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");

  const getStudents = async () => {
    try {
      const res = await getDatas("/teacher-api/enrollees/all", setError);
      if (res && res.response) {
        setRecipientList(res.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const requestUserData = async () => {
    try {
      const res = await getDatas("/profile/data", setError);
      if (res && res.user) {
        setUser(res.user);
      } else {
      console.log("redirecting")

        navigate("/unauthorized");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestUserData();
    getStudents();
  }, []);

  const handleSelectEmail = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(selectedEmails.filter((e) => e !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(recipientList.map((user) => user.email));
    }
    setSelectAll(!selectAll);
  };

  const sendMail = async (e) => {
    setActive(null);

    setShowRecipients(false);
    e.preventDefault();

    if (selectedEmails.length === 0) {
      setTimeout(() => {
        setActive(2);
        setMessage("Please Select Mail Recipient");
      }, 0);
      return;
    }

    const emailData = {
      from: user.email,
      email: selectedEmails,
      subject,
      text,
    };

    try {
      const response = await postDatas(
        "/teacher-api/mailer",
        emailData,
        setError
      );
      if (response && response.response) {
        setActive(1);
        setMessage(response.response);
      } else {
        setActive(3);
        setMessage(response.error);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const filteredRecipients = recipientList.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className={`${s.sendMailContainer} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <div className={s.header}>
        <span>
          Send Email
          <button
            type="button"
            className={s.toggleRecipientsButton}
            onClick={() => setShowRecipients((prev) => !prev)}
          >
            {showRecipients ? "Hide Recipients" : "Add Recipients"}
          </button>
        </span>
        <div className={s.closeButton} onClick={() => setIsMailOpen(false)}>
          Close
        </div>
      </div>
      {notification && <div className={s.notification}>{notification}</div>}
      <form onSubmit={sendMail} className={s.mailForm}>
        <div className={s.inputGroup}>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className={s.inputGroup}>
          <label htmlFor="text">Message:</label>
          <textarea
            id="text"
            rows="5"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          ></textarea>
        </div>

        {showRecipients && (
          <div className={s.recipientList}>
            <div className={s.recipientHeader}>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              Select All
              <div className={s.searchContainer}>
                <input
                  type="text"
                  placeholder="Search by email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {filteredRecipients.map((user, index) => (
              <div key={index} className={s.checkboxContainer}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedEmails.includes(user.email)}
                    onChange={() => handleSelectEmail(user.email)}
                  />
                  {user.email}
                </label>
              </div>
            ))}
          </div>
        )}

        <button type="submit" className={s.submitButton}>
          Send Email
        </button>
      </form>
    </section>
  );
}

export default SendMail;
