import React from 'react';
import styles from './verification.module.css';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Account status is Pending...<br></br>
      Please wait for Admin Approval</h1>
      <p className={styles.message}>You do not have permission to view this page.</p>
      <button className={styles.button} onClick={handleGoBack}>Go Back to Home</button>
    </div>
  );
}; 

export default Verification;
 