import React, {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import { icons } from "../../../../../../../utilities/icons";
import s from "./style.module.css";
import style from "../../../../../components/style_configs/buttons.module.css";
import Edit from "../../../classes/modals/Edit";
import Top from "../classes_top/Top";
import Prompt from "../../../../../components/Prompt/Prompt";
import Default from "../../../../../../defaults/Default/Default";
import Popup from "../../../../../components/Popup/Popup";
import { deleteDatas } from "../../../../../../../services/api";
import Mail from "../../../../../components/Mail_layout/Mail";
import Announce from "./Announce";

function Main({ 
  Classes,
  strand_id,
  teacher_id,
  setClasses,
  copy,
  fetchClasses,
  user
}) {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [activeMenuButton, setActiveMenuButton] = useState(null);
  const [activeCopiedNotif, setActiveNotifButton] = useState(null);

  const openModal = (Component, datas, e) => {
    e.stopPropagation();
    setModalContent(
      <Component
        closeModal={closeModal}
        teacher_id={teacher_id}
        strand_id={strand_id}
        // prompt
        datas={datas}
        id={datas.class_id}
        onConfirm={goDelete}
      />
    );
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    fetchClasses(user.teacher_id);
    setIsModalOpen(false);
    setModalContent(null);
  };

  const goDelete = async (confirmation, class_id) => {
    setError(null);
    setActive(null);
    setMessage(null);

    if (confirmation) {
      try {
        const url = `/teacher-api/classes/delete`;
        const datas = {
          class_id,
        };
        const res = await deleteDatas(url, datas, setError);

        if (res && res.response) {
          setActive(1);
          setMessage(res.response);
          setClasses((prev) => prev.filter((cl) => cl.class_id !== class_id));
        }
      } catch (error) {
        setActive(3);
        setError("Deletion Failed");
      }
    }
    closeModal();
  };

  const viewClass = (c) => {
    const { class_id, title, description } = c;
    navigate(`${class_id}/${title}/${description}/modules`);
  };

  const viewButtonMenu = (class_id, e) => {
    e.stopPropagation();
    setActiveMenuButton((prev) => (prev === class_id ? null : class_id));
  };

  const copyCode = (code, class_id, e) => {
    setActiveNotifButton((prev) => (prev === class_id ? null : class_id));

    e.stopPropagation();
    navigator.clipboard.writeText(code);

    setTimeout(() => {
      setActiveNotifButton(null);
    }, 1000);
  };

  return (
    <div className={`${s.parentContainer} ${s.fadeIn}`}>
      <Popup message={message} active={active} />

      <Top openModal={openModal} setClasses={setClasses} copy={copy} />

      <>
        {Classes.length === 0 ? (
          <Default
            active={2}
          />
        ) : (
          <div className={s.parent}>
            <div className={s.container}>
              {Classes.map((c, i) => (
                <div className={s.col} key={i} onClick={() => viewClass(c)} style={{backgroundColor:c.color}}>
                  <div className={s.card}>
                    <div
                      className={s.template}
                    >
                      <span
                        className={s.menuToggler}
                        onClick={(e) => viewButtonMenu(c.class_id, e)}
                      >
                        {icons.kebabFill}
                      </span>

                      <span className={s.subject}> {c.title}</span>
                      <span className={s.logoIcon}>{icons.gradFill}</span>
                      {activeMenuButton === c.class_id && (
                        <div className={s.buttonContainer}>
                          <button
                            className={style.button}
                            onClick={(e) => openModal(Edit, c, e)}
                          >
                            {icons.updateFill}
                          </button>
                          <button
                            className={style.button}
                            onClick={(e) => openModal(Prompt, c, e)}
                          >
                            {icons.deleteFill}
                          </button>
                        </div>
                      )}

                      <button
                        onClick={(e) => openModal(Announce, c, e)}
                        className={s.announceButton}
                        style={{backgroundColor: c?.color}}
                      >
                        {icons.hornFill} Announce
                      </button>
                    </div>
                    <div className={s.cardBody}>
                      <span>
                        <h5 className={s.cardTitle}>
                          {icons.classLine} {c.description}
                        </h5>
                        <p
                          className={s.cardText}
                          onClick={(e) => copyCode(c.code, c.class_id, e)}
                        >
                          {c.code}{" "}
                          <button className={s.clipboardButton}>
                            {icons.copyFill}
                          </button>{" "}
                        </p>
                      </span>
                      {activeCopiedNotif === c.class_id && (
                        <p className={s.notification}>Code Copied</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
      {isModalOpen && (
        <Mail title="Modal Title" isClose={closeModal} isOpen={isModalOpen}>
          {modalContent}
        </Mail>
      )}
    </div>
  );
}

export default Main;
