import React from "react";
import Header from "./parts/Header";
import Main from "./parts/Main";
import Footer from "../../../components/Footer/Footer";


function Welcome() {
  return (
    <>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </>
  );
}

export default Welcome;
