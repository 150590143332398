import { v4 } from "uuid";
import { storage } from "../configs/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const DOMAIN = process.env.REACT_APP_API_URL;

export const toUrlSafe = (str) =>
  encodeURIComponent(str.trim().replace(/\s+/g, "-"));
 
export const formatDate = (getDate) => {
  const date = new Date(getDate); 
  const options = {
    year: "numeric",   
    month: "long", 
    day: "numeric",  
    hour: "2-digit", 
    minute: "2-digit",
  }; 
  return date.toLocaleDateString("en-US", options); 
};

export const navigateTo = (navigate, url) => {
  navigate(url);
};

export const closeModal = (setIsModalOpen) => {
  setIsModalOpen(false);
};

// upload file to firebase
export const handleUpload = async (file, path) => {
  const storageRef = ref(storage, `${path}/${file.name + v4()}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

// file preview
export const previewFile = (file, previewRef) => {
  if (file && previewRef) {
    const objectURL = URL.createObjectURL(file);
    previewRef.src = objectURL;
  } else {
    console.error("Invalid file or preview reference.");
  }
};


export const cleanDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric',hour:'numeric',minute:'2-digit',hour12:true });
}

// code generator
export const randomCode = () => {
  const codeLength = 6;
  const chars = "abcdefghijklmnopqrstuvwxyz1234567890!@#$";
  const array = chars.split("");
  const charValues = Object.values(array);
  const charLength = charValues.length;
  const code = randomChar();

  function randomChar() {
    let codeText = "";
    let code = [];
    for (let i = 0; i < codeLength; i++) {
      const random = Math.random();
      const index = Math.floor(random * charLength);
      code.push(charValues[index]);
    }
    codeText = code.join("");
    return codeText;
  }

  return code;
};

//auto scroll to top
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToBot = () => {
  window.scrollTo({
    top: -100, 
    behavior: "smooth",
  });
};
 
export const cleanString = (str) => {
  try { 
    let first = str.trim().toLowerCase();
    let second = first.replace(" ", "");
    return second;
  } catch (error) {
    console.error(error);
  }
};


export const handleAssetPreview = (file, assetRef) => {
  const assetURL = URL.createObjectURL(file); 
  assetRef.src = assetURL; 

  return () => URL.revokeObjectURL(assetURL);
};

export const handleToggler = (getter,setter) => {
  setter(!getter);
}

export const convertBase64ToFile = (base64String, fileName) => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], fileName, {type:mime});
}

// get data==========================================================================
export const fetchData = async (url, setError) => {
  setError(null);
  try {
    const response = await fetch(DOMAIN + url);
    if (!response.ok) {
      setError("Fetching Error");
      return null;
    }
    const res = await response.json();
    return res.response;
  } catch (error) {
    setError("Fetching Error");
    console.log(error);
    return null; 
  }
};

// post or add==========================================================================
export const postData = async (url, data, setError) => {
  setError(null); // Reset errors before the request
  try {
    const response = await fetch(DOMAIN + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setError("Error Posting Data");
      return null;
    }

    const res = await response.json();
    return { ...res.response, message: res.message };
  } catch (error) {
    setError("Error Posting Data");
    console.log(error);
    return null;
  }
};

//update data ==========================================================================
export const updateData = async (url, data, setError) => {
  setError(null);
  try {
    const response = await fetch(DOMAIN + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json", // Set the appropriate headers
      },
      body: JSON.stringify(data), // Send the data in the request body
    });

    if (!response.ok) {
      setError("Error Updating Data");
      return null;
    } 

    const res = await response.json();
    return { ...res.response, message: res.message };
  } catch (error) {
    setError("Error Updating Data");
    console.log(error);
    return null;
  }
};

//delete data ==============================================================================
export const deleteData = async (url, data, setError) => {
  setError(null);
  try {
    const response = await fetch(DOMAIN + url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setError("Error Deleting Data");
      return null;
    }

    const res = await response.json();
    return res.response;
  } catch (error) {
    setError("Error Deleting Data");
    console.log(error);
    return null;
  }
};
