import React, { useEffect, useState } from "react";
import { getDatas, putDatas, postDatas } from "../../../../../services/api";
import s from "../css/view.module.css";
import Default from "../../../../defaults/Default/Default";
import Popup from "../../../components/Popup/Popup";
import { icons } from "../../../../../utilities/icons";

function View({ user, datas, closeMail }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [participants, setParticipants] = useState([]);
  const [updatedScores, setUpdatedScores] = useState({});
  const [message, setMessage] = useState("");
  const [active, setActive] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [classParticipants, setClassParticipants] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newParticipantScores, setNewParticipantScores] = useState({});
  const [focusedStudentId, setFocusedStudentId] = useState(null);

  // Fetching current classwork participants
  const getAllClassworkParticipants = async () => {
    setLoading(true);
    try {
      const res = await getDatas(
        `/teacher-api/classworks/classwork-participants/${datas.classwork_id}`,
        setError
      );
      if (res && res.response) {
        setParticipants(res.response);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllClassworkParticipants();
  }, [user]);

  // Fetching all class participants
  const fetchParticipants = async () => {
    try {
      const res = await getDatas(
        `/teacher-api/classworks/participants/${datas?.class_id}`,
        setError
      );
      if (res && res.response) {
        setClassParticipants(res.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchParticipants();
  }, [datas.class_id]);

  // Save updated scores
  const saveScores = async () => {
    setActive(null);
    setLoading(true);

    const newData = participants.map((p) => ({
      student_id: p.student_id,
      classwork_id: datas.classwork_id,
      score: updatedScores[p.student_id] || p.score,
    }));

    try {
      const res = await putDatas(
        "/teacher-api/classworks/score/update",
        newData,
        setError
      );
      if (res && res.response) {
        setActive(1);
        setMessage(res.response);
        getAllClassworkParticipants();
        setLoading(false);
      }
    } catch (error) {
      setActive(3);
      setMessage("Failed to Update Scores");
    }
  };

  const handleScoreChange = (id, score) => {
    setUpdatedScores((prev) => ({ ...prev, [id]: score }));
  };

  const handleNewParticipantScoreChange = (studentId, score) => {
    setNewParticipantScores((prev) => ({ ...prev, [studentId]: score }));
  };

  const filteredParticipants = participants.filter((participant) =>
    participant.student_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const availableParticipants = classParticipants.filter(
    (classParticipant) =>
      !participants.some(
        (participant) => participant.student_id === classParticipant.student_id
      )
  );

  const addParticipant = async (student) => {
    const data = {
      student_id: student.student_id,
      classwork_id: datas.classwork_id,
      score: newParticipantScores[student.student_id] || 0,
    };

    try {
      const res = await postDatas(
        "/teacher-api/classworks/participants/add",
        data,
        setError
      );
      if (res && res.response) {
        setMessage(res.response);
        setActive(1);
        setShowAddModal(false);
        getAllClassworkParticipants();
      }
    } catch (error) {
      setMessage("Failed to Add Participant");
      setActive(3);
    }
  };

  return (
    <div className={`${s.classworkViewContainer} ${s.slideIn}`}>
      <Popup message={message} active={active} />

      <div className={s.classworkViewHeader}>
        <div>Scores Update</div>
        <button onClick={closeMail}>Close</button>
      </div>

      {/* Search and Add Participant */}
      <span className={s.searchContainer}>
        <button onClick={() => setShowAddModal(true)}>{icons.addFill}</button>
        <input
          type="text"
          placeholder="Search by student name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={s.searchInput}
        />
      </span>

      {loading ? (
        <Default active={1} />
      ) : participants.length === 0 ? (
        <Default active={2} />
      ) : (
        <div className={s.classworkMain}>
          {showAddModal ? (
            <div className={s.addModal}>
              <div className={s.modalContent}>
                <span>
                  <span>
                    Add Participants <br />
                    max score:
                    {datas.max_score}
                  </span>

                  <button onClick={() => setShowAddModal(false)}>Hide</button>
                </span>
                <div className={s.availableParticipants}>
                  {availableParticipants.map((student) => (
                    <div key={student.student_id} className={s.studentRow}>
                      <span>{student.fname + " " + student.lname}</span>
                      <input
                        type="number"
                        min="0"
                        max={datas.max_score}
                        value={newParticipantScores[student.student_id] || ""}
                        onChange={(e) =>
                          handleNewParticipantScoreChange(
                            student.student_id,
                            e.target.value
                          )
                        }
                        placeholder="Score"
                      />
                      <button onClick={() => addParticipant(student)}>
                        Add
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            filteredParticipants.map((participant) => (
              <div key={participant.student_id} className={s.participantRow}>
                <span>{participant.student_name}</span>
                <span>
                  <input
                    type="number"
                    min="0"
                    max={datas.max_score}
                    value={
                      focusedStudentId === participant.student_id
                        ? updatedScores[participant.student_id] !== undefined
                          ? updatedScores[participant.student_id]
                          : ""
                        : updatedScores[participant.student_id] !== undefined
                        ? updatedScores[participant.student_id]
                        : participant.score
                    }
                    onFocus={() => setFocusedStudentId(participant.student_id)}
                    onBlur={() => setFocusedStudentId(null)} // Reset on blur
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value) || 0;
                      handleScoreChange(participant.student_id, newValue);
                    }}
                  />
                  <span className={s.max}> / {participant.max_score}</span>
                </span>
              </div>
            ))
          )}
        </div>
      )}

      <div className={s.classworkBottom}>
        <button onClick={saveScores}>Save Records</button>
      </div>
    </div>
  );
}

export default View;
