import React, { useContext, useState } from "react";
import styles from "./Header.module.css";
import { GlobalStyleContext } from "../../../../components/other/styles/globalcss";
import { postDatas } from "../../../../services/api";
import logo from "../../../../assets/logo.jpg";
import profile from "../../../../assets/profile.png"; 
import { icons } from "../../../../utilities/icons";
import { handleToggler } from "../../../../utilities/methods";

const Header = ({ title}) => {    
  const [error, setError] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const logout = async () => {
    localStorage.removeItem("isLoggedIn");
    const res = await postDatas(
      "/admin-api/post/logout",
      {},
      setError
    );
    if (res.response) {
      window.location.href = "/";
    }
  };
  const { globalStyles } = useContext(GlobalStyleContext);
  return (
    <>
      <header
        className={styles.header}
        style={{ backgroundColor: globalStyles.bg2 }}
      >
        <div className={styles.left} style={{ color: globalStyles.logo }}>
          <img src={logo} alt="template"></img>
          <span>{title}</span>
        </div>
        <div className={styles.right}>
          <span>{icons.emailLine}</span>
          <span>{icons.notifFill}</span>
          <span>
            Admin
          </span>
          <span>
            <img
              src={ profile}
              alt=""
              onClick={() =>
                handleToggler(isDropdownVisible, setIsDropdownVisible)
              }
            ></img>
          </span>

        </div>
        {isDropdownVisible && (
          <div className={styles.menuDropdown}>
            <span onClick={logout}>
              {icons.homeLine} <label>Logout</label>
            </span>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
