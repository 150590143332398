import React from "react";
import { Routes, Route } from "react-router-dom";
import Forgot from "../pages/Student/pages/Authentication/Forgot";
import Login from "../pages/Student/pages/Authentication/Login";
import Signup from "../pages/Student/pages/Authentication/Signup";
import Main from "../pages/Student/pages/Main/Main";
import Dashboard from "../pages/Student/pages/Dashboard/Dashboard";
import ELearn from "../pages/Student/pages/E-Learning/ELearn";
import WebTools from "../pages/Student/pages/Web-Tools/WebTools";
import Pending from "../pages/defaults/Pending/Pending";
import Announcement from "../pages/Student/pages/Announcements/Announcements";
import Settings from "../pages/Student/pages/Settings/Settings";
import Scoreboard from "../pages/Student/pages/Scoreboard/Scoreboard";
import ProtectedRoute from "../contexts/ProtectedRoute";

export default function StudentRoutes() {
  return (
    <Routes>
      {/* ************* Authentication ************** */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/signup" element={<Signup />} />

      <Route path="/pending" element={<Pending />} />
      <Route
        path="/Main"
        element={
          <ProtectedRoute role="student" >
            <Main />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute role="student" >
            <Settings />
          </ProtectedRoute>
        }
      />

      {/* ************** navigations ************** */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute role="student" >
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/learning"
        element={
          <ProtectedRoute role="student" >
            <ELearn />
          </ProtectedRoute>
        }
      />

      <Route
        path="/scoreboard"
        element={
          <ProtectedRoute role="student" >
            <Scoreboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/announcements"
        element={
          <ProtectedRoute role="student" >
            <Announcement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tools"
        element={
          <ProtectedRoute role="student" >
            <WebTools />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
