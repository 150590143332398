import React, { useState, useRef, useEffect } from "react";
// styles
import styles from "../css/create.module.css";
import s from "../../../../../components/css/buttons.module.css";
import sample from "../../../../../images/bg.jpg";
//methods

import { handleUpload,
  previewFile,
  updateData, } from "../../../../../../../utilities/methods";
// firebase
import { storage } from "../../../../../../../configs/firebase";
import { ref, deleteObject } from "firebase/storage";
// components
import Popup from "../../../../../components/Popup/Popup";

function UpdateTeacher({ datas, closeModal, getTeachers }) {
  const previewImageRef = useRef();
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(null);
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [values, setValues] = useState({
    image: datas.image,
    description: datas.description,
    fname: datas.fname,
    mname: datas.mname,
    lname: datas.lname,
    contact: datas.contact,
    email: datas.email,
    password: datas.password,
    teacher_id: datas.teacher_id
  });

  // *********************************************************
  // handle information update
  // *********************************************************
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage("");
    setActive(null);

    if (profile) {
      try {
      if (prevImage) {
        const prevImageRef = ref(storage, prevImage);
        await deleteObject(prevImageRef);
      }
      
        values.image = await handleUpload(profile, "profile");
      } catch (error) {
        setActive(3);
        setMessage("Image Upload Failed, Please try uploading again later");
        console.log(error);
      }

      const url = `/admin-api/put/teacher`;
      const response = await updateData(url, values, setError);

      if (response) {
        setActive(1);
        setMessage("Updating Teacher Information Successful!");
        getTeachers();
      } else {
        setActive(3);
        setMessage("Updating Teacher Information Failed!");
      }
    }
  };

  // *********************************************************
  // handle image preview || input change || page reload
  // *********************************************************
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // previewing image
  const previewImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setProfile(image);
      previewFile(image, previewImageRef);
    }
  };

  useEffect(() => {
    setPrevImage(values.image);
  }, [values]);

  return (
    <>
      <Popup message={message} active={active} />

      <div className={`${styles.container}`}>
        <div className={styles.loginCard}>
          {/* form on left */}
          <div className={styles.formSection}>
            <div className={styles.title}>
              Update Teacher
              <button className={s.modalButton} onClick={closeModal}>
                Back
              </button>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputGroup}>
                <div className={styles.profile}>
                  <input
                    className={styles.input}
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={previewImage}
                  />
                  <img
                    src={values.image}
                    alt="profile"
                    className={styles.image}
                    ref={previewImageRef}
                  />
                </div>

                {/* description */}
                <input
                  className={styles.input}
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  required
                />

                {/* full name */}
                <div className={styles.name}>
                  {/* fname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="fname"
                    placeholder="First Name"
                    value={values.fname}
                    onChange={handleChange}
                    required
                  />
                  {/* mname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="mname"
                    placeholder="Middle Name"
                    value={values.mname}
                    onChange={handleChange}
                    required
                  />
                  {/* lname */}
                  <input
                    className={styles.input}
                    type="text"
                    name="lname"
                    placeholder="last Name"
                    value={values.lname}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* contact */}
                <input
                  className={styles.input}
                  type="text"
                  name="contact"
                  placeholder="Contact"
                  value={values.contact}
                  onChange={handleChange}
                />

                <div className={styles.account}>
                  Account Details:
                  {/* email */}
                  <input
                    className={styles.input}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {/* password */}
                  <input
                    className={styles.input}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <button type="submit" className={styles.button}>
                Update Information
              </button>
            </form>
          </div>
          {/* image on right */}
          <div className={styles.illustrationSection}>
            <div className={styles.illustration}>
              <img
                src={sample}
                alt="Illustration"
                className={styles.image}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdateTeacher;
